import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    background: '#F7F8FA',
    border: '1px solid #E4E4E4',
    borderColor: '#7A7A7A',
    borderRadius: '3px',
    fontSize: '15px',
  },

  icon: {
    color: '#000',
  },
});

export const Select = defineMultiStyleConfig({ baseStyle });
