import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, Flex, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import Logo from "../../assets/img/logo.png";
import { NavLink } from "react-router-dom";
// import "./index.scss";
import { getData } from "../../services/staticpage-service"

const PrivacyPolicy = () => {

  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)

  const getPageData = async (privacy_policy) => {
    setLoading(true)
    try {
      const res = await getData(privacy_policy);
      setData(res.data.data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getPageData("privacy_policy")
  }, [])

  return (
    <Box
      padding="20px"
      borderBottomRightRadius="8px"
      borderBottomLeftRadius="8px"
       minH='50vh'
    >
      <Flex
        align="center"
        justify="center"
        width="100%"
        bg="white"
        flexDir="column"
        mx="auto"
        borderRadius="8px"
        mb="15px"
      >
        <Box
         width="100%"
         // bg="#f41106"
         display="flex"
         alignItems="center"
         justifyContent="center"
         height="60px"
         color="black"
         // gap="20px"
         // padding="10px"
         borderTopRightRadius="8px"
         borderTopLeftRadius="8px"
        >
          <Text fontSize={{ base: "19px", md: "24px" }} fontWeight="600" textAlign="center">
             Privacy Policy
          </Text>
        </Box>

        {
          loading ? "Loading..." : (<Text alignItes="center" padding={{base:'10px 5px', md:'30px 50px'}}>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Text>)
        }


      </Flex>
    </Box>
  );
};

export default PrivacyPolicy;
