import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Button,
  Divider,
  Badge,
  useColorModeValue,
  useBreakpointValue,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Skeleton,
  SkeletonText,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { RiArrowRightDoubleFill } from 'react-icons/ri';
import { LuPrinter } from 'react-icons/lu';
import { RiShareForwardLine } from 'react-icons/ri';
import UserDetail from './user-detail';
import OrderTracking from './order-tracking';
import './index.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrderDetails,
  orderTrackingHandler,
} from '../../redux/orderSlice';
import { downloadInvoice } from '../../services/order-service';
import moment from 'moment';
import isEmpty from 'is-empty';
import OrderDetailsMobile from './order-details-mobile';

const OrderDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const { singleOrderDetails, isLoading, orderTrackingData } = useSelector(
    (state) => state.order
  );
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(fetchOrderDetails(id)),
        dispatch(orderTrackingHandler(id)),
      ]);
    }
  }, [dispatch, id]);

  return (
    <Box p={isSmallerThan767 ? '0 10px' : '0'}>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        p={isSmallerThan767 ? '10px 0 0' : '25px 0 25px 0'}
      >
        <Text fontSize={isSmallerThan767 ? '16px' : '20px'} fontWeight='bold'>
          Order Details
        </Text>
        {/* <Flex alignItems='center' gap='10px'>
          <Text color='#E6533C' fontSize='14px'>
            Ecommerce
          </Text>
          <RiArrowRightDoubleFill color='#E6533C' />
          <Text fontWeight='bold' fontSize='14px'>
            Order Details
          </Text>
        </Flex> */}
      </Flex>

      <Box
        display='flex'
        flexDir={{ base: 'column', md: 'row' }}
        //  alignItems="center"
        justifyContent='space-between'
        gap='25px'
      >
        <Box bg='white' flex={1} borderRadius='md'>
          <Flex
            p='20px 0 20px 0'
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            justifyContent={{ base: 'flex-start', md: 'space-between' }}
          >
            <Flex flexDir='row' borderBottom='1px solid gray.200'>
              <Box
                width='4px'
                height='24px'
                bgGradient='linear(to-b, #B5B8F0, #A5C7F1)'
                mr={2}
                borderRadius='5px'
              />
              <Flex
                fontSize={isSmallerThan767 ? '14px' : '18px'}
                fontWeight='bold'
                mb={2}
              >
                Order No -{' '}
                {isLoading ? (
                  <Skeleton height={'20px'} width={'120px'} />
                ) : (
                  <Text as='span'># {singleOrderDetails?.order_no}</Text>
                )}
              </Flex>
            </Flex>
            {isLoading ? (
              <Skeleton height={'20px'} width={'100px'} />
            ) : (
              <Text
                color='#E6533C'
                bg='#F2EEFC'
                p='3px 6px 2px 6px'
                borderRadius='7px'
                fontSize='12px'
                fontWeight='600'
                marginLeft={{ base: '14px', md: '0px' }}
              >
                {singleOrderDetails?.status === 'cancelled'
                  ? 'Cancelled'
                  : `Estimated delivery :  ${
                      isEmpty(orderTrackingData)
                        ? '-'
                        : moment(orderTrackingData?.eta_time_stamps).format(
                            'MMM DD, YYYY hh:mm A'
                          )
                    }`}
              </Text>
            )}
          </Flex>
          {isSmallerThan767 ? (
            <OrderDetailsMobile />
          ) : (
            <>
              <Box
                overflowX='auto'
                borderRadius='md'
                width='100%'
                className='custom-scrollbar'
              >
                <Table>
                  <Thead borderTop='1px solid #edf2f7'>
                    <Tr fontWeight='bold'>
                      <Th fontWeight='bold' fontSize='14px'>
                        Item
                      </Th>

                      <Th fontWeight='bold' fontSize='14px'>
                        Price
                      </Th>
                      <Th fontWeight='bold' fontSize='14px'>
                        Quantity
                      </Th>
                      <Th fontWeight='bold' fontSize='14px'>
                        Total Price
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Stack direction='row' spacing={4} align='center'>
                          {isLoading ? (
                            <Skeleton height='85px' width='85px' />
                          ) : (
                            <Image
                              src={singleOrderDetails?.product_image}
                              alt={singleOrderDetails?.product_name}
                              boxSize='85px'
                              bg='#F3F6F8'
                              borderRadius='10px'
                            />
                          )}

                          <Box>
                            {isLoading ? (
                              <SkeletonText noOfLines={2} />
                            ) : (
                              <Text
                                title={singleOrderDetails?.product_name}
                                maxW={'190px'}
                                fontWeight='bold'
                                className='truncate'
                              >
                                {singleOrderDetails?.product_name}
                              </Text>
                            )}

                            {singleOrderDetails?.attributes?.map((item) => (
                              <Text>
                                {item.name}:{' '}
                                <Text as='span' color='gray.500'>
                                  {item?.value}
                                </Text>
                              </Text>
                            ))}

                            {singleOrderDetails?.discount > 0 && (
                              <Badge
                                bg='#26BF94'
                                color='white'
                                borderRadius='6px'
                              >
                                In Offer
                              </Badge>
                            )}
                            {singleOrderDetails?.discount > 0 && (
                              <Badge
                                bg='#49B6F5'
                                color='white'
                                borderRadius='6px'
                              >
                                {singleOrderDetails.discount}
                              </Badge>
                            )}
                          </Box>
                        </Stack>
                      </Td>

                      <Td>
                        <Flex
                          fontSize={'14px'}
                          gap={'5px'}
                          justify={'flex-end'}
                        >
                          <Text>{singleOrderDetails?.currency}</Text>
                          <Text>{singleOrderDetails?.price}</Text>
                        </Flex>
                      </Td>
                      <Td>{singleOrderDetails?.quantity}</Td>
                      <Td>
                        {' '}
                        <Flex
                          fontSize={'14px'}
                          gap={'5px'}
                          justify={'flex-end'}
                        >
                          <Text>{singleOrderDetails?.currency}</Text>
                          <Text>{singleOrderDetails?.price}</Text>
                        </Flex>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Table width='100%'>
                  <Tbody>
                    <Tr>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>
                        <Text textAlign='right' fontWeight='600'>
                          Sub Total :
                        </Text>
                      </Td>
                      <Td>
                        <Text textAlign='right' fontWeight='500'>
                          <Flex
                            fontSize={'14px'}
                            gap={'5px'}
                            justify={'flex-end'}
                          >
                            <Text>{singleOrderDetails?.currency}</Text>
                            <Text>{singleOrderDetails?.sub_total_amount}</Text>
                          </Flex>
                        </Text>
                      </Td>
                    </Tr>
                    {/* <Tr>
                  <Td>{''}</Td>
                  <Td>{''}</Td>
                  <Td>{''}</Td>
                  <Td>
                    <Text textAlign='right' fontWeight='600'>
                      Applied Coupon :
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      color='#26BF94'
                      textAlign='right'
                      fontWeight='500'
                      fontSize='12px'
                    >
                      SPKFR
                    </Text>
                  </Td>
                </Tr> */}
                    <Tr>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>
                        <Text textAlign='right' fontWeight='600'>
                          Delivery Fees :
                        </Text>
                      </Td>
                      <Td>
                        {isLoading ? (
                          <Skeleton
                            float='right'
                            height={'20px'}
                            width={'100px'}
                          />
                        ) : (
                          <Text textAlign='right' fontWeight='500'>
                            {singleOrderDetails?.shipment_amount > 0
                              ? singleOrderDetails?.shipment_amount
                              : 'Free Shipping'}
                          </Text>
                        )}
                      </Td>
                    </Tr>
                    {/* <Tr>
                  <Td>{''}</Td>
                  <Td>{''}</Td>
                  <Td>{''}</Td>
                  <Td>
                    <Text textAlign='right' fontWeight='600'>
                      Total Saved :
                    </Text>
                  </Td>
                  <Td>
                    <Text textAlign='right' fontWeight='700' color='#26BF94'>
                      <Flex fontSize={'14px'} gap={'5px'} justify={'flex-end'}>
                        <Text>{singleOrderDetails?.currency}</Text>
                        <Text>{singleOrderDetails?.total_amount}</Text>
                      </Flex>
                    </Text>
                  </Td>
                </Tr> */}
                    <Tr textAlign='right'>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>{''}</Td>
                      <Td>
                        <Text fontWeight='600' textAlign='right'>
                          Total Price :
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontWeight='700'
                          fontSize='18px'
                          textAlign='right'
                        >
                          <Flex
                            fontSize={'14px'}
                            gap={'5px'}
                            justify={'flex-end'}
                          >
                            <Text>{singleOrderDetails?.currency}</Text>
                            <Text>{singleOrderDetails?.total_amount}</Text>
                          </Flex>
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              {singleOrderDetails?.status !== 'cancelled' && (
                <Stack
                  direction='row'
                  spacing={4}
                  mt={4}
                  p={4}
                  justifyContent='right'
                >
                  {isLoading ? (
                    <>
                      <Skeleton height={'40px'} width={'100px'} />
                      <Skeleton height={'40px'} width={'100px'} />
                    </>
                  ) : (
                    <>
                      {' '}
                      <Button
                        onClick={async () => {
                          window.open(
                            `${process.env.REACT_APP_API_URL}/get-order-invoice?order_id=${singleOrderDetails?.id}`,
                            '_blank'
                          );
                        }}
                        colorScheme='purple'
                        leftIcon={<LuPrinter />}
                      >
                        Download
                      </Button>
                      {/* <Button bg='#23B7E5' leftIcon={<RiShareForwardLine />}>
                    Share Details
                  </Button> */}
                    </>
                  )}
                </Stack>
              )}
            </>
          )}
        </Box>
        {/* <UserDetail /> */}
        <OrderTracking />
      </Box>
    </Box>
  );
};

export default OrderDetail;
