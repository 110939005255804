import { createSlice } from '@reduxjs/toolkit';

import {
  addToWishlist,
  deleteWishlist,
  getWishList,
  moveToCartFromWishlist,
} from '../services/wishlist-service';

const initialState = {
  isLoading: false,
  wishList: [],
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWishList(state, action) {
      state.wishList = action.payload;
    },
  },
});

export const { setIsLoading, setWishList } = wishlistSlice.actions;

export default wishlistSlice.reducer;

export const addToWishlistHandler = (data) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const payload = {
      // product_id: data?.id ? data.id.toString() : '',
      product_id: data?.product_id ? data.product_id.toString() : data?.id ? data.id.toString() : '',
      // modified id to make it work in adding to wishlist from cart 
      user_id:
        userData?.id || guestInfo.id
          ? (userData?.id || guestInfo.id).toString()
          : '',
    };
    const response = await addToWishlist(payload);
    if (response.status === 200) {
      dispatch(fetchWishList(userData?.id || guestInfo.id));
    }
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchWishList = (userId) => async (dispatch, getState) => {
  try {
    const country = getState().home.country;
    const currency = getState().home.currency;
    const response = await getWishList(userId, currency, country);
    if (response.status === 200) {
      localStorage.setItem('wishlist', JSON.stringify(response.data.data));
      dispatch(setWishList(response.data.data));
    }
  } catch (error) {
    return error;
  }
};
export const fetchWishlistDetails = () => async (dispatch) => {
  try {
    const _wishlist = JSON.parse(localStorage.getItem('wishlist'));
    if (_wishlist) {
      dispatch(setWishList(_wishlist));
    }
  } catch (error) {
    return error;
  }
};

// export const deleteWishListHandler = (payload) => async (dispatch, getState) => {
//   try {
//     const userData = getState().user.userData;
//     const guestInfo = getState().guest.guestInfo;
//     const response = await deleteWishlist(payload);
//     if (response.status === 200) {
//       dispatch(fetchWishList(userData?.id || guestInfo.id));
//     }
//     return response;

//   } catch (error) {
//     return error;
//   }
// };

export const deleteWishListHandler = (payload) => async (dispatch) => {
  try {
    return await deleteWishlist(payload);
  } catch (error) {
    return error;
  }
};

export const moveToCartFromWishlistHandler = (payload) => async (dispatch) => {
  try {
    return await moveToCartFromWishlist(payload);
  } catch (error) {
    return error;
  }
};
