export const countries = [
  { name: 'Tanzania', code: 'TZ' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Cameroon', code: 'CM' },
];
export const currencyList = [
  { name: 'USD', code: 'USD' },
  { name: 'EUR', code: 'EUR' },
  { name: 'TZS', code: 'TZS' },
  { name: 'GHS', code: 'GHS' },
  { name: 'UGX', code: 'UGX' },
  { name: 'KES', code: 'KES' },
  { name: 'XAF', code: 'XAF' },
];
export const language = [
  { name: 'English', code: 'en' },
  { name: 'Franch', code: 'fr' },
  { name: 'Swahili', code: 'sw' },
]; 
 