import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    color: 'white',
    fontWeight: 500,
    borderRadius: '4px',
  },
  sizes: {
    sm: {
      padding: '12px 24px',
      fontSize: '14px',
      height: '38px',
    },
  },
  variants: {
    primary: {
      bg: 'primary.100',
      border: 0,
      _disabled: {
        bg: 'primary.100 !important',
        border: 0,
        opacity: 0.3,
      },
    },
    secondary: {
      bg: 'transparent',
      border: '1px',
      borderColor: 'black',
      color: 'black',
      _disabled: {
        opacity: 0.3,
      },
    },
    outline: {
      bg: 'transparent',
      border: '1px',
      borderColor: 'black',
      color: 'black',
      _disabled: {
        opacity: 0.3,
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'primary',
  },
});

export default Button;
