import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { PiWarningCircleThin } from 'react-icons/pi';

const CancelOrder = ({ isOpen, onClose, confirmCancel }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody textAlign='center' py={10}>
          <Box mb={4}>
            <Icon
              as={PiWarningCircleThin}
              boxSize={12}
              color='orange.400'
              width='100px'
              height='100px'
            />
          </Box>
          <Text fontSize='lg' fontWeight='bold' mb={2}>
            Are you sure?
          </Text>
          <Text color='gray.500'>You won't be able to revert this!</Text>
        </ModalBody>

        <ModalFooter justifyContent='center' gap={5}>
          <Button colorScheme='purple' onClick={confirmCancel}>
            Yes, cancel it!
          </Button>
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelOrder;
