import React, { useEffect, useState } from "react";
import './index.scss';
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { getData } from "../../services/staticpage-service"
import { AttachmentIcon } from "@chakra-ui/icons";

const SizeGuide = () => {
  const [isSmallerThan991] = useMediaQuery('(max-width: 991px)');
  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)

  const getPageData = async (size_guide) => {
    setLoading(true)
    try {
      const res = await getData(size_guide);
      setData(res.data.data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getPageData("size_guide")
  }, [])

  const handleScrollTo = (id) => {
    const selector = `[id="${id}"]`;
    const element = document.querySelector(selector);

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        const offset = 100;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }, 300);
    }
  };

  return (
    <Box
      padding="20px"
      borderBottomRightRadius="8px"
      borderBottomLeftRadius="8px"
       minH='50vh'
    >
      <Flex
        align="center"
        justify="center"
        width="100%"
        bg="white"
        flexDir="column"
        mx="auto"
        borderRadius="8px"
        mb="15px"
      >
        <Box
          width="100%"
          // bg="#f41106"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="60px"
          color="black"
          // gap="20px"
          // padding="10px"
          borderTopRightRadius="8px"
          borderTopLeftRadius="8px"
        >
          <Text fontSize={{ base: "19px", md: "24px" }} fontWeight="600" textAlign="center">
            Size Guide
          </Text>
        </Box>

        {
          loading ? "Loading..." : ( data?.description && <Text alignItes="center" padding={{base:'10px 5px', md:'30px 50px'}}>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Text>)
        }

        <div className="isndes">
          <section className="size-chart-section">
            <h1>Size charts</h1>
            <div className="sizechart-anchors">
              <ul className={!isSmallerThan991 && "additon_sizeChart"}>
                <li onClick={() => handleScrollTo('0')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Men's clothing of large sizes sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('1')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Men's clothing sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('2')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Women's clothing of large sizes sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('3')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Women's clothing sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('4')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Men's jeans sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('5')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Women's jeans sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('6')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Rings sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('7')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Men's shoes sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('8')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Women's shoes sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('0')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span className="sizechart-anchors__item">
                    Kids' shoes sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('10')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span  className="sizechart-anchors__item">
                    Baby shoes sizing chart (up to 1 year)
                  </span>
                </li>
                <li onClick={() => handleScrollTo('11')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span  className="sizechart-anchors__item">
                    Men's socks sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('12')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span  className="sizechart-anchors__item">
                    Women's socks sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('13')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span  className="sizechart-anchors__item">
                    Men's underwear sizing chart
                  </span>
                </li>
                <li onClick={() => handleScrollTo('14')}>
                  <AttachmentIcon className="link_icon_size"/>
                  <span  className="sizechart-anchors__item">
                    Women's underwear sizing chart
                  </span>
                </li>
              </ul>
            </div>
            {/* <div className="size-chart-filters">
              <form
                action="/sizechart/iframeIndex"
                id="size-chart-form"
                className="size-chart-form"
                method="post"
                noValidate="novalidate"
              >
                {" "}
                <div className="field field--default">
                  <div className="field__heading">
                    <label htmlFor="category" className="field__heading--main">
                      Category
                    </label>
                  </div>
                  <div className="field__field">
                    <div
                      className="select type-variations native"
                      data-input-name="category"
                    >
                      <select id="category" name="category">
                        <option selected="selected" value="">
                          – all –
                        </option>
                        <option value="Clothing"> Outerwear</option>
                        <option value="Jeans">Jeans</option>
                        <option value="Rings">Rings</option>
                        <option value="Shoes">Shoes</option>
                        <option value="Socks">Socks</option>
                        <option value="Underwear">Underwear</option>
                      </select>
                      <div className="fallback">
                        <p>– all –</p>
                        <span className="ico ico--arrow-triangle-down">
                          <svg>
                            <use xlinkHref="/images/sprite.svg#arrow-triangle-down" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="field field--default">
                  <div className="field__heading">
                    <label htmlFor="brand" className="field__heading--main">
                      Brand
                    </label>
                  </div>
                  <div className="field__field">
                    <div
                      className="select type-variations native m--fill"
                      data-input-name="brand"
                    >
                      <select id="brand" name="brand" aria-invalid="false">
                        <option value="">– all –</option>
                        <option selected="selected" value="General">
                          No brand
                        </option>
                        <option value="Adidas">Adidas</option>
                        <option value="Asics">Asics</option>
                        <option value="Converse">Converse</option>
                        <option value="Jimmy Choo">Jimmy Choo</option>
                        <option value="Lacoste">Lacoste</option>
                        <option value="Michael Kors">Michael Kors</option>
                        <option value="New Balance">New Balance</option>
                        <option value="Nike">Nike</option>
                        <option value="Nine West">Nine West</option>
                        <option value="Puma">Puma</option>
                        <option value="Salomon">Salomon</option>
                        <option value="Saucony">Saucony</option>
                        <option value="Steve Madden">Steve Madden</option>
                        <option value="Timberland">Timberland</option>
                      </select>
                      <div className="fallback">
                        <p>No brand</p>
                        <span className="ico ico--arrow-triangle-down">
                          <svg>
                            <use xlinkHref="/images/sprite.svg#arrow-triangle-down" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="field field--default">
                  <div className="field__heading">
                    <label htmlFor="gender" className="field__heading--main">
                      Gender
                    </label>
                  </div>
                  <div className="field__field">
                    <div
                      className="select type-variations native"
                      data-input-name="gender"
                    >
                      <select id="gender" name="gender">
                        <option selected="selected" value="">
                          – all –
                        </option>
                        <option value="Men">Man</option>
                        <option value="Women">Woman</option>
                        <option value="Kids_any">Child and baby</option>
                      </select>
                      <div className="fallback">
                        <p>– all –</p>
                        <span className="ico ico--arrow-triangle-down">
                          <svg>
                            <use xlinkHref="/images/sprite.svg#arrow-triangle-down" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </form>
            </div> */}
            <span className="spinner" />
            <div className="size-charts">
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Clothing"
                data-chart-gender="Men"
                data-chart-large={1}
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="0">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's clothing of large sizes sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">4XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">4XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">5XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">5XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">6XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">6XL</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">137.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">142.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">142.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">152.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">157.5</span>
                        </td>
                        <td className="">
                          <span className="size-value">162.6</span>
                        </td>
                        <td className="">
                          <span className="size-value">167.6</span>
                        </td>
                        <td className="">
                          <span className="size-value">172.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">111.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">119.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">121.9</span>
                        </td>
                        <td className="">
                          <span className="size-value">129.5</span>
                        </td>
                        <td className="">
                          <span className="size-value">132.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">139.7</span>
                        </td>
                        <td className="">
                          <span className="size-value">142.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">147.3</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3XL</span>
                        </td>
                        <td>
                          <span className="size-value">137.2</span>
                        </td>
                        <td>
                          <span className="size-value">111.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3XL</span>
                        </td>
                        <td>
                          <span className="size-value">142.2</span>
                        </td>
                        <td>
                          <span className="size-value">119.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">4XL</span>
                        </td>
                        <td>
                          <span className="size-value">142.2</span>
                        </td>
                        <td>
                          <span className="size-value">121.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">4XL</span>
                        </td>
                        <td>
                          <span className="size-value">152.4</span>
                        </td>
                        <td>
                          <span className="size-value">129.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">5XL</span>
                        </td>
                        <td>
                          <span className="size-value">157.5</span>
                        </td>
                        <td>
                          <span className="size-value">132.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">5XL</span>
                        </td>
                        <td>
                          <span className="size-value">162.6</span>
                        </td>
                        <td>
                          <span className="size-value">139.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">6XL</span>
                        </td>
                        <td>
                          <span className="size-value">167.6</span>
                        </td>
                        <td>
                          <span className="size-value">142.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">6XL</span>
                        </td>
                        <td>
                          <span className="size-value">172.7</span>
                        </td>
                        <td>
                          <span className="size-value">147.3</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Clothing"
                data-chart-gender="Men"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="1">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's clothing sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="">
                          <span className="size-value">S</span>
                        </td>
                        <td className="">
                          <span className="size-value">S</span>
                        </td>
                        <td className="">
                          <span className="size-value">M</span>
                        </td>
                        <td className="">
                          <span className="size-value">M</span>
                        </td>
                        <td className="">
                          <span className="size-value">L</span>
                        </td>
                        <td className="">
                          <span className="size-value">L</span>
                        </td>
                        <td className="">
                          <span className="size-value">XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">2XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">2XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">86.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">91.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">96.5</span>
                        </td>
                        <td className="">
                          <span className="size-value">101.6</span>
                        </td>
                        <td className="">
                          <span className="size-value">106.7</span>
                        </td>
                        <td className="">
                          <span className="size-value">111.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">116.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">121.9</span>
                        </td>
                        <td className="">
                          <span className="size-value">127</span>
                        </td>
                        <td className="">
                          <span className="size-value">132.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">137.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">142.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">71.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">73.7</span>
                        </td>
                        <td className="">
                          <span className="size-value">76.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">83.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">86.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">91.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">94</span>
                        </td>
                        <td className="">
                          <span className="size-value">101.6</span>
                        </td>
                        <td className="">
                          <span className="size-value">104.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">109.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">111.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">119.4</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                        <td>
                          <span className="size-value">73.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">106.7</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">111.8</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                        <td>
                          <span className="size-value">116.8</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                        <td>
                          <span className="size-value">121.9</span>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">2XL</span>
                        </td>
                        <td>
                          <span className="size-value">127</span>
                        </td>
                        <td>
                          <span className="size-value">104.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">2XL</span>
                        </td>
                        <td>
                          <span className="size-value">132.1</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3XL</span>
                        </td>
                        <td>
                          <span className="size-value">137.2</span>
                        </td>
                        <td>
                          <span className="size-value">111.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3XL</span>
                        </td>
                        <td>
                          <span className="size-value">142.2</span>
                        </td>
                        <td>
                          <span className="size-value">119.4</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Clothing"
                data-chart-gender="Women"
                data-chart-large={1}
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="2">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's clothing of large sizes sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="">
                          <span className="size-value">0X</span>
                        </td>
                        <td className="">
                          <span className="size-value">0X</span>
                        </td>
                        <td className="">
                          <span className="size-value">1X</span>
                        </td>
                        <td className="">
                          <span className="size-value">1X</span>
                        </td>
                        <td className="">
                          <span className="size-value">2X</span>
                        </td>
                        <td className="">
                          <span className="size-value">2X</span>
                        </td>
                        <td className="">
                          <span className="size-value">3X</span>
                        </td>
                        <td className="">
                          <span className="size-value">3X</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="">
                          <span className="size-value">12</span>
                        </td>
                        <td className="">
                          <span className="size-value">13</span>
                        </td>
                        <td className="">
                          <span className="size-value">14</span>
                        </td>
                        <td className="">
                          <span className="size-value">16</span>
                        </td>
                        <td className="">
                          <span className="size-value">18</span>
                        </td>
                        <td className="">
                          <span className="size-value">20</span>
                        </td>
                        <td className="">
                          <span className="size-value">22</span>
                        </td>
                        <td className="">
                          <span className="size-value">24</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">104.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">106.7</span>
                        </td>
                        <td className="">
                          <span className="size-value">109.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">114.3</span>
                        </td>
                        <td className="">
                          <span className="size-value">119.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">124.5</span>
                        </td>
                        <td className="">
                          <span className="size-value">129.5</span>
                        </td>
                        <td className="">
                          <span className="size-value">134.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">83.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">86.4</span>
                        </td>
                        <td className="">
                          <span className="size-value">88.9</span>
                        </td>
                        <td className="">
                          <span className="size-value">94</span>
                        </td>
                        <td className="">
                          <span className="size-value">99.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">104.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">109.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">114.3</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">106.7</span>
                        </td>
                        <td className="">
                          <span className="size-value">109.2</span>
                        </td>
                        <td className="">
                          <span className="size-value">111.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">116.8</span>
                        </td>
                        <td className="">
                          <span className="size-value">121.9</span>
                        </td>
                        <td className="">
                          <span className="size-value">127</span>
                        </td>
                        <td className="">
                          <span className="size-value">132.1</span>
                        </td>
                        <td className="">
                          <span className="size-value">137.2</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">0X</span>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">104.1</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                        <td>
                          <span className="size-value">106.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">0X</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">106.7</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">1X</span>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                        <td>
                          <span className="size-value">111.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">1X</span>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                        </td>
                        <td>
                          <span className="size-value">114.3</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                        <td>
                          <span className="size-value">116.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">2X</span>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                        </td>
                        <td>
                          <span className="size-value">119.4</span>
                        </td>
                        <td>
                          <span className="size-value">99.1</span>
                        </td>
                        <td>
                          <span className="size-value">121.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">2X</span>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                        </td>
                        <td>
                          <span className="size-value">124.5</span>
                        </td>
                        <td>
                          <span className="size-value">104.1</span>
                        </td>
                        <td>
                          <span className="size-value">127</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3X</span>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                        </td>
                        <td>
                          <span className="size-value">129.5</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                        <td>
                          <span className="size-value">132.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3X</span>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                        </td>
                        <td>
                          <span className="size-value">134.6</span>
                        </td>
                        <td>
                          <span className="size-value">114.3</span>
                        </td>
                        <td>
                          <span className="size-value">137.2</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Clothing"
                data-chart-gender="Women"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="3">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's clothing sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">15</span>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">78.7</span>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                        </td>
                        <td>
                          <span className="size-value">100.3</span>
                        </td>
                        <td>
                          <span className="size-value">105.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                        </td>
                        <td>
                          <span className="size-value">66</span>
                        </td>
                        <td>
                          <span className="size-value">68.6</span>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                        </td>
                        <td>
                          <span className="size-value">73.7</span>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                        </td>
                        <td>
                          <span className="size-value">80</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                        <td>
                          <span className="size-value">87.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                        </td>
                        <td>
                          <span className="size-value">99.1</span>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                        </td>
                        <td>
                          <span className="size-value">105.4</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                        <td>
                          <span className="size-value">113</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XS</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">78.7</span>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XS</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                        <td>
                          <span className="size-value">66</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">S</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                        </td>
                        <td>
                          <span className="size-value">68.6</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">M</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                        </td>
                        <td>
                          <span className="size-value">73.7</span>
                        </td>
                        <td>
                          <span className="size-value">99.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">L</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                        </td>
                        <td>
                          <span className="size-value">80</span>
                        </td>
                        <td>
                          <span className="size-value">105.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                        <td>
                          <span className="size-value">15</span>
                        </td>
                        <td>
                          <span className="size-value">100.3</span>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                        </td>
                        <td>
                          <span className="size-value">109.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XL</span>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                        <td>
                          <span className="size-value">105.4</span>
                        </td>
                        <td>
                          <span className="size-value">87.6</span>
                        </td>
                        <td>
                          <span className="size-value">113</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Bust (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Jeans"
                data-chart-gender="Men"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="4">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's jeans sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">33</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">34</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">37</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">39</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">73.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">78.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">99.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value"> Men's jeans Inner length</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">x28</span>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">x30</span>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">x32</span>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">x34</span>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">x36</span>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">x28</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">x30</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">x32</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">x34</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">x36</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Inner length</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">73.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">78.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">33</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">83.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">34</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">88.9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">37</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">94</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">96.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">39</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">99.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">101.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value" />
                        </td>
                        <td>
                          <span className="size-value">71.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">81.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">86.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">91.4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value" />
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">x28</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">x30</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">x32</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">x34</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">x36</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value" />
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">x28</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">x30</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">x32</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">x34</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">x36</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Jeans"
                data-chart-gender="Women"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="5">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's jeans sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">0</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">2XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">61</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">66</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">68.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">73.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">80</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">84</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">88</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td>
                          <span className="size-value">87.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">90</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">92.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">95.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">97.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">100.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">103</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">106.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">110.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">114</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">0</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">2XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">61</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">87.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">63.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">90</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">66</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">92.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">68.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">95.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">71</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">97.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">73.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">100.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">76</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">103</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">80</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">106.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">15</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">84</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">110.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">17</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">88</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">114</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 73, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Size</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Rings"
                data-chart-gender=""
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="6">
                  <AttachmentIcon className="link_icon_size"/>
                  Rings sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Diameter (mm)</span>
                        </td>
                        <td>
                          <span className="size-value">14.86</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16.51</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16.92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18.19</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18.53</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18.89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19.41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19.84</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.68</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.08</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.49</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22.33</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">circumference (mm)</span>
                        </td>
                        <td>
                          <span className="size-value">46.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">47.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">49</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">50.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">51.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">52.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">54</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">55.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">56.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">57.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">59.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">60.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">61.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">62.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">64.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">65.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">66.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">67.9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">69.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Germany, Russia</span>
                        </td>
                        <td>
                          <span className="size-value">15</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">H</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">I</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">J</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">K</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">N</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">O</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">P</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Q</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">R</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">T</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">U</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">V</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">W</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">X</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Y</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Z</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Diameter (mm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">circumference (mm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Germany, Russia</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">14.86</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">46.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">H</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">15.27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">47.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">I</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">15.7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">49</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">15.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">J</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">16.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">50.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">K</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">16.51</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">51.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">16.92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">52.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">17.35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">54</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">N</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">17.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">55.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">17.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">O</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">18.19</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">56.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">P</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">18.53</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">57.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Q</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">18.89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">59.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">R</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">19.41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">60.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">19.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">19.84</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">61.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">T</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">20.2</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">62.8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">U</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">20.68</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">64.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">V</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">21.08</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">65.3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">W</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">21.49</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">66.6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">X</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">21.89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">67.9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.75</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Y</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">22.33</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">69.1</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">Z</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 79, height: 60 }}>
                          <span className="size-value">Diameter (mm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 79, height: 60 }}>
                          <span className="size-value">circumference (mm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 79, height: 60 }}>
                          <span className="size-value">Germany, Russia</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 79, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 79, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Diameter (mm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">circumference (mm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Germany, Russia</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Shoes"
                data-chart-gender="Men"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="7">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's shoes sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">40.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">41.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">42.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">43</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">43.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">44</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">44.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">45</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">46</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">47</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">CM</span>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">40.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">41.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">42.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">43</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">43.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">44</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">44.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">29.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">45</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">46</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">47</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Shoes"
                data-chart-gender="Women"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="8">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's shoes sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">35.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">36.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">37</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">37.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">38.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">39</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">39.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">40-41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">41-42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">42-43</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">CM</span>
                        </td>
                        <td>
                          <span className="size-value">21.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">23.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">35</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">21.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">35.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">37</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">23.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">37.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">24.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">38.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">39</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">25.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">39.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">40-41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">26.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">41</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">41-42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">27.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">42-43</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">28.5</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Shoes"
                data-chart-gender="Youth"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="9">
                  <AttachmentIcon className="link_icon_size"/>
                  Kids' shoes sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">21</span>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                        </td>
                        <td>
                          <span className="size-value">23</span>
                        </td>
                        <td>
                          <span className="size-value">24</span>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                        </td>
                        <td>
                          <span className="size-value">25</span>
                        </td>
                        <td>
                          <span className="size-value">26</span>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                        </td>
                        <td>
                          <span className="size-value">27</span>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                        </td>
                        <td>
                          <span className="size-value">28</span>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                        </td>
                        <td>
                          <span className="size-value">30</span>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                        </td>
                        <td>
                          <span className="size-value">31</span>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                        </td>
                        <td>
                          <span className="size-value">33</span>
                        </td>
                        <td>
                          <span className="size-value">33</span>
                        </td>
                        <td>
                          <span className="size-value">34</span>
                        </td>
                        <td>
                          <span className="size-value">34</span>
                        </td>
                        <td>
                          <span className="size-value">35</span>
                        </td>
                        <td>
                          <span className="size-value">36</span>
                        </td>
                        <td>
                          <span className="size-value">36</span>
                        </td>
                        <td>
                          <span className="size-value">37</span>
                        </td>
                        <td>
                          <span className="size-value">37</span>
                        </td>
                        <td>
                          <span className="size-value">38</span>
                        </td>
                        <td>
                          <span className="size-value">38</span>
                        </td>
                        <td>
                          <span className="size-value">39</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">13.5</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">CM</span>
                        </td>
                        <td>
                          <span className="size-value">12.7</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">13.3</span>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                        </td>
                        <td>
                          <span className="size-value">14.3</span>
                        </td>
                        <td>
                          <span className="size-value">14.6</span>
                        </td>
                        <td>
                          <span className="size-value">15.2</span>
                        </td>
                        <td>
                          <span className="size-value">15.6</span>
                        </td>
                        <td>
                          <span className="size-value">15.9</span>
                        </td>
                        <td>
                          <span className="size-value">16.5</span>
                        </td>
                        <td>
                          <span className="size-value">16.8</span>
                        </td>
                        <td>
                          <span className="size-value">17.1</span>
                        </td>
                        <td>
                          <span className="size-value">17.8</span>
                        </td>
                        <td>
                          <span className="size-value">18.1</span>
                        </td>
                        <td>
                          <span className="size-value">18.4</span>
                        </td>
                        <td>
                          <span className="size-value">19.1</span>
                        </td>
                        <td>
                          <span className="size-value">19.4</span>
                        </td>
                        <td>
                          <span className="size-value">19.7</span>
                        </td>
                        <td>
                          <span className="size-value">20.3</span>
                        </td>
                        <td>
                          <span className="size-value">20.6</span>
                        </td>
                        <td>
                          <span className="size-value">21</span>
                        </td>
                        <td>
                          <span className="size-value">21.6</span>
                        </td>
                        <td>
                          <span className="size-value">21.9</span>
                        </td>
                        <td>
                          <span className="size-value">22.2</span>
                        </td>
                        <td>
                          <span className="size-value">22.9</span>
                        </td>
                        <td>
                          <span className="size-value">23.2</span>
                        </td>
                        <td>
                          <span className="size-value">23.5</span>
                        </td>
                        <td>
                          <span className="size-value">24.1</span>
                        </td>
                        <td>
                          <span className="size-value">24.4</span>
                        </td>
                        <td>
                          <span className="size-value">24.8</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">21</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">12.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">22</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">22</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">13.3</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">23</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">23</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                        </td>
                        <td>
                          <span className="size-value">14.3</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">24</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                        </td>
                        <td>
                          <span className="size-value">14.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">25</span>
                        </td>
                        <td>
                          <span className="size-value">7.5</span>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                        </td>
                        <td>
                          <span className="size-value">15.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">25</span>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">15.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">26</span>
                        </td>
                        <td>
                          <span className="size-value">8.5</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                        <td>
                          <span className="size-value">15.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">27</span>
                        </td>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                        </td>
                        <td>
                          <span className="size-value">16.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">27</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                        <td>
                          <span className="size-value">16.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">28</span>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">17.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">28</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                        </td>
                        <td>
                          <span className="size-value">17.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">30</span>
                        </td>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">18.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">30</span>
                        </td>
                        <td>
                          <span className="size-value">11.5</span>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                        </td>
                        <td>
                          <span className="size-value">18.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">31</span>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">19.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">31</span>
                        </td>
                        <td>
                          <span className="size-value">12.5</span>
                        </td>
                        <td>
                          <span className="size-value">13.5</span>
                        </td>
                        <td>
                          <span className="size-value">19.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">32</span>
                        </td>
                        <td>
                          <span className="size-value">13</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">19.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">33</span>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">20.3</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">33</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">20.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">34</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">21</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">34</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">21.6</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">35</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">21.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">22.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">22.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">37</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">23.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">37</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">23.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">38</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">24.1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">38</span>
                        </td>
                        <td>
                          <span className="size-value">5.5</span>
                        </td>
                        <td>
                          <span className="size-value">6.5</span>
                        </td>
                        <td>
                          <span className="size-value">24.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">39</span>
                        </td>
                        <td>
                          <span className="size-value">6</span>
                        </td>
                        <td>
                          <span className="size-value">7</span>
                        </td>
                        <td>
                          <span className="size-value">24.8</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Shoes"
                data-chart-gender="Kids"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="10">
                  <AttachmentIcon className="link_icon_size"/>
                  Baby shoes sizing chart (up to 1 year)
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                        </td>
                        <td>
                          <span className="size-value">19</span>
                        </td>
                        <td>
                          <span className="size-value">19</span>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">0</span>
                        </td>
                        <td>
                          <span className="size-value">0.5</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">0.5</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">CM</span>
                        </td>
                        <td>
                          <span className="size-value">8.3</span>
                        </td>
                        <td>
                          <span className="size-value">8.9</span>
                        </td>
                        <td>
                          <span className="size-value">9.2</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                        <td>
                          <span className="size-value">10.2</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                        <td>
                          <span className="size-value">10.8</span>
                        </td>
                        <td>
                          <span className="size-value">11.4</span>
                        </td>
                        <td>
                          <span className="size-value">11.7</span>
                        </td>
                        <td>
                          <span className="size-value">12.1</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td>
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">16</span>
                        </td>
                        <td>
                          <span className="size-value">0</span>
                        </td>
                        <td>
                          <span className="size-value">0.5</span>
                        </td>
                        <td>
                          <span className="size-value">8.3</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">16</span>
                        </td>
                        <td>
                          <span className="size-value">0.5</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">8.9</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">9.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">17</span>
                        </td>
                        <td>
                          <span className="size-value">1</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">9.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">18</span>
                        </td>
                        <td>
                          <span className="size-value">1.5</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">10.2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">18</span>
                        </td>
                        <td>
                          <span className="size-value">2</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">10.5</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">19</span>
                        </td>
                        <td>
                          <span className="size-value">2.5</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">10.8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">19</span>
                        </td>
                        <td>
                          <span className="size-value">3</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">11.4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">20</span>
                        </td>
                        <td>
                          <span className="size-value">3.5</span>
                        </td>
                        <td>
                          <span className="size-value">4.5</span>
                        </td>
                        <td>
                          <span className="size-value">11.7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">20</span>
                        </td>
                        <td>
                          <span className="size-value">4</span>
                        </td>
                        <td>
                          <span className="size-value">5</span>
                        </td>
                        <td>
                          <span className="size-value">12.1</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 84, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">
                            Europe (<strong>UK</strong>)
                          </span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">CM</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Socks"
                data-chart-gender="Men"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="11">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's socks sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="">
                          <span className="size-value">Europe, UK</span>
                        </td>
                        <td className="">
                          <span className="size-value">36-40</span>
                        </td>
                        <td className="">
                          <span className="size-value">41-46</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">UK</span>
                        </td>
                        <td className="">
                          <span className="size-value">4-7</span>
                        </td>
                        <td className="">
                          <span className="size-value">7,5-11,5</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">US</span>
                        </td>
                        <td className="">
                          <span className="size-value">4½-7½</span>
                        </td>
                        <td className="">
                          <span className="size-value">8-12</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">Europe, UK</span>
                        </td>
                        <td>
                          <span className="size-value">UK</span>
                        </td>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">36-40</span>
                        </td>
                        <td>
                          <span className="size-value">4-7</span>
                        </td>
                        <td>
                          <span className="size-value">4½-7½</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">41-46</span>
                        </td>
                        <td>
                          <span className="size-value">7,5-11,5</span>
                        </td>
                        <td>
                          <span className="size-value">8-12</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 78, height: 60 }}>
                          <span className="size-value">Europe, UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 78, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 78, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Europe, UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init m--no-search"
                data-chart-category="Socks"
                data-chart-gender="Women"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="12">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's socks sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="">
                          <span className="size-value">US</span>
                        </td>
                        <td className="">
                          <span className="size-value">8</span>
                        </td>
                        <td className="">
                          <span className="size-value">9</span>
                        </td>
                        <td className="">
                          <span className="size-value">10</span>
                        </td>
                        <td className="">
                          <span className="size-value">11</span>
                        </td>
                        <td className="">
                          <span className="size-value">12</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="size-value">Europe, UK</span>
                        </td>
                        <td className="">
                          <span className="size-value">37/38</span>
                        </td>
                        <td className="">
                          <span className="size-value">39/40</span>
                        </td>
                        <td className="">
                          <span className="size-value">41/42</span>
                        </td>
                        <td className="">
                          <span className="size-value">43/44</span>
                        </td>
                        <td className="">
                          <span className="size-value">45/46</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td>
                          <span className="size-value">US</span>
                        </td>
                        <td>
                          <span className="size-value">Europe, UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">8</span>
                        </td>
                        <td>
                          <span className="size-value">37/38</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">9</span>
                        </td>
                        <td>
                          <span className="size-value">39/40</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">10</span>
                        </td>
                        <td>
                          <span className="size-value">41/42</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">11</span>
                        </td>
                        <td>
                          <span className="size-value">43/44</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">12</span>
                        </td>
                        <td>
                          <span className="size-value">45/46</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 78, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 78, height: 60 }}>
                          <span className="size-value">Europe, UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Europe, UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Underwear"
                data-chart-gender="Men"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="13">
                  <AttachmentIcon className="link_icon_size"/>
                  Men's underwear sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">England, Greece</span>
                        </td>
                        <td className="">
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">2XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                        <td className="">
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">34</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">44</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">England, Greece</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">32</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">34</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">36</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">38</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">40</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">2XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">42</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">3XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">44</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 100, height: 60 }}>
                          <span className="size-value">England, Greece</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 100, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">England, Greece</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
              <div
                className="size-chart-wrapper m--init"
                data-chart-category="Underwear"
                data-chart-gender="Women"
                data-chart-large=""
                data-chart-brand="General"
                data-chart-search-brand="General"
              >
                <h2 id="14">
                  <AttachmentIcon className="link_icon_size"/>
                  Women's underwear sizing chart
                </h2>
                <div className="size-chart-table-wrapper">
                  <table className="size-chart-table size-chart-table-horizontal">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">63-65</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">66-69</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">70-74</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">75-78</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">79-83</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">84-89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">90-92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">90-94</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td className="">
                          <span className="size-value">89-92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">93-96</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">97-101</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">102-104</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">105-108</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">109-112</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">113-117</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">118-122</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td className="">
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">14</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">16</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">18</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">20</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">International, UK</span>
                        </td>
                        <td className="">
                          <span className="size-value">2XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">2XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td className="">
                          <span className="size-value">3XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table size-chart-table-vertical">
                    <tbody>
                      <tr>
                        <td className="m--no-search">
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search">
                          <span className="size-value">International, UK</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">63-65</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">89-92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">8</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">2XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">66-69</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">93-96</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">10</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XS</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">70-74</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">97-101</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">12</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">S</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">75-78</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">102-104</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">14</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">M</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">79-83</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">105-108</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">16</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">L</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">84-89</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">109-112</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">18</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">90-92</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">113-117</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">20</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">2XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="size-value">90-94</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">118-122</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">22</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                        <td>
                          <span className="size-value">3XL</span>
                          <div className="size-chart-search-link-box">
                            <button className="button m--red">Find</button>
                            <input
                              type="checkbox"
                              className="checkbox"
                              defaultChecked=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-horizontal size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 105, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 105, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 105, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="m--no-search" style={{ width: 105, height: 60 }}>
                          <span className="size-value">International, UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="size-chart-table-vertical size-chart-header">
                    <tbody>
                      <tr>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Waist (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">Hip (cm)</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">US</span>
                        </td>
                        <td className="m--no-search" style={{ width: 0, height: 60 }}>
                          <span className="size-value">International, UK</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="search-result m--empty">
                  <div className="daily__heading search-result-heading">
                    <div className="daily__heading-total">
                      <span>
                        Found <span className="total-value">???</span> goods
                      </span>
                      <span className="spinner" />
                    </div>
                  </div>
                  <div className="search-result-items" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Flex>
    </Box>
  );
};

export default SizeGuide;
