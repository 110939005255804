import React from "react";
import { Box, Flex, Text, SimpleGrid, ChakraProvider } from "@chakra-ui/react";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import ReviewCard from "./review-card";

const reviews = [
  {
    name: "Kiara Advain",
    title: "CEO of Empiro",
    message: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum autem quaerat distinctio",
    rating: 4,
    time: "2 days ago"
  },
];

const ReviewList = () => {
  return (
      <Box>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          p="25px 20px 20px 20px"
        >
          <Text fontSize="20px" fontWeight="bold">
            Reviews
          </Text>
          <Flex alignItems="center" gap="10px">
            <Text color="#E6533C" fontSize="14px">
              Pages
            </Text>
            <RiArrowRightDoubleFill color="#E6533C" />
            <Text fontWeight="bold" fontSize="14px">
              Reviews
            </Text>
          </Flex>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2 }} width="100%">
          {reviews.map((review, index) => (
            <ReviewCard 
              key={index}
              name={review.name}
              title={review.title}
              message={review.message}
              rating={review.rating}
              time={review.time}
            />
          ))}
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              name={review.name}
              title={review.title}
              message={review.message}
              rating={review.rating}
              time={review.time}
            />
          ))}
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              name={review.name}
              title={review.title}
              message={review.message}
              rating={review.rating}
              time={review.time}
            />
          ))}
          {reviews.map((review, index) => (
            <ReviewCard
              key={index}
              name={review.name}
              title={review.title}
              message={review.message}
              rating={review.rating}
              time={review.time}
            />
          ))}
        </SimpleGrid>

        {/* Uncomment the code to see the Pagination Design */}
        
        {/* <HStack mb={10} align="center"spacing={0} borderWidth="1px" borderColor="gray.300" borderRadius="md" width="22%">
          <Button
            _hover={{ bg: 'gray.100', color: '#f41106' }}
              bg="white"
            color="black"
            borderRightWidth="1px"
            borderColor="gray.300"
            borderRadiusLeft="md"
            borderRightRadius="0px"
            borderRight="1px solid #CBD5E0"
          >
            Previous
          </Button>
          <Button
            _hover={{ bg: 'gray.100', color: '#f41106' }}
            bg="white"
            color="black"
            borderRightWidth="1px"
            borderColor="gray.300"
            borderRadius="none"
            borderRightRadius="0px"
            borderRight="1px solid #CBD5E0"
          >
            1
          </Button>
          <Button
            _hover={{ bg: 'gray.100', color: '#f41106' }}
            bg="#f41106"
            color="white"
            borderRightWidth="1px"
            borderColor="gray.300"
            borderRadius="none"
            borderRightRadius="0px"
            borderRight="1px solid #CBD5E0"
          >
            2
          </Button>
          <Button
            _hover={{ bg: 'gray.100', color: '#f41106' }}
            bg="white"
            color="black"
            borderRightWidth="1px"
            borderColor="gray.300"
            borderRadius="none"
            borderRightRadius="0px"
            borderRight="1px solid #CBD5E0"
          >
            3
          </Button>
          <Button
            _hover={{ bg: 'gray.100', color: '#f41106' }}
            bg="white"
            color="black"
            borderRadius="md"
          >
            Next
          </Button>
        </HStack> */}
      </Box>
  );
};

export default ReviewList;
