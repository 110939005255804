import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    border: '1.5px solid #7A7A7A !important',
    _checked: {
      border: '0px solid #7A7A7A !important',
      bgColor: '#f41106',
    },
    icon: {
      bgColor: '#f41106',
    },
  },
});
const outline = definePartsStyle({
  control: {
    border: '1px solid red',
    borderColor: 'gray.300',

    // Let's also provide dark mode alternatives
    _dark: {
      borderColor: 'gray.600',
    },
  },
});

export const Radio = defineMultiStyleConfig({
  baseStyle,
  variants: { outline },
});
