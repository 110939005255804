import { Image } from '@chakra-ui/react';
import React from 'react';
import HeartIcon from "../../../assets/img/icons/heartIcon.svg"


const Icon = () => {
  return <Image src={HeartIcon} />;
};

export default Icon;


