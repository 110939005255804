import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const TextArea = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      bg: '#ffffff !important',
    },
  }),
  variants: {
    readOnly: definePartsStyle({
      field: {
        _readOnly: true,
        bg: '#F4F4F4 !important',
      },
    }),
  },
});
