import { useEffect, useLayoutEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile } from '../redux/userSlice';
import { Container, useMediaQuery } from '@chakra-ui/react';
import ActionButtons from '../components/action-buttons';
import BottomNavigation from '../components/bottom-navigation';
import AccountHeader from '../components/header/account-header';
import isEmpty from 'is-empty';
import NowBought from '../components/getNowBought';

let tt;

const PrivateRoutes = () => {
  const location = useLocation();
  const [isSmallerThan767] = useMediaQuery('(max-width: 991px)');
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  let auth = { token: localStorage.getItem('token') };

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  useEffect(() => {
    if (auth.token && isEmpty(userData)) {
      dispatch(fetchProfile());
    }
  }, [auth.token, dispatch, userData]);

  // useEffect(() => {
  //   if (tt) {
  //     clearTimeout(tt);
  //   }
  //   tt = setTimeout(() => {
  //     if (auth.token) dispatch(fetchProfile());

  //   }, 500);
  //   }, [auth.token, dispatch]);

  const isCartPage = location.pathname === '/product/cart';


  return auth.token ? (
    <Wrapper>
      <Header />

      <Container
        maxW='100%'
        // changed this padding from (0 10px) to (0) to make divider 100% in width of screen 
        padding={'0'}
        bg={isCartPage ? '#EDEDED' : 'white'}
        borderBottom={isCartPage ? '4px solid white' : 'none'}
      >
        <Outlet />
      </Container>
      {isSmallerThan767 ? <BottomNavigation /> : <Footer />}

      {/* {!isSmallerThan767 && */}
      <ActionButtons />
      {/* } */}
      <NowBought />
    </Wrapper>
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoutes;
