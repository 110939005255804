import { StarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Progress,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StarRating from '../../../components/star-rating/star';

const Rating = ({ fromHome }) => {
  const { productDetails } = useSelector((state) => state.product);
  const product = productDetails?.products;
  const [showMore, setShowMore] = useState(false);
  const [showMoreReviews, setShowMoreReviews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSmallerThan767] = useMediaQuery('(max-width: 991px)');

  const displayedRows = showMore
    ? productDetails?.products?.product_details
    : productDetails?.products?.product_details?.slice(0, 6);

  const displayedReviews = showMoreReviews
    ? product?.productReviews
    : product?.productReviews?.slice(0, 6);

  const handleShowMore = () => {
    setLoading(true);
    setTimeout(() => {
      setShowMore(!showMore);
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      {fromHome && displayedRows?.length > 0 && (
        <Box className='product-detail' mt='15px'>
          <Text fontSize='lg' fontWeight='bold' mb={2}>
            Product Details:
          </Text>
          <Box
            overflowX={['auto', 'visible']}
            style={{ marginTop: '8px', width: '100%', maxWidth: '100%' }}
          >
            {!isSmallerThan767 && (
              <Table variant='simple' minWidth='100%'>
                <Thead>
                  <Tr>
                    <Th
                      border='1px solid'
                      borderColor='#E0E0E0'
                      width='25%'
                      bg='#EDEDED'
                    >
                      Feature
                    </Th>
                    <Th border='1px solid' borderColor='#E0E0E0' width='25%'>
                      Details
                    </Th>
                    <Th
                      border='1px solid'
                      borderColor='#E0E0E0'
                      width='25%'
                      bg='#EDEDED'
                    >
                      Feature
                    </Th>
                    <Th border='1px solid' borderColor='#E0E0E0' width='25%'>
                      Details
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedRows?.map((detail, index, array) => {
                    if (index % 2 === 0) {
                      return (
                        <Tr key={index}>
                          <Td
                            fontSize='13px'
                            border='1px solid'
                            borderColor='#E0E0E0'
                            fontWeight={600}
                            bg='#EDEDED'
                            width='25%'
                            maxW='250px'
                          >
                            {array[index]?.attrName}
                          </Td>
                          <Td
                            fontSize='13px'
                            border='1px solid'
                            borderColor='#E0E0E0'
                            width='25%'
                            maxW='250px'
                          >
                            {array[index]?.attrValue}
                          </Td>
                          {array[index + 1] && (
                            <>
                              <Td
                                fontSize='13px'
                                border='1px solid'
                                borderColor='#E0E0E0'
                                fontWeight={600}
                                bg='#EDEDED'
                                width='25%'
                                maxW='250px'
                              >
                                {array[index + 1]?.attrName}
                              </Td>
                              <Td
                                fontSize='13px'
                                border='1px solid'
                                borderColor='#E0E0E0'
                                width='25%'
                                maxW='250px'
                              >
                                {array[index + 1]?.attrValue}
                              </Td>
                            </>
                          )}
                        </Tr>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Tbody>
              </Table>
            )}
            {isSmallerThan767 && (
              <Table variant='simple' minWidth='100%'>
                <Thead>
                  <Tr>
                    <Th
                      border='1px solid'
                      borderColor='#E0E0E0'
                      width='25%'
                      bg='#EDEDED'
                    >
                      Feature
                    </Th>
                    <Th border='1px solid' borderColor='#E0E0E0' width='25%'>
                      Details
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedRows?.map((detail, index, array) => {
                    return (
                      <Tr key={index}>
                        <Td
                          fontSize='13px'
                          border='1px solid'
                          borderColor='#E0E0E0'
                          fontWeight={600}
                          bg='#EDEDED'
                          width='25%'
                          maxW='250px'
                        >
                          {detail.attrName}
                        </Td>
                        <Td
                          fontSize='13px'
                          border='1px solid'
                          borderColor='#E0E0E0'
                          width='25%'
                          maxW='250px'
                        >
                          {detail.attrValue}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

            {/* Show More Button */}
            {productDetails?.products?.product_details?.length > 6 && (
              <Box textAlign='center' mt={4}>
                {loading ? (
                  <Spinner size='md' />
                ) : (
                  <Text
                    cursor='pointer'
                    fontWeight={600}
                    textDecoration='underline'
                    onClick={handleShowMore}
                    colorScheme='blue'
                    variant='outline'
                  >
                    {showMore ? 'Show Less' : 'Show More'}
                  </Text>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Box>
        <Text fontSize='15px' fontWeight='600' mt={10} mb={5}>
          Reviews & Ratings :
        </Text>
        <Box>
          <Flex gap={'10px'}>
            <StarIcon color='yellow.400' />
            {/* <StarRating rating={productDetails?.products?.rating} />  */}
            <Box>
              <Flex gap={'5px'}>
                <Text fontSize='13px' fontWeight='bold'>
                  {productDetails?.products?.rating}
                </Text>
                <Text fontSize='13px' color='gray.500'>
                  out of 5
                </Text>
              </Flex>
              <Text fontSize='13px' color='gray.500'>
                Based on {product?.productReviews?.length} ratings
              </Text>
            </Box>
          </Flex>

          {/* {!fromHome && (
            <>
              <Flex mt={2} gap='1rem' wrap='wrap'>
                <Flex align='center' width={['100%', '48%']}>
                  <Text width='30px'>5★</Text>
                  <Progress value={product?.averageRatingForProduct5} size='sm' colorScheme='green' flex='1' borderRadius='5px' />
                  <Text textAlign='right'>{product?.averageRatingForProduct5}</Text>
                </Flex>
                <Flex align='center' width={['100%', '48%']}>
                  <Text width='30px'>4★</Text>
                  <Progress value={product?.averageRatingForProduct4} size='sm' colorScheme='green' flex='1' borderRadius='5px' />
                  <Text textAlign='right'>{product?.averageRatingForProduct4}</Text>
                </Flex>
                <Flex align='center' width={['100%', '48%']}>
                  <Text width='30px'>3★</Text>
                  <Progress value={product?.averageRatingForProduct3} size='sm' colorScheme='green' flex='1' borderRadius='5px' />
                  <Text textAlign='right'>{product?.averageRatingForProduct3}</Text>
                </Flex>
                <Flex align='center' width={['100%', '48%']}>
                  <Text width='30px'>2★</Text>
                  <Progress value={product?.averageRatingForProduct2} size='sm' colorScheme='yellow' flex='1' borderRadius='5px' />
                  <Text textAlign='right'>{product?.averageRatingForProduct2}</Text>
                </Flex>
                <Flex align='center' width={['100%', '48%']}>
                  <Text width='30px'>1★</Text>
                  <Progress value={product?.averageRatingForProduct1} size='sm' colorScheme='red' flex='1' borderRadius='5px' />
                  <Text textAlign='right'>{product?.averageRatingForProduct1}</Text>
                </Flex>
              </Flex>

              <Box mt={4}>
                <Table variant='simple'>
                  <Tbody>
                    {displayedReviews?.map((review, index, array) => {
                      if (index % 2 === 0) {
                        return (
                          <Tr key={index}>
                            <Td width='50%' padding='8px'>
                              <Flex gap={'10px'}>
                                <StarIcon color='yellow.400' />
                                <Box>
                                  <Text fontSize='13px' fontWeight='bold'>{array[index]?.reviewerName}</Text>
                                  <Text fontSize='13px' color='gray.500'>{array[index]?.review}</Text>
                                </Box>
                              </Flex>
                            </Td>
                            {array[index + 1] && (
                              <Td width='50%' padding='8px'>
                                <Flex gap={'10px'}>
                                  <StarIcon color='yellow.400' />
                                  <Box>
                                    <Text fontSize='13px' fontWeight='bold'>{array[index + 1]?.reviewerName}</Text>
                                    <Text fontSize='13px' color='gray.500'>{array[index + 1]?.review}</Text>
                                  </Box>
                                </Flex>
                              </Td>
                            )}
                          </Tr>
                        );
                      }
                      return null;
                    })}
                  </Tbody>
                </Table>
                {product?.productReviews?.length > 6 && (
                  <Text
                    cursor='pointer'
                    fontWeight={600}
                    textAlign='center'
                    textDecoration='underline'
                    mt={4}
                    onClick={() => setShowMoreReviews(!showMoreReviews)}
                    colorScheme='blue'
                    variant='outline'
                  >
                    {showMoreReviews ? 'Show Less' : 'Show More'}
                  </Text>
                )}
              </Box>
            </>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default Rating;
