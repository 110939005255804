import axios from 'axios';

export const addToWishlist = async (paylaod) => {
  try {
    return await axios.post(`add-to-wishlist`, paylaod);
  } catch (error) {
    return error;
  }
};
export const getWishList = async (userId, currency, country) => {
  try {
    return await axios.get(
      `wishlist?user_id=${userId}&currency=${currency}&country=${country}`
    );
  } catch (error) {
    return error;
  }
};
export const deleteWishlist = async (paylaod) => {
  try {
    return await axios.post(`delete-wishlist`, paylaod);
  } catch (error) {
    return error;
  }
};
export const moveToCartFromWishlist = async (paylaod) => {
  try {
    return await axios.post(`move-to-cart`, paylaod);
  } catch (error) {
    return error;
  }
};
