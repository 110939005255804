import React from 'react';
import {
  Box,
  Badge,
  Link,
  Image,
  Text,
  Button,
  Tooltip,
  Flex,
  SimpleGrid,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import productImage from '../../../assets/img/product/16.png';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../../../components/product-card';
import { setProductCard } from '../../../redux/homeSlice';
import Slider from './slider';
import Modal from '../../../components/modal';
import ProductDetail from '..';

const SimilarProducts = () => {
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    productDetails: { related_products },
  } = useSelector((state) => state.product);
  const products = [
    {
      image: productImage,
      name: 'Omega watch series 3',
      rating: 4.4,
      reviews: 13256,
      currentPrice: 1099,
      originalPrice: 1759,
    },
    {
      image: productImage,
      name: 'Strap on watch(White)',
      rating: 3.8,
      reviews: 5289,
      currentPrice: 799,
      originalPrice: 1299,
    },
    {
      image: productImage,
      name: 'Orange watch series 3',
      rating: 4.1,
      reviews: 2476,
      currentPrice: 1499,
      originalPrice: 2599,
    },
    {
      image: productImage,
      name: 'Omega watch special edition',
      rating: 3.8,
      reviews: 1893,
      currentPrice: 2299,
      originalPrice: 3299,
    },
    {
      image: productImage,
      name: 'Fassil smart watch(45mm)',
      rating: 4.5,
      reviews: 12923,
      currentPrice: 899,
      originalPrice: 1299,
    },
  ];

  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const dispatch = useDispatch();

  const cartHandler = (data) => {
    onOpenCart();
  };

  return (
    <Box
      className='similar-products'
      bg='white'
      mt={isSmallerThan767 ? '10px' : '30px'}
      borderTop={'1px solid rgb(238, 238, 238)'}
      pt='10px'
    >
      {related_products && related_products.length > 0 && (
        <>
          <Text mb='15px' fontSize={'18px'} fontWeight={600}>
            Related Items
          </Text>
          <Slider items={related_products || []} onCartOpen={cartHandler} />
        </>
      )}

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome />
      </Modal>
    </Box>
  );
};

export default SimilarProducts;
