import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M8.82491 15.5663C12.5481 15.5663 15.5663 12.5481 15.5663 8.82491C15.5663 5.10173 12.5481 2.0835 8.82491 2.0835C5.10173 2.0835 2.0835 5.10173 2.0835 8.82491C2.0835 12.5481 5.10173 15.5663 8.82491 15.5663Z'
        stroke='#F41106'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.5137 13.8638L16.1567 16.4999'
        stroke='#F41106'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default SearchIcon;
