import { Flex, Input, Text, Textarea } from '@chakra-ui/react';
import { useField, Field } from 'formik';

const FormInput = ({ label, labelProps, as, flexWidth, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Flex flexDir='column' gap='10px' width={flexWidth}>
      <Flex gap='10px' align='center'>
        <Text
          as='label'
          htmlFor={props.id || props.name}
          textStyle='body-rg'
          fontSize='0.8rem'
          fontWeight={600}
          lineHeight='18px'
          {...labelProps}
        >
          {label}
        </Text>
      </Flex>
      <Field
        as={as === 'Textarea' ? Textarea : Input}
        isInvalid={meta.touched && meta.error}
        borderColor='#000'
        focusBorderColor='darkgray'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Text textStyle='body-rg' color='red' fontWeight={400}>
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormInput;
