import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchSubCategories } from '../../redux/productSlice';
import {
  Box,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Recommendations from '../../components/home/recommendations';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';

let tt;
let count = 0;
const SubCategory = () => {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const { subCategoriesList, isLoading } = useSelector(
    (state) => state.product
  );
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (id) {
      // count++;
      dispatch(fetchSubCategories(id));
    }
  }, [dispatch, id]);

  const cartHandler = (data) => {
    onOpenCart();
  };

  const padding = location.pathname.includes('/product/category')
    ? '0px !important'
    : 'none';

  return (
    <Container
      maxW='100%'
      padding={padding}
      // padding={'0 -120px !important'}
    >
      <Text
        fontSize={isSmallerThan767 ? '18px' : '22px'}
        mt='20px'
        fontWeight={600}
        ml={isSmallerThan767 ? '15px' : '0'}
      >
        {name}
      </Text>
      <Box mt='1.25rem' mb='1.25rem'>
        <hr />
      </Box>
      {subCategoriesList.length === 0 && !isLoading && (
        <Flex align={'center'} justify={'center'} p={5}>
          <Text>No data found!</Text>
        </Flex>
      )}

      <SimpleGrid columns={isSmallerThan767 ? 2 : 5} spacing={'30px'}>
        {isLoading ? (
          <>
            {[0, 1, 2, 4, 5].map((item) => (
              <Skeleton
                bg='#F5F5F5'
                height={'124px'}
                w={'124px'}
                borderRadius={'50%'}
              ></Skeleton>
            ))}
          </>
        ) : (
          subCategoriesList.map((item) => (
            <Flex
              flexDir={'column'}
              align={'center'}
              justify={'center'}
              textAlign={'center'}
            >
              <Flex
                bg='#F5F5F5'
                height={'124px'}
                w={'124px'}
                borderRadius={'50%'}
                align={'center'}
                justify={'center'}
                cursor={'pointer'}
                onClick={() =>
                  navigate(
                    `/product/listing?categoryId=${
                      item.id
                    }&name=${encodeURIComponent(item.name.replace(/ /g, '-'))}`
                  )
                }
              >
                <Image
                  height={'124px'}
                  w={'124px'}
                  borderRadius={'50%'}
                  border='2px #F5F5F5 solid'
                  _hover={{ border: '2px red solid' }}
                  src={
                    item.image ||
                    require('../../assets/img/product/no-image.png')
                  }
                />
              </Flex>

              <Text
                color={'#000'}
                fontWeight={700}
                fontSize={'16px'}
                lineHeight={'20px'}
                mt={'20px'}
              >
                {item.name}
              </Text>
            </Flex>
          ))
        )}
      </SimpleGrid>

      <Box px='10px'>
        <Recommendations
          title={
            <Text
              fontSize={isSmallerThan767 ? '18px' : '24px'}
              fontWeight={700}
              lineHeight={'30px'}
              color={'#666'}
              mb={isSmallerThan767 ? '10px' : '30px'}
              mt='40px'
            >
              Recommendations <span style={{ color: '#F41106' }}>For you</span>
            </Text>
          }
          onCartOpen={cartHandler}
        />
      </Box>

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome />
      </Modal>
    </Container>
  );
};

export default SubCategory;
