import { Image } from '@chakra-ui/react';
import React from 'react';
import Setting from "../../../assets/img/icons/settingIcon.svg"
import { useLocation } from 'react-router-dom';


const Icon = () => {
  const location = useLocation();
  const isSettingsPage = location.pathname === '/settings';
  return <Image src={Setting} color={isSettingsPage ? 'red' : 'currentColor'}/>;
};

export default Icon;

