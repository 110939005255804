import React, { useState } from 'react';
import {
  Box,
  Image,
  Text,
  Flex,
  Badge,
  Button,
  Divider,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import styles from './order-card.module.scss';
import { useNavigate } from 'react-router-dom';
import { confirmRecievedHandler } from '../../redux/orderSlice';
import { useDispatch } from 'react-redux';

const OrderCard = ({ order, onCancel }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [confirmReceived, setConfirmReceived] = useState(false);

  // const actionLabel = () => {
  //   switch (order.status) {
  //     case 'created':
  //       return 'Cancel Order';
  //     case 'submitted':
  //       return 'Create Ticket';
  //     case 'delivered':
  //       return 'Return Order';
  //     default:
  //       break;
  //   }
  // };

  const returnRequestStatus = () => {
    switch (order.is_return_request) {
      case 1:
        return 'Pending';
      case 2:
        return 'Approved';
      case 3:
        return 'Rejected';
      default:
        return '';
    }
  };

  const handleActionClick = (e) => {
    e.stopPropagation();

    switch (order.status) {
      case 'created':
        onCancel(order.id);
        break;
      case 'delivered':
        navigate(`/return-order/${order.id}`);
        break;
      default:
        break;
    }
  };

  const handleConfirmReceived = async (e) => {
    e.stopPropagation();
    setConfirmReceived(true);
    const response = await dispatch(confirmRecievedHandler(order.id));
    const res = response.data
    console.log(res, "testtttt")
    if (res.success) {
      toast({
        title: 'Confirmation Successful',
        description: res.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: res.message || 'Failed to confirm the order.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRequestReturn = (e) => {
    e.stopPropagation();
    navigate(`/return-order/${order.id}`);
  };

  return (
    <Box
      maxW='sm'
      borderRadius='lg'
      overflow='hidden'
      bg='white'
      onClick={() => {
        navigate(`/order-detail/${order.id}`);
      }}
      cursor={'pointer'}
      minH={isSmallerThan767 ? '240px' : '285px'}
      boxShadow={'0 .125rem 0 rgba(10,10,10,.04)'}
    >
      <Flex p='4'>
        <Image
          boxSize='40px'
          objectFit='cover'
          src={order.product_image}
          alt={order.product_name}
          bg='#F3F6F8'
          p={2}
          borderRadius='8px'
        />
        <Flex flexDirection='column' ml={4} justifyContent='space-around'>
          <Text
            className={styles.product_name}
            fontWeight='600'
            fontSize={isSmallerThan767 ? '12px' : '14px'}
            title={order?.product_name}
          >
            {order?.product_name}
          </Text>
          <Text
            color='green.500'
            fontSize={isSmallerThan767 ? '12px' : '14px'}
            fontWeight='600'
          >
            {order?.currency}
            {order?.total_amount}
          </Text>
        </Flex>
        <Text fontWeight='600' fontSize='14px' color='#000'>
          Order Id:{' '}
          <strong style={{ fontSize: 13, fontWeight: 400, color: '#333' }}>
            {order?.order_no}
          </strong>
        </Text>
      </Flex>
      <Divider />
      <Flex justifyContent='space-between' gap='3rem' p='4'>
        <Flex flexDir='column'>
          <Text fontSize='13px' color='#000' fontWeight={600}>
            Delivery Address:
          </Text>
          <Text fontSize='13px' color={'#8c9097'}>
            {order?.shipping_address?.shipping_address},{' '}
            {order?.shipping_address?.shipping_city},{' '}
            {order?.shipping_address?.shipping_country}
          </Text>
        </Flex>
        <Box>
          <Text fontSize='sm' color='gray.500'>
            {/* {order.deliveryDate.split(' ')[0]} */}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            {/* {order.deliveryDate.split(' ')[1]} */}
          </Text>
        </Box>
      </Flex>
      <Divider />
      <Flex justifyContent='space-between' alignItems='center' p='4'>
        <Flex alignItems='center'>
          <Text fontSize='13px' color={'#8c9097'}>
            Status:
          </Text>
          <Badge
            ml={2}
            colorScheme={
              order.status === 'shipped' ||
                order.status === 'created' ||
                order.status === 'delivered' ||
                order.status === 'submitted'
                ? 'green'
                : 'red'
            }
          >
            <Text
              fontSize={'10px'}
              textTransform={'capitalize'}
              fontWeight={600}
            >
              {order.status}{' '}
            </Text>

            {/* {order.is_return_request ? returnRequestStatus() :  null } */}
          </Badge>
        </Flex>
        {/* {order.status !== 'cancelled' &&
          order.status !== 'created' &&
          order.status !== 'returned' &&
          order.status !== 'shipped' && (
            <Button
              // onClick={(e) => {
              //   e.stopPropagation();
              //   onCancel(order.id);
              // }}
              onClick={handleActionClick}
              size='sm'
              colorScheme={order.actionColor}
            >
              {order.is_return_request == 0 ? returnRequestStatus() : "Return Order"}
            </Button>
          )} */}
        <Flex >
          {order?.status === 'shipped' && (
            <Flex justifyContent='center' p='4'>
              <Button
                size='sm'
                colorScheme='blue'
                onClick={handleConfirmReceived}
                style={{
                  height: '10px',
                  borderRadius: '15px',
                }}
              >
                Confirm Received
              </Button>
            </Flex>
          )}

          {order?.status === 'delivered' && (
            <Flex justifyContent='center' p='4'>
              <Button
                size='sm'
                colorScheme='blue'
                onClick={handleRequestReturn}
                style={{
                  height: '10px',
                  borderRadius: '15px',
                }}
              >
                {order?.is_return_available ? 'Returns/refunds' : ''}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>

    </Box>
  );
};

export default OrderCard;
