import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import './slider.scss';
// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { useSelector } from 'react-redux';
import ShareButton from '../../../components/share-button';

const ProductSlider = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { productDetails } = useSelector((state) => state.product);
  const [swiperRef, setSwiperRef] = useState(null);

  // const ChangeSlide = ({ position }) => {
  //   const swiper = useSwiper();

  //   useEffect(() => {
  //     if (swiper) {
  //       swiper.slideTo(position);
  //     }
  //   }, [swiper, position]);
  // };
  const slideTo = useCallback(
    (index) => {
      swiperRef?.slideTo(index, 0);
    },
    [swiperRef]
  );
  useEffect(() => {
    if (productDetails?.imageIndex) slideTo(productDetails?.imageIndex || 0);
  }, [productDetails?.imageIndex, slideTo]);

  return (
    <Box className='product-slider slider-container'>
      {images?.length > 0 && (
        <>
          {' '}
          <Swiper
            onSwiper={setSwiperRef}
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            spaceBetween={10}
            loop={true}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {/* <ChangeSlide position={productDetails?.imageIndex || 0} /> */}
            {images?.map((image, index) => (
              <SwiperSlide key={image.id} virtualIndex={index}>
                <Box
                  bg={index === activeIndex ? '#F3F6F8' : '#fff'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'360px'}
                >
                  <div className='_imgSliderBx'>
                  <Image
                    w={'360px'}
                    // border={'1px solid #EDEDED'}
                    borderRadius={'4px'}
                    src={image.image}
                  />
                  <ShareButton postUrl={window?.location?.href}/>
                  </div>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <Flex mt={'10px'} justifyContent={'center'}>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={5}
              slidesPerView={5}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              style={{ alignSelf: 'center' }}
            >
              {images?.map((image, index) => (
                <SwiperSlide key={image.id}>
                  <Box
                    onClick={() => setActiveIndex(index)}
                    cursor={'pointer'}
                    _hover={{
                      border: '1px solid #F41106',
                      boxShadow:
                        '0px 151px 42px 0px rgba(0, 0, 0, 0.00), 0px 97px 39px 0px rgba(0, 0, 0, 0.01), 0px 54px 33px 0px rgba(0, 0, 0, 0.03), 0px 24px 24px 0px rgba(0, 0, 0, 0.04), 0px 6px 13px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
                    }}
                    border={'1px solid #EDEDED'}
                    borderRadius={'4px'}
                    bg={index === activeIndex ? '#F3F6F8' : '#fff'}
                    p={'5px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    w={'56px'}
                    h={'56px'}
                  >
                    <Image w={'46px'} h={'46px'} src={image.image} />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default ProductSlider;
