import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/style.scss';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers['Api-Key'] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem('token');

    if (token) {
      reqConfiglocal.headers['Authorization-Token'] = `${token}`;
    }
    return reqConfiglocal;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return error;
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
