import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import LeftImage from "../../assets/img/about-bg.svg";
import BgImage from "../../assets/img/about.jpg";
import { getData } from "../../services/staticpage-service";

const Index = () => {
  const [productsCount, setProductsCount] = useState(1);
  const [usersCount, setUsersCount] = useState(1);
  const [data, setData] = useState("")
  const [loading, setLoading] = useState(false)

  const getPageData = async (about_us) => {
    setLoading(true)
    try {
      const res = await getData(about_us);
      setData(res.data.data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getPageData("about_us")
  }, [])

  useEffect(() => {
    const animateCounts = () => {
      if (productsCount < 1600000) {
        setTimeout(() => {
          setProductsCount(productsCount + 5000);
        }, 5);
      }
      if (usersCount < 1000000) {
        setTimeout(() => {
          setUsersCount(usersCount + 5000);
        }, 5);
      }
    };

    animateCounts();

    return () => {
      clearTimeout();
    };
  }, [productsCount, usersCount]);

  return (
    <>
      <Box>
        <Box>
          <Box
            p="20px"
            m="20px"
            color="white"
            border="1px solid gray"
            borderRadius="10px"
          >
            <Box
              padding="15px 40px"
              position="relative"
              borderRadius="10px"
              border="1px solid transparent"
              height={{ base: "1100px", md: "500px" }}
              display="flex"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              bgImage={`url(${BgImage})`}
              bgSize="cover"
              bgPosition="center"
              bgRepeat="no-repeat"
            >
              <Flex
                align="center"
                justify="space-between"
                direction={{ base: "column", md: "row" }}
              >
                <Box
                  maxW={{ base: "100%", md: "600px" }}
                  textAlign={{ base: "left", md: "left" }}
                  marginTop={{ base: "-400px", md: "0px" }}
                >
                  {/* <Box>
                    <Text fontWeight="bold">About us </Text>
                    <Box
                      w="10%"
                      h="5px"
                      bgGradient="linear(to-r, #ffffff, rgba(155, 89, 182, 0))"
                      borderRadius="5px"
                      mb="10px"
                      mt="10px"
                    />
                  </Box> */}
                  <Text fontSize="3xl" fontWeight="bold">
                    DISCIPLINE IS WHAT{" "}
                    <Text as="span" color="teal.300">
                      MOTIVATES
                    </Text>{" "}
                    YOU MOST
                  </Text>
                  <Text mt={4}>
                    Welcome to <strong>Kwelibuy</strong>,<br /> Your gateway to the future of
                    e-commerce in Africa. As a proud subsidiary of{" "}
                    <strong>Ekweli</strong>,
                    We have been dedicated to serving the African market for nearly a
                    decade. Our mission is to empower customers across Africa by
                    providing them with the opportunity to shop from all over the world,
                  </Text>
                </Box>
                <Box display={{ base: "none", md: "block" }}>
                  <Image src={LeftImage} alt="About Image" width="70%" />
                </Box>
              </Flex>

              <Flex
                flexDir={{ base: "column", md: "row" }}
                position="absolute"
                bottom={{ base: "7%", md: "-45px" }}
                bg="white"
                color="black"
                left="50%"
                transform="translateX(-50%)"
                boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
                borderRadius="8px"
              >
                <Flex
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderRight="1px dashed gray"
                  height="100px"
                  width="200px"
                >
                  <Text fontSize="sm">Products</Text>
                  <Text fontSize="3xl">
                    {(productsCount / 1000000).toFixed(1)}M+
                  </Text>
                </Flex>
                <Flex
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  height="100px"
                  width="200px"
                >
                  <Text fontSize="sm">Users</Text>
                  <Text fontSize="3xl">{(usersCount / 1000000).toFixed(1)}M+</Text>
                </Flex>
              </Flex>
            </Box>

            <Box p={{ base: 4, md: 8 }} maxW="6xl" mx="auto" mt="80px">
              <Text fontSize="24px" color="black" fontWeight="500" mb={2}>
                About Us
              </Text>
              <Box
                w="10%"
                h="5px"
                bgGradient="linear(to-r,  #f41106, rgba(155, 89, 182, 0))"
                borderRadius="5px"
                mb="10px"
                mt="10px"
              />
              {loading ? (
                "Loading..."
              ) : (
                <Text alignItes="center" padding={{base:'10px 5px', md:'30px 50px'}}>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </Text>
              )}
            </Box>
          </Box>
        </Box>

      </Box>
    </>
  );
};

export default Index;
