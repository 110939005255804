import React, { useEffect, useRef, useState } from "react";
import shareIcon from "../../assets/img/fi_share-2.svg";
import { AttachmentIcon, EmailIcon } from "@chakra-ui/icons";
import {
  FaXTwitter,
  FaFacebook,
  FaWhatsapp,
  FaTelegram,
} from "react-icons/fa6";
import {
  useMediaQuery,
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";

const ShareButton = ({ postUrl }) => {
  const toast = useToast();
  const [isSmallerThan767] = useMediaQuery("(max-width: 767px)");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const shareModalOpen = () => setIsModalOpen(true);
  const shareModalClose = () => setIsModalOpen(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isSmallerThan767) {
      setIsModalOpen(false);
    } else {
      setIsOpen(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSmallerThan767]);

  const postText = `
    I found something amazing: ${postUrl} 🌐
    
    Ready to elevate your shopping experience? Click the link below to start exploring:

    We believe that Kwelibuy can enhance your online shopping journey.
    
    Kwelibuy offeres you convenience, variety, and addtional value. 
    
    Check out our latest deals and updates!

    We got you covered, visit us at https://Kwelibuy.com
    `;

  const copyLinkToClipboard = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(postUrl);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = postUrl;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
      }
      toast({
        title: "Link copied to clipboard.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setIsOpen(false);
      // setIsModalOpen(false);
    } catch (err) {
      toast({
        title: "Failed to copy link.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const shareViaEmail = () => {
    const subject = encodeURIComponent("Check out this post!");
    const body = encodeURIComponent(postText);
    window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
    setIsOpen(false);
    setIsModalOpen(false);
  };

  const shareViaFacebook = () => {
    const url = encodeURIComponent(postUrl);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    setIsOpen(false);
    setIsModalOpen(false);
  };

  const shareViaTwitter = () => {
    const url = encodeURIComponent(postUrl);
    const text = encodeURIComponent(postText);
    window.open(
      `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    setIsOpen(false);
    setIsModalOpen(false);
  };

  const shareViaWhatsApp = () => {
    const url = encodeURIComponent(postUrl);
    const text = encodeURIComponent(postText);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${text}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
    setIsOpen(false);
    setIsModalOpen(false);
  };

  const shareViaTelegram = () => {
    const url = encodeURIComponent(postUrl);
    const text = encodeURIComponent(postText);
    const telegramUrl = `https://telegram.me/share/url?url=${url}&text=${text}`;
    window.open(telegramUrl, "_blank", "noopener,noreferrer");
    setIsOpen(false);
    setIsModalOpen(false);
  };

  const shareViaLinkedIn = () => {
    const url = encodeURIComponent(postUrl);
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    setIsOpen(false);
    setIsModalOpen(false);
  };

  return (
    <>
      {!isSmallerThan767 && (
        <div className=" _shrDropIcon" ref={dropdownRef}>
          <div className="dropdown">
            <img
              src={shareIcon}
              onClick={toggleDropdown}
              className="dropbtn"
              alt="Share"
            />
            <div className={`dropdown-content ${isOpen ? "show" : ""}`}>
              <div className="_mainDeskShare_div">
                <div
                  className="_cmnSocialDeskt _twitterShl"
                  onClick={copyLinkToClipboard}
                >
                  <div className="_innrSocialDesktop">
                    <i className="fa-regular fa-copy"></i>
                  </div>
                  <span> Copy Link </span>
                </div>
                <div
                  className="_cmnSocialDeskt _facebookShl"
                  onClick={shareViaFacebook}
                >
                  <div className="_innrSocialDesktop">
                    <i className="fa-brands fa-facebook"></i>
                  </div>
                  <span> Facebook </span>
                </div>
                <div
                  className="_cmnSocialDeskt _twitterShl"
                  onClick={shareViaTwitter}
                >
                  <div className="_innrSocialDesktop">
                    <i className="fa-brands fa-x-twitter"></i>
                  </div>
                  <span> Twitter </span>
                </div>
                <div
                  className="_cmnSocialDeskt _whatappShl"
                  onClick={shareViaWhatsApp}
                >
                  <div className="_innrSocialDesktop">
                    <i className="fa-brands fa-whatsapp"></i>
                  </div>
                  <span> WhatsApp </span>
                </div>
              </div>
              {/* <a onClick={copyLinkToClipboard}>
                <AttachmentIcon /> Copy Link
              </a>
              <a onClick={shareViaTwitter} className="icon_flexCls">
                <FaXTwitter /> Twitter
              </a>
              <a onClick={shareViaFacebook} className="icon_flexCls">
                <FaFacebook /> Facebook
              </a>
              <a onClick={shareViaWhatsApp} className="icon_flexCls">
                <FaWhatsapp /> WhatsApp
              </a> */}

              {/* <a onClick={shareViaLinkedIn}>
                <i className="fa-brands fa-linkedin"></i> LinkedIn
              </a> */}
              {/* 
              <a onClick={shareViaTelegram} className="icon_flexCls">
                <FaTelegram /> Telegram
              </a> */}
              {/* <a onClick={shareViaEmail}>
                <EmailIcon /> Email
              </a> */}
            </div>
          </div>
        </div>
      )}

      {isSmallerThan767 && (
        <div className=" _shrDropIcon">
          <img
            src={shareIcon}
            onClick={shareModalOpen}
            className="dropbtn"
            alt="Share"
          />
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={shareModalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <div className="_mainShare_div">
            <div className="_cmnSocial _facebookShl" onClick={shareViaFacebook}>
              <div className="_innrSocial">
                <i className="fa-brands fa-facebook"></i>
              </div>
              <span> Facebook </span>
            </div>
            <div className="_cmnSocial _twitterShl" onClick={shareViaTwitter}>
              <div className="_innrSocial">
                <i className="fa-brands fa-x-twitter"></i>
              </div>
              <span> Twitter </span>
            </div>
            <div className="_cmnSocial _whatappShl" onClick={shareViaWhatsApp}>
              <div className="_innrSocial">
                <i className="fa-brands fa-whatsapp"></i>
              </div>
              <span> WhatsApp </span>
            </div>

            <div
              className="_cmnSocial _twitterShl"
              onClick={copyLinkToClipboard}
            >
              <div className="_innrSocial">
                <i className="fa-regular fa-copy"></i>
              </div>
              <span> Copy Link </span>
            </div>

            {/* <div className="_cmnSocial _linkedinShl" onClick={shareViaLinkedIn}>
              <div className="_innrSocial">
                <i className="fa-brands fa-linkedin"></i>
              </div>
              <span> LinkedIn </span>
            </div>

            <div className="_cmnSocial _emailShl" onClick={shareViaEmail}>
              <div className="_innrSocial">
                <i className="fa-regular fa-envelope"></i>
              </div>
              <span> Email </span>
            </div>
            
            <div className="_cmnSocial _telegreShl" onClick={shareViaTelegram}>
              <div className="_innrSocial">
                <i className="fa-brands fa-telegram"></i>
              </div>
              <span> Telegram </span>
            </div> */}
          </div>
          <button className="btn_footerShare" onClick={shareModalClose}>
            {" "}
            Close{" "}
          </button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareButton;
