import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeData } from '../../redux/homeSlice';
import ProductCard from '../product-card';

const ProductSlider = ({ items, isBestDeals, onCartOpen }) => {
  const dispatch = useDispatch();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan1185] = useMediaQuery('(max-width: 1185px)');
  const [isSmallerThan1430] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1530] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan1920] = useMediaQuery('(max-width: 1920px)');
  const { homeData } = useSelector((state) => state.home);

  useEffect(() => {
    const bullets = document.querySelectorAll('.swiper-pagination-bullet');
    bullets.forEach((bullet) => {
      bullet.style.backgroundColor = 'red';
      bullet.style.marginBottom = '-20px';
    });
  }, []);

  const slidesPerView = isSmallerThan767
    ? 2.5
    : isSmallerThan1185
    ? 3.5
    : isSmallerThan1430
    ? 4.5
    : isSmallerThan1530
    ? 5.5
    : isSmallerThan1800
    ? 6.5
    : isSmallerThan1920
    ? 6.5
    : 6.5;

  const spaceBetween = isSmallerThan767 ? 8 : 16;

  return (
    <div mb='20px' className='deals-banner'>
      <Swiper
        loop={true}
        // allowSlidePrev={true}
        // allowSlideNext={true}
        modules={[Pagination, Navigation]}
        // navigation
        pagination={{ clickable: true }}
        // slidesPerView={isSmallerThan767 ? 1 : 5}
        slidesPerView={slidesPerView}
        spaceBetween={isSmallerThan767 ? 8 : 16}
        onSlideChange={(swiper) => {}}
        onSwiper={(swiper) => {}}
        gridTemplateColumns={`repeat(${slidesPerView}, minmax(225px, 1fr))`}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <ProductCard
              onCartOpen={() => onCartOpen(item)}
              item={item}
              onMouseEnter={() => {
                const _temp = [...items];
                const map = _temp.map((item, itemIndex) => {
                  if (index === itemIndex) {
                    return { ...item, showIcons: true };
                  }
                  return { ...item, showIcons: false };
                });
                dispatch(setHomeData({ ...homeData, best_deal_products: map }));
              }}
              onMouseLeave={() => {
                const _temp = [...items];
                const map = _temp.map((item, itemIndex) => {
                  if (index === itemIndex) {
                    return { ...item, showIcons: false };
                  }
                  return { ...item, showIcons: false };
                });
                dispatch(setHomeData({ ...homeData, best_deal_products: map }));
              }}
              isBestDeals={isBestDeals}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSlider;
