import { createSlice } from '@reduxjs/toolkit';
import { ezipay } from '../services/payment-service';

const initialState = {
  isLoading: false,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = paymentSlice.actions;

export default paymentSlice.reducer;

export const ezipayHandler = (data) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const country = getState().home.country;
    const currency = getState().home.currency;

    const payload = {
      amount: data.amount,
      mobile: data.mobile,
      currency,
      country,
      user_id: userData?.id || guestInfo.id,
    };
    if (data?.order_id) {
      payload.order_id = data?.order_id;
    }

    return await ezipay(payload);
  } catch (error) {
    return error;
  }
};
