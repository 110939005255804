import { createSlice } from '@reduxjs/toolkit';
import {
  addToCart,
  applyCoupon,
  deleteCart,
  getCartList,
  updateCart,
  updateCartQuantity,
} from '../services/cart-service';

const initialState = {
  isLoading: false,
  cartList: [],
  cartDetails: null,
  updatedCartDetails: null,
  coupon: null,
  selectedItems: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCartList(state, action) {
      state.cartList = action.payload;
    },
    setCartDetails(state, action) {
      state.cartDetails = action.payload;
    },
    removeProductFromCart(state, action) {
      const productId = action.payload;
      state.cartList = state.cartList.filter((item) => item.id !== productId);
    },
    setUpdatedCartDetails(state, action) {
      state.updatedCartDetails = action.payload;
    },
    setCoupon(state, action) {
      state.coupon = action.payload;
    },
    removeCoupon(state) {
      state.coupon = null;
    },
    setSelectedItems(state, action) {
      state.selectedItems = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setProductDetails,
  setCartList,
  setCartDetails,
  removeProductFromCart,
  setUpdatedCartDetails,
  setCoupon,
  removeCoupon,
  setSelectedItems,
} = cartSlice.actions;

export default cartSlice.reducer;

export const addToCartHandler =
  (data, isWishlist = 0) =>
  async (dispatch, getState) => {
    try {
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const country = getState().home.country;
      const currency = getState().home.currency;
      const payload = {
        currency,
        country,
        product_id: isWishlist === 1 ? data.product_id : data?.id,
        user_id: userData?.id || guestInfo.id,
        quantity: data.quantity || 1,
        amount: data.price,
        varient_value: data?.default_sku_attribute,
        sku_id: data.sku_id,
        is_wishlist: isWishlist,
        ...(isWishlist === 0 && {
          logistic_service: data.logistic_service,
          shipping: data.shipping,
        }),
      };

      if (data?.is_cart) {
        payload.is_cart = 1;
      }
      if (data?.is_buy_now) {
        payload.is_buy_now = 1;
      }
      return await addToCart(payload);
    } catch (error) {
      return error;
    }
  };
export const updateCartQuantityHandler =
  (payload, quantity) => async (dispatch, getState) => {
    try {
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const country = getState().home.country;
      const currency = getState().home.currency;
      const _payload = {
        id: payload.id,
        quantity,
        user_id: userData?.id || guestInfo.id,
        amount: parseFloat(payload.price.replace(',', '')),
        currency,
        country,
      };
      const response = await updateCartQuantity(_payload);
      if (response.status === 200) {
        dispatch(fetchCartList(userData?.id || guestInfo.id));
      }
      return response;
    } catch (error) {
      return error;
    }
  };
export const updateCartHandler = (payload) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const country = getState().home.country;
    const currency = getState().home.currency;
    const _payload = {
      id: payload.id,
      user_id: userData?.id || guestInfo.id,
      shipping: parseFloat(payload.shipment_fee.replace(',', '')),
      currency,
      country,
    };
    const response = await updateCart(_payload);
    if (response.status === 200) {
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }
  } catch (error) {
    return error;
  }
};
export const fetchCartList = (userId) => async (dispatch, getState) => {
  try {
    const country = getState().home.country;
    const currency = getState().home.currency;
    const response = await getCartList(userId, country, currency);
    if (response.status === 200) {
      //check if item is not in selectedItems
      const selectedItems = getState().cart.selectedItems;

      response.data.data.forEach((el) => {
        if (selectedItems.some((item) => item.id === el.id)) {
          el.selected = true;
        } else if (selectedItems.length === 0) {
          el.selected = true;
        }
      });
      if (selectedItems.length === 0) {
        dispatch(setSelectedItems(response.data.data));
      }

      dispatch(setCartList(response.data.data));
      localStorage.setItem('cartDetails', JSON.stringify(response.data));
      dispatch(setCartList(response.data.data));

      console.log(response.data, 'response.data');

      dispatch(setCartDetails(response.data));
    }
  } catch (error) {
    return error;
  }
};
export const fetchCartDetails = (userId) => async (dispatch) => {
  try {
    const cartDetails = JSON.parse(localStorage.getItem('cartDetails'));
    dispatch(setCartDetails(cartDetails));
  } catch (error) {
    return error;
  }
};

export const deleteCartHandler = (id) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const userId = userData?.id || guestInfo.id;
    const paylaod = { id, user_id: userId };
    const response = await deleteCart(paylaod);
    if (response.status === 200) {
      dispatch(removeProductFromCart(id));
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }
  } catch (error) {
    return error;
  }
};

export const applyCouponHandler = (payload) => async (dispatch) => {
  try {
    const response = await applyCoupon(payload);
    if (response.status === 200) {
      dispatch(setUpdatedCartDetails(response.data));
    }
    return response;
  } catch (error) {
    console.error('Error applying coupon:', error);
    throw error;
  }
};

// export const removeCouponHandler = () => (dispatch) => {
//   dispatch(removeCoupon());
// };
