import React, { useState } from 'react';
import {
  Box,
  Text,
  Link,
  Button,
  VStack,
  HStack,
  Image,
  Icon,
  useToast,
  Spinner,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import productImage from '../../../assets/img/product/25.png';
import Logistics from '../logistics';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartHandler, fetchCartList } from '../../../redux/cartSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addToWishlistHandler,
  deleteWishListHandler,
  fetchWishList,
} from '../../../redux/wishlistSlice';
import NumberGroupInput from '../../../components/number-group-input';
import isEmpty from 'is-empty';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';

const OfferSection = ({ fromHome, onClose, isCount = 1, fromCart }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const { productDetails, selectedShipment } = useSelector(
    (state) => state.product
  );
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [qty, setQty] = useState(1);
  const [cartLoading, setCartLoading] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [viewDetailsLoading, setViewDetailsLoading] = useState(false);
  const product = productDetails?.products;

  const [isInWishlist, setIsInWishlist] = useState(product?.is_wishlist === 1);
  const [isHeartHovered, setIsHeartHovered] = useState(false);
  const isProductDetailsPage = location.pathname.includes('/product/details');
  const isProductPage = location.pathname.includes('/product');

  const handleWishlistToggle = (e) => {
    e.stopPropagation();
    const itemId = product?.id ? String(product?.id) : null;
    const userId = userData?.id
      ? String(userData?.id)
      : guestInfo?.id
      ? String(guestInfo?.id)
      : null;

    if (isInWishlist) {
      dispatch(
        deleteWishListHandler({
          id: itemId,
          user_id: userId,
        })
      ).then((res) => {
        if (res.status === 200) {
          setIsInWishlist(false);
          toast({
            title: 'Product removed from wishlist',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          dispatch(fetchWishList(userData?.id || guestInfo.id));
        }
      });
    } else {
      dispatch(addToWishlistHandler(product)).then((res) => {
        if (res.status === 200) {
          setIsInWishlist(true);
          toast({
            title: 'Product added to wishlist',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }
  };

  return (
    <VStack spacing={4} align='start'>
      {/* {!fromHome && (
        <Box>
          <Text fontWeight='bold'>Returns:</Text>
          <Text fontSize='sm'>
            Min 7 days return and exchange policy. Return Policies may vary
            based on products and promotions. For full details on our Returns
            Policies, please <Link color='blue.500'>click here.</Link>
          </Text>
        </Box>
      )} */}
      {!isSmallerThan767 && (
        <Box style={{ width: '100%' }} mt={5}>
          <Button
            opacity={product?.quantity === 0 ? 0.5 : 1}
            pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
            isDisabled={
              isEmpty(product) ||
              isEmpty(selectedShipment) ||
              selectedShipment === null
            }
            style={{
              width: '100%',
              backgroundColor: '#fa1819',
              color: 'white',
              marginBottom: '10px',
            }}
            onClick={() => {
              setCartLoading(true);
              const payload = {
                ...productDetails?.products,
                logistic_service: selectedShipment?.logistic_service,
                shipping:
                  selectedShipment?.free_shipping === '1'
                    ? 0
                    : selectedShipment?.shipment_fee,
                quantity: isCount,
              };
              if (fromCart) {
                payload.is_cart = true;
              } else {
                payload.is_cart = false;
              }

              dispatch(addToCartHandler(payload)).then((res) => {
                setCartLoading(false);
                if (res.status === 200) {
                  toast({
                    title: 'Product added to cart',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  });
                  dispatch(fetchCartList(userData?.id || guestInfo.id));
                  if (isProductPage && typeof onClose === 'function') {
                    onClose();
                  }
                }
              });
            }}
          >
            {cartLoading ? <Spinner size='sm' /> : 'Add To Cart'}
          </Button>
          {!isProductDetailsPage ? (
            <>
              {/* Show this set of buttons on the /product/cart page */}
              {/* buy now  */}
              <Button
                opacity={product?.quantity === 0 ? 0.5 : 1}
                pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  backgroundColor: 'black',
                  color: 'white',
                }}
                onClick={() => {
                  const payload = {
                    ...productDetails?.products,
                    logistic_service: selectedShipment?.logistic_service,
                    shipping:
                      selectedShipment?.free_shipping === '1'
                        ? 0
                        : selectedShipment?.shipment_fee,
                    quantity: isCount,
                    is_buy_now: 1,
                  };

                  dispatch(addToCartHandler(payload)).then((res) => {
                    setCartLoading(false);
                    if (res.status === 200) {
                      dispatch(fetchCartList(userData?.id || guestInfo.id));
                      navigate('/create/order?buy-now=true');
                      if (isProductPage && typeof onClose === 'function') {
                        onClose();
                      }
                    }
                  });
                }}
                _disabled={{
                  backgroundColor: 'black',
                  opacity: 0.5,
                  cursor: 'not-allowed',
                }}
                isDisabled={
                  isEmpty(product) ||
                  isEmpty(selectedShipment) ||
                  selectedShipment === null
                }
              >
                Buy Now
              </Button>
              {/* add to wishlist  */}
              <Flex dir='row' gap={2}>
                <Button
                  variant='secondary'
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    backgroundColor: '#EDEDED',
                  }}
                  onClick={() => {
                    // setViewDetailsLoading(true);
                    window.open(
                      `/product/details/${product?.product_unique_id}?sku_id=${product.sku_id}`,
                      '_blank'
                    );
                  }}
                  isDisabled={
                    isEmpty(product) ||
                    isEmpty(selectedShipment) ||
                    selectedShipment === null
                  }
                >
                  {viewDetailsLoading ? <Spinner size='sm' /> : 'View Details'}
                </Button>
                <Box
                  className='icon wishlist'
                  height={'40px'}
                  width={'40px'}
                  bg='#ffdedc'
                  borderRadius={'5px'}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  onMouseEnter={() => setIsHeartHovered(true)}
                  onMouseLeave={() => setIsHeartHovered(false)}
                  onClick={handleWishlistToggle}
                  isDisabled={
                    isEmpty(product) ||
                    isEmpty(selectedShipment) ||
                    selectedShipment === null
                  }
                >
                  {isInWishlist || isHeartHovered ? (
                    <RiHeartFill size={'24px'} color='#F41106' />
                  ) : (
                    <RiHeartLine size={'24px'} color='#F41106' />
                  )}
                </Box>
              </Flex>
            </>
          ) : (
            <>
              {/* buy now  */}
              <Button
                opacity={product?.quantity === 0 ? 0.5 : 1}
                pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  backgroundColor: 'black',
                }}
                // variant='secondary'
                onClick={() => {
                  const payload = {
                    ...productDetails?.products,
                    logistic_service: selectedShipment?.logistic_service,
                    shipping:
                      selectedShipment?.free_shipping === '1'
                        ? 0
                        : selectedShipment?.shipment_fee,
                    quantity: isCount,
                    is_buy_now: 1,
                  };

                  dispatch(addToCartHandler(payload)).then((res) => {
                    setCartLoading(false);
                    if (res.status === 200) {
                      dispatch(fetchCartList(userData?.id || guestInfo.id));
                      navigate('/create/order?buy-now=true');
                      if (isProductPage && typeof onClose === 'function') {
                        onClose();
                      }
                    }
                  });
                }}
                _disabled={{
                  backgroundColor: 'black',
                  opacity: 0.5,
                  cursor: 'not-allowed',
                }}
                isDisabled={
                  isEmpty(product) ||
                  isEmpty(selectedShipment) ||
                  selectedShipment === null
                }
              >
                Buy Now
              </Button>
              {/* add to wishlist  */}
              <Button
                variant='secondary'
                style={{
                  width: '100%',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setWishlistLoading(true);
                  dispatch(addToWishlistHandler(productDetails?.products)).then(
                    (res) => {
                      setWishlistLoading(false);
                      if (res.status === 200) {
                        toast({
                          title: 'Product added to wishlist',
                          status: 'success',
                          duration: 2000,
                          isClosable: true,
                        });
                      }
                    }
                  );
                }}
                isDisabled={
                  isEmpty(product) ||
                  isEmpty(selectedShipment) ||
                  selectedShipment === null
                }
              >
                {wishlistLoading ? <Spinner size='sm' /> : 'Add To Wishlist'}
              </Button>
            </>
          )}
        </Box>
      )}

      {isSmallerThan767 && (
        <Flex
          pos={'fixed'}
          bottom={'51px'}
          justify={'space-between'}
          align={'center'}
          zIndex={2}
          width={'calc(100% - 6px)'}
          left={'3px'}
          gap={'15px'}
          bg='#fff'
          padding={'5px'}
        >
          <Box
            className='icon wishlist'
            height={'40px'}
            width={'40px'}
            bg='#ffdedc'
            borderRadius={'5px'}
            display='flex'
            alignItems='center'
            justifyContent='center'
            onMouseEnter={() => setIsHeartHovered(true)}
            onMouseLeave={() => setIsHeartHovered(false)}
            onClick={handleWishlistToggle}
            isDisabled={
              isEmpty(product) ||
              isEmpty(selectedShipment) ||
              selectedShipment === null
            }
          >
            {isInWishlist || isHeartHovered ? (
              <RiHeartFill size={'24px'} color='#F41106' />
            ) : (
              <RiHeartLine size={'24px'} color='#F41106' />
            )}
          </Box>
          <Button
            opacity={product?.quantity === 0 ? 0.5 : 1}
            className='addCart_btn'
            pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
            isDisabled={
              isEmpty(product) ||
              isEmpty(selectedShipment) ||
              selectedShipment === null
            }
            style={{
              width: '50%',
              backgroundColor: '#202020',
              color: 'white',
            }}
            onClick={() => {
              setCartLoading(true);
              const payload = {
                ...productDetails?.products,
                logistic_service: selectedShipment?.logistic_service,
                shipping:
                  selectedShipment?.free_shipping === '1'
                    ? 0
                    : selectedShipment?.shipment_fee,
                quantity: isCount,
              };
              dispatch(addToCartHandler(payload)).then((res) => {
                setCartLoading(false);
                if (res.status === 200) {
                  toast({
                    title: 'Product added to cart',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  });
                  dispatch(fetchCartList(userData?.id || guestInfo.id));
                  onClose();
                }
              });
            }}
          >
            {cartLoading ? <Spinner size='sm' /> : 'Add To Cart'}
          </Button>
          <Button
            opacity={product?.quantity === 0 ? 0.5 : 1}
            pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
            style={{
              width: '50%',
              background: '#F41106',
              color:'#ffff',
              border:'none',
            }}
            variant='secondary'
            onClick={() => {
              const payload = {
                ...productDetails?.products,
                logistic_service: selectedShipment?.logistic_service,
                shipping:
                  selectedShipment?.free_shipping === '1'
                    ? 0
                    : selectedShipment?.shipment_fee,
                quantity: isCount,
                is_buy_now: 1,
              };

              dispatch(addToCartHandler(payload)).then((res) => {
                setCartLoading(false);
                if (res.status === 200) {
                  dispatch(fetchCartList(userData?.id || guestInfo.id));
                  navigate('/create/order?buy-now=true');
                  if (isProductPage && typeof onClose === 'function') {
                    onClose();
                  }
                }
              });
            }}
            isDisabled={
              isEmpty(product) ||
              isEmpty(selectedShipment) ||
              selectedShipment === null
            }
          >
            Buy Now
          </Button>
        </Flex>
      )}
    </VStack>
  );
};

export default OfferSection;
