import React from 'react';

const MenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      style={{ minWidth: '48px' }}
    >
      <rect width='48' height='48' rx='10' fill='#FFEFEE' />
      <line
        x1='12.75'
        y1='15.25'
        x2='37.25'
        y2='15.25'
        stroke='#F41106'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <line
        x1='12.75'
        y1='24.25'
        x2='30.1591'
        y2='24.25'
        stroke='#F41106'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <line
        x1='12.75'
        y1='33.25'
        x2='24.25'
        y2='33.25'
        stroke='#F41106'
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  );
};

export default MenuIcon;
