import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../components/form-input';
import { useDispatch, useSelector } from 'react-redux';
import { addAddressHandler, fetchAddressHandler } from '../../redux/userSlice';
import { setOrderDetails } from '../../redux/orderSlice';

const AddressModal = ({
  isOpen,
  onClose,
  fromOrder,

  setAddress,
}) => {
  const dispatch = useDispatch();
  const [isSmallerThan560] = useMediaQuery('(max-width: 560px)');
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const userId = userData?.id || '';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isSmallerThan560 ? 'full' : 'xl'}
    >
      <ModalOverlay />
      <ModalContent
        maxW={isSmallerThan560 ? 'full' : 'xl'} // 70%
        width={isSmallerThan560 ? 'full' : 'auto'} // 70%
      >
        <ModalHeader borderBottom='#EDEDED 1px solid'>Add Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              phone: '',
              address: '',
              pinCode: '',
              city: '',
              // state: '',
              country: '',
            }}
            validationSchema={Yup.object({
              fullName: Yup.string().required('Required'),
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
              phone: Yup.string().required('Required'),
              address: Yup.string().required('Required'),
              pinCode: Yup.string().required('Required'),
              city: Yup.string().required('Required'),
              // state: Yup.string().required('Required'),
              country: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const payload = {
                  fullName: values.fullName,
                  email: values.email,
                  phone: values.phone,
                  address: values.address,
                  pinCode: values.pinCode,
                  city: values.city,
                  country: values.country,
                };
                if (fromOrder) {
                  dispatch(
                    setOrderDetails({
                      newAddress: true,
                      address: {
                        full_name: values.fullName,
                        email: values.email,
                        phone: values.phone,
                        address: values.address,
                        pin_code: values.pinCode,
                        city: values.city,
                        country: values.country,
                      },
                    })
                  );

                  onClose();
                  return;
                }
                await dispatch(addAddressHandler(payload));
                toast({
                  title: 'Address saved successfully.',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                });
                await dispatch(fetchAddressHandler(userId));
                onClose();
              } catch (error) {
                toast({
                  title: 'Failed to save address.',
                  description: error.message || 'Something went wrong.',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isValid }) => (
              <Flex flexDir='column' as={Form} gap='1.25em' mt='1.26rem'>
                <Flex flexDir='column' gap='20px'>
                  <Flex gap='20px' w='100%'>
                    <FormInput
                      name='fullName'
                      type='text'
                      label='Full Name'
                      placeholder='Full Name'
                      flexWidth='50%'
                    />
                    <FormInput
                      name='email'
                      type='email'
                      label='Email'
                      placeholder='Email'
                      flexWidth='50%'
                    />
                  </Flex>
                  <Flex gap='20px'>
                    <FormInput
                      name='phone'
                      type='number'
                      label='Phone Number'
                      placeholder='Phone Number'
                      flexWidth='50%'
                    />
                    <FormInput
                      name='address'
                      type='text'
                      label='Address'
                      placeholder='Address'
                      flexWidth='50%'
                    />
                  </Flex>
                </Flex>
                <Flex flexDir='row' gap='20px'>
                  <FormInput
                    name='pinCode'
                    type='number'
                    label='Pin Code'
                    placeholder='Pin Code'
                    flexWidth='25%'
                  />
                  <FormInput
                    name='city'
                    type='text'
                    label='City'
                    placeholder='City'
                    flexWidth='25%'
                  />
                  {/* <FormInput
                      name="state"
                      type="text"
                      label="State"
                      placeholder="State"
                      flexWidth="25%"
                    /> */}
                  <FormInput
                    name='country'
                    type='text'
                    label='Country'
                    placeholder='Country'
                    flexWidth='25%'
                  />
                </Flex>
                <hr />
                <Flex justify='flex-end' mt={4}>
                  <Button onClick={onClose} mr={3} variant='secondary'>
                    Close
                  </Button>
                  <Button type='submit' variant='primary' isDisabled={!isValid}>
                    Save Address
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddressModal;
