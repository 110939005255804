import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Image,
  FormLabel,
  Text,
  Grid,
  useDisclosure,
  Flex,
  Spacer,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ModalComponent from '../../../components/address-modal'; // Import the ModalComponent
import TruckImage from '../../../assets/img/product/truck.png';
import InputWithLabel from '../../../components/input-label'; // Assuming InputWithLabel is in a separate file
import { MdAccountCircle } from 'react-icons/md';
import OrderSummary from '../order-summary';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDetails } from '../../../redux/orderSlice';
import FormInputWithLabel from '../../../components/form-input-with-label';
import { fetchAddress } from '../../../services/user-service';
import { checkEmailHandler, setAddressList } from '../../../redux/userSlice';
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import FormRadioInput from '../../../components/form-radio-input';
import isEmpty from 'is-empty';

const validationSchema = Yup.object({
  shipping_name: Yup.string().required('Full name is required'),
  shipping_email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  shipping_phone: Yup.string().required('Phone number is required'),
  shipping_address: Yup.string().required('Address is required'),
  shipping_pincode: Yup.string().required('Pin code is required'),
  shipping_city: Yup.string().required('City is required'),
  // shipping_state: Yup.string().required('State is required'),
  shipping_country: Yup.string().required('Country is required'),
});

const ShippingForm = ({ setTabIndex }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData, addressList } = useSelector((state) => state.user);
  const { country } = useSelector((state) => state.home);
  const { orderDetails } = useSelector((state) => state.order);
  const toast = useToast();

  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const fetchAddressList = useCallback(async () => {
    const response = await fetchAddress(userData?.id);
    if (response.status === 200) {
      dispatch(setAddressList(response.data.data));
    }
  }, [dispatch, userData?.id]);

  useEffectOnce(() => {
    if (userData?.id && addressList.length === 0) fetchAddressList();
  });

  const handleSubmitModal = (e) => {
    e.preventDefault();

    // onClose();
  };

  useEffect(() => {
    if (isEmpty(orderDetails?.newAddress) && addressList.length > 0) {
      dispatch(
        setOrderDetails({
          newAddress: true,
          address: addressList.find((j) => j.is_default === 1),
        })
      );
    }
  }, [addressList, dispatch, orderDetails]);
  return (
    <Box
      className='checkout-border'
      width={isSmallerThan767 ? '100%' : '70%'}
      p={isSmallerThan767 ? 2 : 4}
      backgroundColor='white'
    >
      <Text fontSize='1.5rem' fontWeight='bold' color='gray.200'>
        01
      </Text>
      <Flex flexDir='row' justifyContent='space-between' alignItems='center'>
        <Flex flexDir='column'>
          <Text fontSize='lg' fontWeight='bold'>
            Shipping Address:
          </Text>
        </Flex>
        {!isEmpty(userData) && addressList.length > 0 && (
          <Button onClick={onOpen} mb={4}>
            Add New Address
          </Button>
        )}
      </Flex>
      <Formik
        enableReinitialize={true}
        initialValues={{
          shipping_name:
            orderDetails?.address?.full_name || userData?.name || '',
          shipping_email: orderDetails?.address?.email || userData?.email || '',
          shipping_phone: orderDetails?.address?.phone || userData?.phone || '',
          shipping_address: orderDetails?.address?.address || '',
          shipping_pincode: orderDetails?.address?.pin_code || '',
          shipping_city: orderDetails?.address?.city || '',
          // shipping_state: address?.state || '',
          shipping_country: orderDetails?.address?.country || country,
          isBillingAddress: 'same',
          billing_name: '',
          billing_email: '',
          billing_phone: '',
          billing_address: '',
          billing_pincode: '',
          billing_city: '',
          // billing_state: '',
          billing_country: orderDetails?.address?.country || country,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          try {
            if (isEmpty(userData)) {
              dispatch(
                checkEmailHandler({ email: values.shipping_email })
              ).then((res) => {
                if (res?.data?.success) {
                  dispatch(
                    setOrderDetails({ shipping: values, newAddress: true })
                  );
                  setTabIndex(1);
                } else {
                  toast({
                    title: res?.data?.message ?? 'Something went wrong',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                }
              });
            } else {
              dispatch(setOrderDetails({ shipping: values, newAddress: true }));
              setTabIndex(1);
            }
          } catch (error) {}
        }}
        validateOnMount
      >
        {({ isValid, values }) => (
          <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
            <Flex flexDir={'column'} gap={'20px'}>
              <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                <FormInputWithLabel
                  name='shipping_name'
                  type='text'
                  label='Full Name'
                />

                <FormInputWithLabel
                  name='shipping_email'
                  type='email'
                  label='Email Address'
                />

                <FormInputWithLabel
                  name='shipping_phone'
                  type='number'
                  label='Phone No'
                />
                <FormInputWithLabel
                  name='shipping_address'
                  type='text'
                  label='Address'
                />
              </Grid>
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                gap={4}
              >
                <FormInputWithLabel
                  name='shipping_pincode'
                  type='number'
                  label='Pin Code'
                />
                <FormInputWithLabel
                  name='shipping_city'
                  type='text'
                  label='City'
                />
                {/* <FormInputWithLabel
                  name='shipping_state'
                  type='text'
                  label='State'
                /> */}
                <FormInputWithLabel
                  name='shipping_country'
                  type='text'
                  label='Country'
                  readOnly
                />
              </Grid>
              {/* <Box>
                <Text fontSize='lg' fontWeight='bold' mb='15px'>
                  Billing Address:
                </Text>
                <FormRadioInput
                  name='isBillingAddress'
                  label={''}
                  options={[
                    {
                      label: 'Same as shipping address',
                      value: 'same',
                    },
                    {
                      label: 'Add new billing address',
                      value: 'different',
                    },
                  ]}
                />
                {values.isBillingAddress === 'different' && (
                  <Flex flexDir={'column'} gap={'20px'} mt='20px'>
                    <Grid
                      templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                      gap={4}
                    >
                      <FormInputWithLabel
                        name='billing_name'
                        type='text'
                        label='Full Name'
                      />

                      <FormInputWithLabel
                        name='billing_email'
                        type='email'
                        label='Email Address'
                      />

                      <FormInputWithLabel
                        name='billing_phone'
                        type='number'
                        label='Phone No'
                      />
                      <FormInputWithLabel
                        name='billing_address'
                        type='text'
                        label='Address'
                      />
                    </Grid>
                    <Grid
                      templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
                      gap={4}
                    >
                      <FormInputWithLabel
                        name='billing_pincode'
                        type='number'
                        label='Pin Code'
                      />
                      <FormInputWithLabel
                        name='billing_city'
                        type='text'
                        label='City'
                      />
                      
                      <FormInputWithLabel
                        name='billing_country'
                        type='text'
                        label='Country'
                        readOnly
                      />
                    </Grid>
                  </Flex>
                )}
              </Box> */}
            </Flex>

            <Flex justifyContent='flex-end' width='100%'>
              <Box textAlign={'center'}>
                <Button
                  variant={'secondary'}
                  isDisabled={!isValid}
                  type='submit'
                  mb='0'
                >
                  Continue to Payment
                </Button>
                {!isValid && (
                  <Text mt='5px' fontSize={'12px'} color={'red'}>
                    All fields are mandatory !
                  </Text>
                )}
              </Box>
            </Flex>
          </Flex>
        )}
      </Formik>
      {/* <form onSubmit={formik.handleSubmit}>
        <Checkbox
          name='billingAddress'
          isChecked={formik.values.billingAddress}
          onChange={formik.handleChange}
          mb={4}
        >
          Same as Billing Address
        </Checkbox>

       
      </form> */}

      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmitModal}
        formik={''}
        fromOrder
      />
    </Box>
  );
};

export default ShippingForm;
