import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Image,
    Text,
    VStack,
    useDisclosure,
    Flex
} from '@chakra-ui/react';
import Img1 from "../../assets/img/instructionImg1.png"
import Img2 from "../../assets/img/instructionImg2.png"

const InstructionsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button onClick={onOpen} borderRadius='20px'>
                Instructions
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>How is the proof to be provided</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align="start" spacing={4}>
                            <Text fontSize="md">
                                Please follow the steps below to upload content for your return product:
                            </Text>
                            <Text fontSize="sm">
                                1. If you received the item with a size label, please provide a picture which shows the difference with the size chart in description.
                            </Text>
                            <Text fontSize="sm">
                                2. If you have a size stick, use it to measure the actual size and please show the result in photo.
                            </Text>
                            <Flex direction="column" align="center" gap={4} w='100%'>
                                <Image
                                    src={Img1}
                                    alt="Upload nstruction"
                                    borderRadius="md"
                                />
                                <Image
                                    src={Img2}
                                    alt="Upload instruction"
                                    borderRadius="md"
                                />
                            </Flex>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} borderRadius='20px'>
                            Got it
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default InstructionsModal;
