import { Box, useOutsideClick } from '@chakra-ui/react';
import React, { useRef } from 'react';
import styles from './dropdown.module.scss';

const Dropdown = ({ isOpen, onClose, children }) => {
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });
  return (
    isOpen && (
      <Box pos={'relative'} ref={ref}>
        <Box className={styles.dropdown_box}>{children}</Box>
      </Box>
    )
  );
};

export default Dropdown;
