import { configureStore } from '@reduxjs/toolkit';
import homeSlice from './homeSlice';
import authSlice from './authSlice';
import userSlice from './userSlice';
import productSlice from './productSlice';
import guestSlice from './guestSlice';
import cartSlice from './cartSlice';
import wishlistSlice from './wishlistSlice';
import orderSlice from './orderSlice';
import paymentSlice from './paymentSlice';

const store = configureStore({
  reducer: {
    home: homeSlice,
    auth: authSlice,
    user: userSlice,
    product: productSlice,
    cart: cartSlice,
    guest: guestSlice,
    wishlist: wishlistSlice,
    order: orderSlice,
    payment: paymentSlice,
  },
});
export default store;
