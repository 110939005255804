import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { accountVerification } from '../../services/auth-service';
import {useNavigate} from 'react-router-dom'
import { Image, Box, Text, Flex, Spinner, Button, useToast} from '@chakra-ui/react';
import Verified from '../../../src/assets/img/verified.png';
import Failed from '../../../src/assets/img/failed.png';
import PageNotFound from '../page-not-found';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import isEmpty from 'is-empty';

let count = 1;

const AccountVerification = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const verifyAccount = async () => {
    count++;
    setIsLoading(true);
    try {
      const response = await accountVerification(token);
      if (response.status === 200) {
        toast({
          title: "Account Verified Successfully, Please Login!",
          status: "success",
          duration: 10000,
          isClosable: true,
        });
        navigate('/')
        setIsVerified(true);
      } else {
        const message = response?.response?.data?.message || "Verification failed";
        toast({
          title: message,
          status: "error",
          duration: 10000,
          isClosable: true,
        });
        navigate('/')
        setIsVerified(true);
      }
    } catch (error) {
      setIsVerified(true);
      let backendMessage;

      if (error.response) {
        backendMessage = error.response.response.data?.message || "An unexpected error occurred";
      } else {
        backendMessage = error.message || "Network error occurred";
      }
      toast({
        title: backendMessage,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
    navigate('/')
  };
  useEffectOnce(() => {
    if (count === 1) {
      verifyAccount();
    }
  },[]);

  return (
    <Box textAlign="center" mt="50px">
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    {/* {isLoading &&
     (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    )
     : isVerified ? (
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        padding="50px"
      >
        <Image src={Verified} alt="Verified" mx="auto" width="10%" />
        <Text fontSize="xl" mt="20px">
          User Verified Successfully! Please Login
        </Text>
      </Flex>
    ) 
    : (
      <PageNotFound />
    )
    } */}
  </Box>
  );
};

export default AccountVerification;
