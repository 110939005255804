import { Checkbox, Text, Flex } from '@chakra-ui/react';
import { Field, useField } from 'formik';

const FormCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <Field as={Checkbox} size='md' spacing={'10px'} {...field} {...props}>
        <Text
          textStyle={'body-rg'}
          letterSpacing={'0.32px'}
          lineHeight={'18px'}
          fontWeight={400}
        >
          {label}
        </Text>
      </Field>
      {meta.touched && meta.error ? (
        <Text textStyle={'body-rg'} color={'error.100'} fontWeight={400}>
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormCheckbox;
