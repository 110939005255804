import { createSlice } from '@reduxjs/toolkit';
import {
  addAddress,
  changePassword,
  checkEmail,
  deleteAddress,
  fetchAddress,
  fetchNotifications,
  profile,
  profileUpdate,
  readNotifications,
  subscribeToNewsLatter,
  updateAddress,
} from '../services/user-service';

const initialState = {
  isLoading: false,
  userDetails: null,
  isLogin: true,
  userData: null,
  addressList: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setAddressList(state, action) {
      state.addressList = action.payload;
    },
    setNotificationsList(state, action) {
      state.notificationsList = action.payload;
    },
  },
});

export const { setIsLoading, setUserDetails, setUserData, setAddressList, setNotificationsList } =
  userSlice.actions;

export default userSlice.reducer;

export const fetchProfile = () => async (dispatch) => {
  try {
    const response = await profile();

    if (response.status === 200) {
      dispatch(setUserData(response.data.data));
    }
  } catch (error) {
    return error;
  }
};

export const profileUpdateHandler = (payload) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('user_id', payload.user_id);
    formData.append('name', payload.name);
    formData.append('last_name', payload.last_name);
    formData.append('email', payload.email);
    formData.append('phone', payload.phone);
    formData.append('image', payload.image);
    return await profileUpdate(formData);
  } catch (error) {
    return error;
  }
};
export const changePasswordHandler = (payload) => async (dispatch) => {
  try {
    return await changePassword(payload);
  } catch (error) {
    return error;
  }
};
export const addAddressHandler = (payload) => async (dispatch) => {
  try {
    return await addAddress(payload);
  } catch (error) {
    return error;
  }
};
export const updateAddressHandler = (payload) => async (dispatch) => {
  try {
    return await updateAddress(payload);
  } catch (error) {
    return error;
  }
};
export const fetchAddressHandler = (userId) => async (dispatch, getState) => {
  try {
    const response = await fetchAddress(userId);
    if (response.status === 200) {
      dispatch(setAddressList(response.data.data));
    }
  } catch (error) {
    return error;
  }
};
export const deleteAddressHandler =
  (userId, addressId) => async (dispatch, getState) => {
    try {
      return await deleteAddress(userId, addressId);
    } catch (error) {
      return error;
    }
  };
export const subscribeToNewsLatterHandler = (payload) => async (dispatch) => {
  try {
    return await subscribeToNewsLatter(payload);
  } catch (error) {
    return error;
  }
};
export const checkEmailHandler = (payload) => async (dispatch) => {
  try {
    return await checkEmail(payload);
  } catch (error) {
    return error;
  }
};

export const fetchNotificationsHandler = (userId) => async (dispatch, getState) => {
  console.log(userId, "iddd")
  try {
    const response = await fetchNotifications(userId);
    if (response.status === 200) {
      dispatch(setNotificationsList(response.data.data));
    }
  } catch (error) {
    return error;
  }
};

export const readNotificationsHandler = (userId) => async (dispatch, getState) => {
  try {
    const response = await readNotifications(userId);
  } catch (error) {
    return error;
  }
};