import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Avatar,
  Badge,
  Image,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Profile from '../../../assets/img/product/profileImage.jpg';
import { useSelector } from 'react-redux';

const ReviewCard = () => {
  const {
    productDetails: { productReviews },
  } = useSelector((state) => state.product);

  const [showMoreReviews, setShowMoreReviews] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [currentReviewImages, setCurrentReviewImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSmallerThan767] = useMediaQuery('(max-width: 991px)');

  const displayedReviews = showMoreReviews
    ? productReviews
    : productReviews?.slice(0, 6);

  const handleImageClick = (images, index) => {
    setCurrentReviewImages(images);
    setSelectedImageIndex(index);
  };

  const handleNextImage = () => {
    if (
      selectedImageIndex !== null &&
      selectedImageIndex < currentReviewImages.length - 1
    ) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleClosePreview = () => {
    setSelectedImageIndex(null);
  };

  const toggleReviews = () => {
    setIsLoading(true);
    setTimeout(() => {
      setShowMoreReviews(!showMoreReviews);
      setIsLoading(false);
    }, 1000);
  };

  return (
    <Box
      className='review-card'
      style={{
        borderRadius: '8px',
        // maxWidth: '100%',
        marginBottom: '20px',
      }}
    >
      {productReviews && productReviews.length > 0 && (
        <>
          <Grid
            templateColumns={`repeat(${isSmallerThan767 ? 2 : 2}, 1fr)`}
            gap={isSmallerThan767 ? 1 : 3}
            mt={4}
          >
            {displayedReviews?.map((review, index) => (
              <GridItem
                key={index}
                border='#E0E0E0 2px solid'
                borderRadius='10px'
                padding='10px'
              >
                <Flex
                  justify='space-between'
                  flexDir={isSmallerThan767 ? 'column' : 'row'}
                >
                  <Flex>
                    <Avatar
                      height={'28px'}
                      width={'28px'}
                      src={review.image || Profile}
                      name={review.name}
                    />
                    <Box ml={isSmallerThan767 ? '5px' : '3'}>
                      <Text
                        fontSize={isSmallerThan767 ? '14px' : '16px'}
                        fontWeight='bold'
                      >
                        {review.name}
                        <Box align='left'>
                          <Flex gap={'1.5px'}>
                            {[...Array(review.rating)].map((_, i) => (
                              <StarIcon
                                height={'12px'}
                                width={'12px'}
                                key={i}
                                color={
                                  i < review.rating ? 'yellow.400' : 'gray.300'
                                }
                              />
                            ))}
                          </Flex>

                          <Text
                            fontSize='12px'
                            color='gray.500'
                            fontWeight='normal'
                          >
                            Reviewed on {review?.review_date}
                          </Text>
                        </Box>
                      </Text>
                    </Box>
                  </Flex>
                  <Badge
                    style={{
                      alignSelf: 'flex-start',
                      color: 'white',
                      backgroundColor: '#26BF94',
                      padding: '3px 7px',
                      borderRadius: '6px',
                      fontSize: isSmallerThan767 ? '10px' : '10px',
                      fontWeight: isSmallerThan767 ? '400' : '500',
                    }}
                  >
                    Verified Purchase
                  </Badge>
                </Flex>

                <Box>
                  <Text
                    mt={2}
                    fontSize={isSmallerThan767 ? '12px' : 'sm'}
                    color='gray.700'
                  >
                    {review?.review}
                  </Text>
                </Box>

                {review.images && review.images.length > 0 && (
                  <Flex
                    mt={4}
                    justifyContent='flex-start'
                    alignItems='center'
                    overflowX='auto'
                    pb='10px'
                    flexWrap={'wrap'}
                    rowGap={'5px'}
                  >
                    {review.images.map((img, idx) => (
                      <Image
                        key={idx}
                        src={img}
                        alt={`Review image ${idx + 1}`}
                        borderRadius='10px'
                        boxSize='100px'
                        objectFit='cover'
                        ml='.25rem'
                        cursor='pointer'
                        onClick={() => handleImageClick(review.images, idx)}
                        height={'50px'}
                        width={'50px'}
                      />
                    ))}
                  </Flex>
                )}
              </GridItem>
            ))}
          </Grid>

          {productReviews?.length > 6 && (
            <Text
              cursor='pointer'
              fontWeight={600}
              textAlign='center'
              textDecoration='underline'
              mt={4}
              onClick={toggleReviews}
              colorScheme='blue'
              variant='outline'
            >
              {isLoading ? (
                <Spinner size='md' />
              ) : showMoreReviews ? (
                'Show Less'
              ) : (
                'Show More'
              )}
            </Text>
          )}

          {/* Fullscreen Image Preview */}
          {selectedImageIndex !== null && (
            <Box
              position='fixed'
              top='0'
              left='0'
              width='100vw'
              height='100vh'
              backgroundColor='rgba(0, 0, 0, 0.8)'
              display='flex'
              justifyContent='center'
              alignItems='center'
              zIndex='1000'
              onClick={handleClosePreview}
            >
              {/* Left Arrow */}
              {selectedImageIndex > 0 && (
                <IconButton
                  aria-label='Previous Image'
                  icon={<ArrowBackIcon />}
                  position='absolute'
                  left='20px'
                  top='50%'
                  transform='translateY(-50%)'
                  size='lg'
                  backgroundColor='transparent'
                  color='white'
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePreviousImage();
                  }}
                />
              )}

              {/* Display the Image */}
              <Image
                src={currentReviewImages[selectedImageIndex]}
                alt={`Selected image ${selectedImageIndex + 1}`}
                height='70vh'
                width='60vw'
                objectFit='contain'
                cursor='pointer'
                onClick={(e) => e.stopPropagation()}
              />

              {/* Right Arrow */}
              {selectedImageIndex < currentReviewImages.length - 1 && (
                <IconButton
                  aria-label='Next Image'
                  icon={<ArrowForwardIcon />}
                  position='absolute'
                  right='20px'
                  top='50%'
                  transform='translateY(-50%)'
                  size='lg'
                  backgroundColor='transparent'
                  color='white'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNextImage();
                  }}
                />
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ReviewCard;
