import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  RiCloseFill,
  RiDiscountPercentLine,
  RiEyeFill,
  RiShoppingCart2Line,
  RiStarFill,
} from 'react-icons/ri';
import { calculateDiscountPercentage } from '../../utils/functions';
import styles from './wishlist.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWishListHandler,
  fetchWishList,
  moveToCartFromWishlistHandler,
} from '../../redux/wishlistSlice';
import { addToCartHandler, fetchCartList } from '../../redux/cartSlice';
import { useNavigate } from 'react-router-dom';

const Product = ({ data, onMouseEnter, onMouseLeave }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [loading, setLoading] = useState(false);

  const productName = data?.name
    ? data.name.split(' ').slice(0, 6).join(' ')
    : '';

  return (
    <Box
      // bg='#EDEDED'
      border='#EDEDED 1px solid'
      p='10px'
      className={styles.product_card}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex
        className={styles.close}
        pos={'absolute'}
        right={'15px'}
        top={'15px'}
        onClick={(e) => {
          e.stopPropagation();

          dispatch(
            deleteWishListHandler({
              id: data?.product_id ? data.product_id.toString() : '',
              user_id:
                userData?.id || guestInfo?.id
                  ? (userData?.id || guestInfo?.id).toString()
                  : '',
            })
          ).then((res) => {
            if (res.status === 200) {
              toast({
                title: 'Product removed from wishlist',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
              dispatch(fetchWishList(userData?.id || guestInfo.id));
            } else {
              toast({
                title: 'Something went wrong',
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          });
        }}
      >
        <RiCloseFill size={'30px'} />
      </Flex>

      <Flex
        className={styles.image}
        align={'center'}
        justify={'center'}
        // padding='0.5rem'
      >
        <Image
          src={
            data?.image?.image ||
            require('../../assets/img/product/no-image.png')
          }
          // width='90%'
        />
      </Flex>
      <Box mb='1rem' minH='115px'>
        <Flex justify='space-between' align='center'>
          <Box>
            <Text className={styles.name} noOfLines={2}>
              {productName}
            </Text>
          </Box>
          <Flex align='center' gap='7px' height='100%'>
            <Text
              fontWeight='bold'
              color='#F5B849'
              fontSize='12px'
              lineHeight='1'
            >
              {data?.rating || 0}
            </Text>
            <RiStarFill color='#F5B849' />
          </Flex>
        </Flex>

        <Flex justify={'space-between'}>
          <Flex gap={'5px'} align={'center'}>
            <Text
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={'17px'}
              color={'#000'}
            >
              {data.currency}
              {data.price}
            </Text>
            {data.price !== data.mrp && (
              <Text fontSize={'14px'} opacity={'.8'}>
                <del>
                  {data.currency}
                  {data.mrp}
                </del>
              </Text>
            )}
          </Flex>

          {data.mrp !== data.price && (
            <Badge
              colorScheme='green'
              fontSize='10px'
              textAlign='center'
              display='flex'
              alignItems='center'
              justifyContent='center'
              height='20px'
              lineHeight='1'
            >
              {Math.floor(calculateDiscountPercentage(data.mrp, data.price))}%
              off
            </Badge>
          )}
        </Flex>
        <Flex
          align={'center'}
          fontSize={'11px'}
          fontWeight={600}
          color={'green'}
          gap={'5px'}
          mt={'.5rem'}
        >
          <RiDiscountPercentLine />
          Offer Price {data.price}
        </Flex>
      </Box>
      <hr />
      <Flex
        flexDir={'column'}
        align={'center'}
        justify={'center'}
        gap={'1rem'}
        mt='1rem'
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setLoading(true);
            dispatch(addToCartHandler(data, 1)).then((res) => {
              setLoading(false);
              if (res.status === 200) {
                toast({
                  title: 'Product added to cart',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                });
                dispatch(fetchCartList(userData?.id || guestInfo.id));
              }
            });
          }}
          w={'100%'}
          variant={'primary'}
        >
          <Flex gap={'10px'} alignItems='center'>
            {loading ? (
              <Spinner size='sm' />
            ) : (
              <>
                <RiShoppingCart2Line />
                <Text fontWeight={'bold'}>Move To Cart</Text>
              </>
            )}
          </Flex>
        </Button>
        <Button
          onClick={() => {
            navigate(
              `/product/details/${data.product_unique_id}?sku_id=${data.sku_id}`
            );
          }}
          w={'100%'}
          variant={'secondary'}
        >
          <Flex gap={'10px'} alignItems='center'>
            <RiEyeFill />
            <Text fontWeight={'bold'}>View Product</Text>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
};

export default Product;
