const colors = {
  primary: {
    100: '#f41106',
  },
  background: {
    100: '#212D27',
    200: '#222F28',
  },
  grey: {
    100: '#7A7A7A',
    200: '#D9D9D9',
    300: '#F4F4F4',
    400: '#F8F8F8',
  },
  text: {
    primary: {
      100: '#000000',
    },
    secondary: {
      100: '#7A7A7A',
    },
  },
  success: {
    100: '#008A00',
  },
  pending: {
    100: '#F29339',
  },
  error: {
    100: '#FF3333',
  },
  progress: {
    100: '#B78900',
  },
};

export default colors;
