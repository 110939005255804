import axios from 'axios';

export const getHomeData = async (country, currency, user_id) => {
  try {
    return await axios.get(
      `/home-data?country=${country}&currency=${currency}&user_id=${user_id}`
    );
  } catch (error) {
    return error;
  }
};
export const getAllCategories = async (country, currency) => {
  try {
    return await axios.get(
      `/category-list?country=${country}&currency=${currency}`
    );
  } catch (error) {
    return error;
  }
};
export const getProducts = async (_params, country, currency, user_id) => {
  try {
    // query params
    const params = {
      ..._params,
      country,
      currency,
      // user_id,
    };

    return await axios.get(`/product-list`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const getCountryList = async () => {
  try {
    return await axios.get(`get-country`);
  } catch (error) {
    return error;
  }
};
export const getNowBought = async () => {
  try {
    return await axios.get(`get-now-bought`);
  } catch (error) {
    return error;
  }
};
export const qandASubmit = async (paylaod) => {
  try {
    return await axios.post('submit-qa-question', paylaod);
  } catch (error) {
    return error;
  }
};
export const submitNewSuggestion = async (paylaod) => {
  try {
    return await axios.post('submit-new-suggestion', paylaod, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    return error;
  }
};
export const newsletterSubscribe = async (paylaod) => {
  try {
    return await axios.post('newsletter-subscribe-email', paylaod);
  } catch (error) {
    return error;
  }
};

export const newsletterUnsubscribe = async (paylaod) => {
  try {
    return await axios.post('newsletter-unsubscribe-email', paylaod);
  } catch (error) {
    return error;
  }
};
