import React from 'react';
import {
  Box,
  Text,
  VStack,
  Image,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import SuccessImage from '../../../assets/img/product/pay-successful.png';
import { Link, useNavigate } from 'react-router-dom';
import { MdShoppingCart, MdShoppingCartCheckout } from 'react-icons/md';
import { useSelector } from 'react-redux';
import isEmpty from 'is-empty';

const PaymentStatus = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  return (
    <Box backgroundColor='white'>
      {isEmpty(userData) ? (
        <>
          {' '}
          <VStack
            p={4}
            width={isSmallerThan767 ? '100%' : '80%'}
            mx='auto'
            align='center'
            gap='30px'
          >
            <Box textAlign={'center'}>
              <Text
                fontSize={isSmallerThan767 ? '18px' : '24px'}
                color='#26BF94'
                fontWeight='bold'
                mb='0'
              >
                Order submitted successfully...🤝
              </Text>
              <Text fontSize={'12px'}>
                Please wait for some time. The payment status will be updated
                sortly. You will be notify when its done!
              </Text>
            </Box>
            <Box width={{ base: '100%', md: '25%' }} mx='auto'>
              <Image src={SuccessImage} width='80%' mx='auto' />
            </Box>
            <Box textAlign='center'>
              <Text fontSize={isSmallerThan767 ? '18px' : '20px'}>
                Thank you for joining Kwelibuy family.
              </Text>
              <Text fontSize={isSmallerThan767 ? '13px' : '14px'}>
                We have created a user for you and the details were sent to your
                email.
              </Text>
              <Text fontSize={isSmallerThan767 ? '13px' : '14px'}>
                You can now login and track your order.
              </Text>
              <Text
                fontSize={isSmallerThan767 ? '13px' : '14px'}
                color='gray.500'
              >
                Thank you for shopping with us.
              </Text>
            </Box>
            <Box>
              <Button
                rightIcon={<MdShoppingCart />}
                variant={'secondary'}
                onClick={() => navigate('/')}
              >
                Continue Shopping
              </Button>
            </Box>
          </VStack>
        </>
      ) : (
        <>
          {' '}
          <VStack
            p={4}
            width={isSmallerThan767 ? '100%' : '80%'}
            mx='auto'
            align='center'
            gap='30px'
          >
            <Box textAlign={'center'}>
              <Text
                fontSize={isSmallerThan767 ? '18px' : '24px'}
                color='#26BF94'
                fontWeight='bold'
                mb='0'
              >
                Order submitted successfully...🤝
              </Text>
              <Text fontSize={'12px'}>
                Please wait for some time. The payment status will be updated
                sortly. You will be notify when its done!
              </Text>
            </Box>

            <Box width={{ base: '100%', md: '25%' }} mx='auto'>
              <Image src={SuccessImage} width='80%' mx='auto' />
            </Box>
            <Box textAlign='center'>
              <Text fontSize={isSmallerThan767 ? '13px' : '14px'}>
                You can track your order
                {/* with Order Id{" "}
            <Text as="span" fontWeight="bold" color="black">
              SPK#1FR
            </Text>{" "} */}{' '}
                from{' '}
                <Text
                  as='span'
                  color='#26BF94'
                  textDecoration='underline'
                  _hover={{
                    color: '#26BF94',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/my-orders');
                  }}
                >
                  Track Order
                </Text>
              </Text>
              <Text
                fontSize={isSmallerThan767 ? '13px' : '14px'}
                color='gray.500'
              >
                Thank you for shopping with us.
              </Text>
            </Box>
            <Box>
              <Button
                rightIcon={<MdShoppingCart />}
                variant={'secondary'}
                onClick={() => navigate('/')}
              >
                Continue Shopping
              </Button>
            </Box>
          </VStack>
        </>
      )}
    </Box>
  );
};

export default PaymentStatus;
