import { ChakraProvider, Container, Flex, Spinner } from '@chakra-ui/react';
import RouteComponent from './routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import theme from './theme';
import { Suspense, useEffect, useState } from 'react';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Suspense
          fallback={
            <Flex justify={'center'} align={'center'} p={'100px'}>
              <Spinner />
            </Flex>
          }
        >
          <LoginPrompt>
            <RouteComponent />
          </LoginPrompt>
        </Suspense>
      </Provider>
    </ChakraProvider>
  );
}

export default App;

const LoginPrompt = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');

    const fullUrl = window.location.href;

    if (authStatus === 'true') {
      setIsAuthenticated(true);
    } else if (
      fullUrl === 'https://kwelibuy.com/' ||
      fullUrl === 'https://kwelibuy.com' ||
      fullUrl.includes('.com')
    ) {
      localStorage.setItem('isAuthenticated', 'true'); // Save auth status
      setIsAuthenticated(true);
      window.location.href = '/';
    } else {
      const username = prompt('Enter username:');
      const password = prompt('Enter password:');

      // Your desired credentials
      const validUsername = 'admin'; // Set your username
      const validPassword = 'password123'; // Set your password

      if (username === validUsername && password === validPassword) {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true'); // Save auth status
        window.location.reload();
      } else {
        alert('Invalid credentials!');
        window.location.reload(); // Reload to prompt again
      }
    }
  }, []);

  return isAuthenticated ? children : <div>Loading...</div>;
};
