// src/features/guest/guestSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  guestInfo: {
    id: '',
    name: '',
    email: '',
    address: '',
    // add other fields as needed
  },
  isGuest: false,
};

const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestInfo: (state, action) => {
      state.guestInfo = { ...state.guestInfo, ...action.payload };
    },
    setIsGuest: (state, action) => {
      state.isGuest = action.payload;
    },
    clearGuestInfo: (state) => {
      state.guestInfo = initialState.guestInfo;
      state.isGuest = false;
    },
  },
});

export const { setGuestInfo, setIsGuest, clearGuestInfo } = guestSlice.actions;

export default guestSlice.reducer;
