import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  HStack,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { FaPhoneAlt, FaCircle } from "react-icons/fa";
import { GoCircle } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";
import BgImage from "../../assets/img/about.jpg"; // import your background image
import FormInput from "../../components/form-input"; // adjust the import path as needed
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, NavLink } from "react-router-dom";

const ContactSection = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNo: "",
    emailId: "",
    address: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNo: Yup.string()
      .matches(/^\d{10}$/, "Must be exactly 10 digits")
      .required("Required"),
    emailId: Yup.string().email("Invalid email address").required("Required"),
    address: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {};

  return (
    <Box
      width="100%"
      color="white"
      py={10}
      px={{ base: 4, md: 8 }}
      textAlign="center"
    >
      <Box
        position="relative"
        width="100%"
        height={{ base: "auto", md: "500px" }}
        bgImage={`url(${BgImage})`}
        bgSize="cover"
        bgPosition="center"
        borderRadius="10px"
        p={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(159, 122, 234, 0.8)"
          borderRadius="10px"
        />
        <Box position="relative" zIndex="1">
          <Text fontSize="2xl" fontWeight="bold">
            Contact US !
          </Text>
          <Text mb={6}>
            Have any questions? We would love to hear from you 24X7.
          </Text>
          <Button colorScheme="teal" rightIcon={<FaPhoneAlt />}>
            Contact
          </Button>
        </Box>
      </Box>
      {/* <Flex
        direction={{ base: "column", md: "row" }}
        mt={{ base: 4, md: -10 }}
        px={{ base: 4, md: 8 }}
        zIndex="1"
        position="relative"
        justifyContent="space-between"
        gap={4}
      >
        <Box
          bg="white"
          p={6}
          borderRadius="10px"
          boxShadow="xl"
          width={{ base: "100%", md: "45%" }}
        >
          <Flex>
            <Box
              width="3px"
              height="25px"
              bgGradient="linear(to-b, #B5B8F0, #A5C7F1)"
              mr={2}
            />
            <Text
              fontSize="lg"
              align="left"
              fontWeight="bold"
              mb={4}
              color="black"
            >
              Get In Touch !
            </Text>
          </Flex>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Box backgroundColor="white" p={4}>
                  <Divider mb="5" />
                  <VStack spacing={4}>
                    <HStack
                      color="black"
                      width="100%"
                      spacing={4}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <FormInput
                        label="First Name"
                        name="firstName"
                        placeholder="Enter First Name"
                        width="100%"
                      />
                      <FormInput
                        label="Last Name"
                        name="lastName"
                        placeholder="Enter Last Name"
                        width="100%"
                      />
                    </HStack>
                    <HStack
                      width="100%"
                      spacing={4}
                      flexDirection={{ base: "column", md: "row" }}
                      color="black"
                    >
                      <FormInput
                        label="Phone No"
                        name="phoneNo"
                        placeholder="Enter Phone No"
                        width="100%"
                      />
                      <FormInput
                        label="Email Id"
                        name="emailId"
                        placeholder="Enter Email Id"
                        width="100%"
                      />
                    </HStack>
                    <HStack
                      color="black"
                      width="100%"
                      spacing={4}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <FormInput
                        label="Address"
                        name="address"
                        placeholder="Enter Address"
                        // width="100%"
                        as="Textarea"
                        width={{ base: "254px", md: "100%" }}
                        // mr={{base:"5px", md:"0px"}}
                      />
                      <FormInput
                        label="Message"
                        name="message"
                        placeholder="Enter Message"
                        // width="100%"
                        as="Textarea"
                        width={{ base: "254px", md: "100%" }}
                        // mr={{base:"5px", md:"0px"}}
                      />
                    </HStack>
                    <Divider mt="4" mb="4" />
                    <Button type="submit" colorScheme="teal" align="center">
                      Send Message
                    </Button>
                  </VStack>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
        <Box
          bg="white"
          p={5}
          borderRadius="10px"
          boxShadow="xl"
          width={{ base: "100%", md: "45%" }}
          height={{ base: "500px", md: "inherit" }}
          position="relative"
        >
          <iframe
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.940892058045!2d78.4866715744418!3d17.385044203898112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9784c85c3f85%3A0x3f2877a6e0e78db6!2sSPRIKUBO%20TECHNOLOGIES%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1607411173626!5m2!1sen!2sin&zoomControl=true&mapTypeControl=true&mapTypeId=satellite"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="flex-start"
            width="50%"
            position="absolute"
            top="30px"
            left="30px"
            bg="white"
            fontSize="13px"
          >
            <Box
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p="4"
              display="flex"
              alignItems="center"
              fontSize="sm"
            >
              <Flex direction="column" align="center" mb="5">
                <Icon as={GoCircle} boxSize={4} color="gray" />
                <Box h="20px" borderLeft="1px dashed gray" />
                <Icon as={MdLocationOn} boxSize={6} color="red.500" />
              </Flex>
              <Flex
                ml="2"
                direction="column"
                alignItems="center"
                gap="5px"
                fontSize="15px"
              >
                <Text align="left" noOfLines={1} color="black">
                  Surya, A/25, Sainikpuri, Secu...
                </Text>
                <Divider />
                <Text align="left" noOfLines={1} color="black">
                  SPRUKO® TECHNOLOGIES PRIVAT...
                </Text>
                <NavLink
                  target="_blank"
                  to="https://www.google.com/maps/dir/Surya,+A%2F25,+Sainikpuri,+Secunderabad,+Telangana+500094/SPRUKO%C2%AE+TECHNOLOGIES+PRIVATE+LIMITED,+LIG+215+H+NO.1+8-67+83,+near+Tulasi+Hospitals+Ecil,+APIIC+Colony,+North+Kamala+Nagar,+Kushaiguda,+Hyderabad,+Secunderabad,+Telangana+500062/@17.481802,78.559288,14z/data=!4m14!4m13!1m5!1m1!1s0x3bcb9b0058af135d:0x1cab5cd3438524a!2m2!1d78.5493783!2d17.4886325!1m5!1m1!1s0x3bcb9c7ec139a15d:0x326d1c90786b2ab6!2m2!1d78.5702614!2d17.4748278!3e0?hl=en&entry=ttu"
                  isExternal
                  style={{ color: "blue" }}
                >
                  More options
                </NavLink>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex> */}
    </Box>
  );
};

export default ContactSection;
