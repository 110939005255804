import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiBellOn } from "react-icons/ci";
import { Box, Text, Menu, MenuButton, MenuList, MenuItem, Button, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import NotificationCard from "../../pages/notifications/notification-card";
import { readNotificationsHandler } from "../../redux/userSlice";
import { useEffect } from "react";
import { setOnLoginOpen } from "../../redux/homeSlice";

let tt;

const NotificationIcon = ({ count = 3 }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const notificationCount = useSelector((state) => state.home?.homeData?.unread_notification_count)
  const { notificationsList } = useSelector((state) => state?.user);

 
  const handleIconClick = () => {
    if (!userData?.id) {
      dispatch(setOnLoginOpen(true));
    } else if (isMobile) {
      navigate("/notifications");
    }
  };

  // useEffect(() => {
  //   dispatch(readNotificationsHandler( userData?.id ));
  // }, []);

  return (
    <Menu isLazy>
      <MenuButton
        onClick={handleIconClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box position="relative" display="inline-block" >
          <CiBellOn size={24} color={isHovered ? 'red' : '#808080'} />
          {notificationCount > 0 && (
            <Box
              position="absolute"
              top="-5px"
              right="-5px"
              bg="red.500"
              borderRadius="full"
              width="18px"
              height="18px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="xs" color="white" fontWeight="bold">
                {notificationCount}
              </Text>
            </Box>
          )}
        </Box>
      </MenuButton>
      { userData?.id && !isMobile && (
        <MenuList>
          {notificationsList?.length > 0 ? (
            notificationsList?.slice(0, 5).map((notification, index) => (
              <MenuItem key={notification.id}>
                <NotificationCard notification={notification} />
              </MenuItem>
            ))
          ) : (
            <MenuItem>No new notifications</MenuItem>
          )}
          <Box p={2}>
            <Button
              width="100%"
              colorScheme="blue"
              onClick={() => navigate("/notifications")}
            >
              View All
            </Button>
          </Box>
        </MenuList>
      )}
    </Menu>
  );
};

export default NotificationIcon;
