import { Image } from '@chakra-ui/react';
import React from 'react';
import ProfileIcon from "../../../assets/img/icons/profileIcon.svg"


const Icon = () => {
  return <Image src={ProfileIcon} />;
};

export default Icon;

