import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { LuPrinter } from 'react-icons/lu';
import { useSelector } from 'react-redux';

const OrderDetailsMobile = () => {
  const { singleOrderDetails, isLoading, orderTrackingData } = useSelector(
    (state) => state.order
  );
  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <Flex pb='10px' borderBottom={'1px solid #ddd'} gap={'20px'}>
        <Flex justify={'flex-end'} w={'30%'}>
          {isLoading ? (
            <Skeleton height='85px' width='85px' />
          ) : (
            <Image
              src={singleOrderDetails?.product_image}
              alt={singleOrderDetails?.product_name}
              boxSize='85px'
              bg='#F3F6F8'
              borderRadius='10px'
            />
          )}
        </Flex>
        <Box w={'70%'} textAlign={'left'}>
          {isLoading ? (
            <SkeletonText noOfLines={2} />
          ) : (
            <Text
              fontSize={'13px'}
              title={singleOrderDetails?.product_name}
              fontWeight='bold'
            >
              {singleOrderDetails?.product_name}
            </Text>
          )}

          {singleOrderDetails?.attributes?.map((item) => (
            <Text fontSize={'12px'}>
              {item.name}:{' '}
              <Text as='span' color='gray.500'>
                {item?.value}
              </Text>
            </Text>
          ))}

          {singleOrderDetails?.discount > 0 && (
            <Badge bg='#26BF94' color='white' borderRadius='6px'>
              In Offer
            </Badge>
          )}
          {singleOrderDetails?.discount > 0 && (
            <Badge bg='#49B6F5' color='white' borderRadius='6px'>
              {singleOrderDetails.discount}
            </Badge>
          )}
        </Box>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text fontSize={'14px'} textAlign={'right'}>
            Price
          </Text>
        </Box>
        <Flex w={'70%'} fontSize={'14px'} gap={'5px'}>
          <Text fontWeight={600}>{singleOrderDetails?.currency}</Text>
          <Text fontWeight={600}>{singleOrderDetails?.price}</Text>
        </Flex>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text textAlign={'right'} fontSize={'14px'}>
            Quantity
          </Text>
        </Box>
        <Box w={'70%'}>
          <Text textAlign={'left'} fontWeight={600}>
            {singleOrderDetails?.quantity}
          </Text>
        </Box>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text textAlign={'right'} fontSize={'14px'}>
            Total Price
          </Text>
        </Box>
        <Box w={'70%'}>
          <Flex fontSize={'14px'} gap={'5px'}>
            <Text fontWeight={600}>{singleOrderDetails?.currency}</Text>
            <Text fontWeight={600}>{singleOrderDetails?.price}</Text>
          </Flex>
        </Box>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text textAlign={'right'} fontSize={'14px'}>
            Sub Total
          </Text>
        </Box>
        <Box w={'70%'}>
          <Flex fontSize={'14px'} gap={'5px'}>
            <Text fontWeight={600}>{singleOrderDetails?.currency}</Text>
            <Text fontWeight={600}>{singleOrderDetails?.sub_total_amount}</Text>
          </Flex>
        </Box>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text textAlign={'right'} fontSize={'14px'}>
            Delivery Fees
          </Text>
        </Box>
        <Box w={'70%'}>
          {isLoading ? (
            <Skeleton height={'20px'} width={'100px'} />
          ) : (
            <Text textAlign='left' fontWeight='600'>
              {singleOrderDetails?.shipment_amount > 0
                ? singleOrderDetails?.shipment_amount
                : 'Free Shipping'}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex gap={'20px'}>
        <Box w={'30%'}>
          <Text textAlign={'right'} fontSize={'14px'}>
            Total Price
          </Text>
        </Box>
        <Box w={'70%'}>
          <Flex fontSize={'14px'} gap={'5px'}>
            <Text fontWeight={600}>{singleOrderDetails?.currency}</Text>
            <Text fontWeight={600}>{singleOrderDetails?.total_amount}</Text>
          </Flex>
        </Box>
      </Flex>
      {singleOrderDetails?.status !== 'cancelled' && (
        <Stack direction='row' spacing={4} mt={4} p={4} justifyContent='right'>
          {isLoading ? (
            <>
              <Skeleton height={'40px'} width={'100px'} />
              <Skeleton height={'40px'} width={'100px'} />
            </>
          ) : (
            <>
              {' '}
              <Button
                onClick={async () => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/get-order-invoice?order_id=${singleOrderDetails?.id}`,
                    '_blank'
                  );
                }}
                colorScheme='purple'
                leftIcon={<LuPrinter />}
              >
                Download
              </Button>
              {/* <Button bg='#23B7E5' leftIcon={<RiShareForwardLine />}>
                    Share Details
                  </Button> */}
            </>
          )}
        </Stack>
      )}
    </Flex>
  );
};

export default OrderDetailsMobile;
