import React from 'react';
import { Flex, Box, Icon } from '@chakra-ui/react';
import { IoStar } from 'react-icons/io5';

const RatingStars = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const fractionalPart = rating - fullStars;

  return (
    <Flex align="center" marginBottom="4px">
      {[...Array(5)].map((_, index) => {
        if (index < fullStars) {
          // Full star
          return <Icon key={index} as={IoStar} color="yellow.400" boxSize={4} />;
        }
        if (index === fullStars && fractionalPart > 0) {
          // Partial star
          return (
            <Box position="relative" key={index} display="inline-flex">
              <Icon as={IoStar} color="gray.400" boxSize={4} />
              <Box
                position="absolute"
                top="0"
                left="0"
                width={`${fractionalPart * 100}%`}
                height="100%"
                overflow="hidden"
                zIndex="1"
                display="inline-flex"
              >
                <Icon as={IoStar} color="yellow.400" boxSize={4} />
              </Box>
            </Box>
          );
        }
        // Empty star
        return <Icon key={index} as={IoStar} color="gray.400" boxSize={4} />;
      })}
    </Flex>
  );
};

export default RatingStars;
