import axios from 'axios';

export const profile = async () => {
  try {
    return await axios.get('/profile');
  } catch (error) {
    return error;
  }
};
export const profileUpdate = async (payload) => {
  try {
    return await axios.post('/profile', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    return error;
  }
};
export const changePassword = async (payload) => {
  try {
    return await axios.post('/change-password', payload);
  } catch (error) {
    return error;
  }
};
export const addAddress = async (payload) => {
  try {
    return await axios.post('/add-address', payload);
  } catch (error) {
    return error;
  }
};
export const updateAddress = async (payload) => {
  try {
    return await axios.post('/update-address', payload);
  } catch (error) {
    return error;
  }
};
export const fetchAddress = async (userId) => {
  try {
    return await axios.get(`address-list?user_id=${userId}`);
  } catch (error) {
    return error;
  }
};
export const deleteAddress = async (userId, addressId) => {
  try {
    return await axios.get(
      `/delete-address?user_id=${userId}&address_id=${addressId}`
    );
  } catch (error) {
    return error;
  }
};
export const subscribeToNewsLatter = async (payload) => {
  try {
    return await axios.post('/newsletter-subscribe-email', payload);
  } catch (error) {
    return error;
  }
};
export const checkEmail = async (payload) => {
  try {
    return await axios.post('check-email', payload);
  } catch (error) {
    return error;
  }
};

export const fetchNotifications = async (userId) => {
  try {
    return await axios.get(`get-notification?user_id=${userId}`);
  } catch (error) {
    return error;
  }
};

export const readNotifications = async (userId) => {
  try {
    return await axios.get(`read-notification?user_id=${userId}`);
  } catch (error) {
    return error;
  }
};
