import axios from 'axios';

export const createOrder = async (paylaod) => {
  try {
    return await axios.post(`create-order`, paylaod);
  } catch (error) {
    return error;
  }
};
export const getOrders = async (userId) => {
  try {
    return await axios.get(`get-order?user_id=${userId}`);
  } catch (error) {
    return error;
  }
};
export const getOrderDetails = async (userId, orderId) => {
  try {
    return await axios.get(
      `get-order-detail?user_id=${userId}&order_id=${orderId}`
    );
  } catch (error) {
    return error;
  }
};
export const orderTracking = async (orderId) => {
  try {
    return await axios.get(`get-order-tracking?order_id=${orderId}`);
  } catch (error) {
    return error;
  }
};
export const cancelOrder = async (orderId) => {
  try {
    return await axios.get(`/cancel-order?order_id=${orderId}`);
  } catch (error) {
    return error;
  }
};
export const refundCategory = async () => {
  try {
    return await axios.get(`/get-refund-category`);
  } catch (error) {
    return error;
  }
};

// export const refundorder = async (payload) => {
//   try {
//     return await axios.post(`/submit-refund-request`, payload);
//   } catch (error) {
//     return error;
//   }
// };
export const refundorder = async (payload) => {
  try {
    return await axios.post(`/submit-refund-request`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    return error;
  }
};

export const getCheckoutData = async (
  userId,
  currency,
  country,
  buyNow,
  appliedCoupon
) => {
  try {
    return await axios.get(
      `get-checkout?user_id=${userId}&currency=${currency}&country=${country}&is_buy_now=${
        buyNow ? 1 : 0
      }&coupon_code=${appliedCoupon}`
    );
  } catch (error) {
    return error;
  }
};
export const downloadInvoice = async (orderId) => {
  try {
    return await axios.get(`get-order-invoice?order_id=${orderId}`);
  } catch (error) {
    return error;
  }
};

export const confirmReceived = async (orderId) => {
  try {
    return await axios.get(`complete-order?order_id=${orderId}`);
  } catch (error) {
    return error;
  }
};
