import { Box, Divider, Flex, Icon, Text } from '@chakra-ui/react';
import moment from 'moment/moment';
import {
  FaCheckCircle,
  FaBox,
  FaTruck,
  FaShippingFast,
  FaCircle,
} from 'react-icons/fa';
import { GiShoppingCart } from 'react-icons/gi';
import { RiBox3Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { isBeforeDate, isPastDate } from '../../../utils/functions';
import isEmpty from 'is-empty';

const OrderTracking = () => {
  const { orderTrackingData } = useSelector((state) => state.order);

  return (
    <Box
      bg='white'
      borderRadius='md'
      boxShadow='md'
      width={{ base: '100%', md: '400px' }}
      // mb={{ base: "10px", md: "266px" }}
      mb='25px'
    >
      {isEmpty(orderTrackingData) ? (
        <Flex justify={'center'} p='25px'>
          <Text>No Tracking data found!</Text>
        </Flex>
      ) : (
        <>
          <Flex
            flexDir='row'
            borderBottom='2px solid gray.200'
            justifyContent='space-between'
            alignItems='center'
            p='15px'
          >
            <Flex mt='10px'>
              <Box
                width='4px'
                height='24px'
                bgGradient='linear(to-b, #B5B8F0, #A5C7F1)'
                mr={2}
                borderRadius='5px'
              />
              <Text fontSize='18px' fontWeight='bold' mb={2}>
                Tracking No
              </Text>
            </Flex>
            <Text color='#2AC096' fontSize='14px'>
              {orderTrackingData?.mail_no}
            </Text>
          </Flex>
          <Divider mb='4' />
          <Flex direction='column' position='relative' pl='10' mt='20px'>
            {/* Track line */}
            <Box
              position='absolute'
              left='58px'
              top='0'
              bottom='25px'
              zIndex={'-1'}
              width='1px'
              borderLeft='2px dashed gray'
            />

            {orderTrackingData?.detail_node_list?.detail_node?.map((item) => (
              <Flex
                mb='6'
                flexDir='row'
                // opacity={isBeforeDate(item?.time_stamp) ? '0.5' : '1'}
              >
                <Flex
                  height='35px'
                  width='35px'
                  bg='#239FDB'
                  color='white'
                  borderRadius='50px'
                  padding='7px'
                  alignItems='center'
                  justifyContent='center'
                >
                  <RiBox3Line size='20px' bg='blue' />
                </Flex>
                <Box ml='4'>
                  <Text fontWeight='bold' fontSize='sm'>
                    {item?.tracking_name}
                  </Text>
                  <Text fontSize='13px' mb='3' fontWeight='600' color='#2AC096'>
                    {moment(item?.time_stamp).format('MMM DD, YYYY hh:mm A')}
                  </Text>
                  <Text fontSize='13px' color='gray.500'>
                    {item?.tracking_detail_desc}
                  </Text>
                </Box>
              </Flex>
            ))}
            {/* Order Placed */}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default OrderTracking;
