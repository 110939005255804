import { Image } from '@chakra-ui/react';
import React from 'react';
import NotificationIcon from "../../../assets/img/icons/notificationIcon.svg"


const Icon = () => {
  return <Image src={NotificationIcon} />;
};

export default Icon;

