import {
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  RiBankLine,
  RiCheckboxCircleLine,
  RiCheckboxLine,
  RiTruckLine,
  RiUser2Line,
  RiUserLine,
} from 'react-icons/ri';
import ShippingForm from '../checkout/shipping-address';
import PersonalDetail from '../checkout/personal-detail';
import Payment from '../checkout/payment';
import PaymentStatus from '../checkout/payment-status';
import OrderDetails from './order-details';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import {
  fetchCheckoutData,
  fetchOrderDetails,
  setCheckoutData,
} from '../../redux/orderSlice';
import { useQuery } from '../../hooks/useQuery';
import Modal from '../../components/modal';
import { setIsLogin, setOnLoginOpen } from '../../redux/homeSlice';
import LoginPage from '../login';

const CreateOrder = () => {
  const {
    isOpen: isOpenLogin,
    onOpen: onOpenLogin,
    onClose: onCloseLogin,
  } = useDisclosure();

  const dispatch = useDispatch();
  const query = useQuery();
  const [tabIndex, setTabIndex] = useState(0);
  const { orderDetails } = useSelector((state) => state.order);
  const { isLogin, onLoginOpen } = useSelector((state) => state.home);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    dispatch(fetchCheckoutData(!isEmpty(query.get('buy-now'))));
  }, [dispatch, query]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token && !query.get('buy-now')) {
      onOpenLogin();
    }
  }, []);

  useEffect(() => {
    const orderId = query.get('order_id');
    if (orderId) {
      dispatch(fetchOrderDetails(orderId)).then((res) => {
        if (res.status === 200) {
          dispatch(setCheckoutData(res.data.data));
        }
      });
    }
  }, [dispatch, query]);

  return (
    <>
      <Flex
        px={isSmallerThan767 ? '10px' : '0'}
        py={'20px'}
        justify={'space-between'}
        align={'center'}
        pb={isSmallerThan767 ? '15px' : '20px'}
      >
        <Text fontSize={'18px'} fontWeight={600}>
          Checkout
        </Text>
      </Flex>
      <Tabs
        mt={isSmallerThan767 ? '0' : '30px'}
        index={tabIndex}
        onChange={(index) => {
          if (
            isEmpty(orderDetails.shipping) ||
            isEmpty(orderDetails.paymentDone)
          )
            return;
          setTabIndex(index);
        }}
      >
        <TabList justifyContent={'space-around'} alignSelf='center'>
          <Tab>
            <RiTruckLine /> <Text ml='10px'>Shipping</Text>
          </Tab>
          {/* <Tab>
            <RiUserLine /> <Text ml='10px'>Personal Details</Text>
          </Tab> */}
          <Tab>
            {' '}
            <RiBankLine /> <Text ml='10px'>Payment</Text>
          </Tab>
          <Tab>
            <RiCheckboxCircleLine /> <Text ml='10px'>Confirmation</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={isSmallerThan767 && '10px'}>
            <Flex
              mb={isSmallerThan767 ? '45px' : '0'}
              flexDir={isSmallerThan767 ? 'column' : 'row'}
              gap={'1.25rem'}
            >
              <ShippingForm setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          {/* <TabPanel>
            <Flex gap={'1.25rem'}>
              <PersonalDetail setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel> */}
          <TabPanel px={isSmallerThan767 && '10px'}>
            <Flex
              mb={isSmallerThan767 ? '45px' : '0'}
              gap={'1.25rem'}
              flexDir={isSmallerThan767 ? 'column' : 'row'}
            >
              <Payment setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          <TabPanel px={isSmallerThan767 && '10px'}>
            <PaymentStatus />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal
        width={isSmallerThan767 ? 'calc(100% - 25px)' : '600px'}
        isOpen={isOpenLogin}
        onClose={() => {
          onCloseLogin();
        }}
        isMobileMargin={isSmallerThan767 ? '0 20px' : 'auto'}
        noClose={true}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <LoginPage noSignup callBack onClose={onCloseLogin} />
      </Modal>
    </>
  );
};

export default CreateOrder;
