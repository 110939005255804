const textStyles = {
  'body-rg': {
    fontSize: '14px'
  },
  'body-sm': {
    fontSize: '12px'
  },
  'body-md': {
    fontSize: '16px'
  },
  'body-lg': {
    fontSize: '18px'
  },
  h1: {
    fontSize: '24px',
    fontWeight: 500
  },
  h2: {
    fontSize: '18px',
    fontWeight: 500
  },
  h3: {
    fontSize: '16px',
    fontWeight: 500
  },
  h4: {
    fontSize: '14px',
    fontWeight: 500
  },
  'title-sm': {
    fontSize: '32px',
    fontWeight: 400
  },
  'title-md': {
    fontSize: '40px',
    fontWeight: 400
  },
  'title-lg': {
    fontSize: '56px',
    fontWeight: 400
  }
};

export default textStyles;
