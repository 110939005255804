import { Checkbox } from '@chakra-ui/react';
import React, { useCallback } from 'react';

const CheckboxComponent = React.memo(
  ({ filters, setSearchParams, queryParams }) => {
    const handleChange = useCallback(
      (e) => {
        e.stopPropagation();
        setSearchParams({
          ...queryParams,
          is_free_shipping: e.target.checked ? 1 : 0,
        });
      },
      [setSearchParams, queryParams]
    );

    return (
      <Checkbox isChecked={filters.isFreeShipping} onChange={handleChange}>
        Free Shipping
      </Checkbox>
    );
  }
);

export default CheckboxComponent;
