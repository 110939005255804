import React, { useState } from 'react';
import './index.scss';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import FormInput from '../../components/form-input';
import FormCheckbox from '../../components/form-checkbox';
import { useDispatch } from 'react-redux';
import { signupHandler } from '../../redux/authSlice';

const Signup = ({ onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  return (
    <div className='signup-container'>
      <div className='signup-box'>
        <h2>Sign Up</h2>
        <p>Welcome and Join us by creating a free account!</p>
        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            password: '',
            confirmPassword: '',
            checkbox: false,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            phone: Yup.string()
              .max(15, 'Mobile number must be 10 digits long')
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            password: Yup.string()
              .required('Required')
              .matches(
                /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/,
                'Password must be 8-32 characters long, include at least one uppercase letter, one number, and one special character.'
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
              .required('Required'),
            checkbox: Yup.bool()
              .isTrue('Accept the acknowledgment to continue')
              .required('Required'),
          })}
          onSubmit={(values) => {
            setLoading(true);
            const payload = {
              name: values.name,
              phone: values.phone,
              email: values.email,
              password: values.password,
              c_password: values.confirmPassword,
            };
            dispatch(signupHandler(payload)).then((res) => {
              setLoading(false);
              if (res.status === 200) {
                toast({
                  title: 'Account Created, Please Verify Your Account from the Link Received on Your Email',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                });
                onClose();
              } else {
                toast({
                  title: res?.response?.data?.message?.email ?? 'Something went wrong',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
          }}
          validateOnMount
        >
          {({ isValid, errors, touched }) => (
            <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
              <Flex flexDir={'column'} gap={'20px'}>
                <FormInput
                  name='name'
                  type='text'
                  label='Full Name'
                  placeholder='Full Name'
                />
                <FormInput
                  name='email'
                  type='text'
                  label='Email Address'
                  placeholder='Email Address'
                />

                <FormInput
                  name='phone'
                  type='number'
                  label='Phone'
                  placeholder='Phone'
                />
                <FormInput
                  name='password'
                  type='password'
                  label='Password'
                  placeholder='Password'
                />
                <FormInput
                  name='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  placeholder='Confirm Password'
                />
              </Flex>

              <FormCheckbox
                type='checkbox'
                name='checkbox'
                value='checkbox'
                label={
                  <>
                    <Text textAlign={'left'}>
                      By creating an account you agree to our{' '}
                      <span>Terms & Conditions</span> and{' '}
                      <span>Privacy Policy</span>
                    </Text>
                  </>
                }
              />

              <Button
                variant={'primary'}
                isDisabled={!isValid || loading}
                size={'sm'}
                type='submit'
                w={'200px'}
                loadingText='Please wait...'
              >
                {loading ? <Spinner size='sm' /> : 'Sign Up'}
              </Button>
            </Flex>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signup;
