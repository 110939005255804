import axios from "axios";

export const getData = async (page_type) => {
    try {
      return await axios.get(`get-pages?type=${page_type}`);
    } catch (error) {
      return error;
    }
  };

export const getQAData = async () => {
    try {
      return await axios.get(`get-qa`);
    } catch (error) {
      return error;
    }
  };
