import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const toast = definePartsStyle((props) => {
  const { status } = props;
  if (status === 'success') {
    return {
      container: {
        bg: '#e7f5ee',
        minWidth: '700px',
        rounded: 'full',
        border: '1px solid #179e55',
      },
      icon: {
        color: '#179e55',
      },
    };
  } else if (status === 'error') {
    return {
      container: {
        bg: '#FFEAEA',
        minWidth: '700px',
        rounded: 'full',
        border: '1px solid #FF3333',
      },
      icon: {
        color: '#FF3333',
      },
    };
  }
});

const Alert = defineMultiStyleConfig({
  variants: { toast },
});

export default Alert;
