import { Image } from '@chakra-ui/react';
import React from 'react';
import HelpIcon from "../../../assets/img/icons/helpIcon.svg"


const Icon = () => {
  return <Image src={HelpIcon} />;
};

export default Icon;

