import {
  Badge,
  Box,
  Flex,
  Image,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  RiDiscountPercentLine,
  RiEyeLine,
  RiHeartFill,
  RiHeartLine,
  RiShoppingCart2Line,
} from 'react-icons/ri';
import {
  calculateDiscountPercentage,
  calculateDiscountPrice,
} from '../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCartHandler,
  fetchCartList,
  setProductDetails,
} from '../../redux/cartSlice';
import {
  addToWishlistHandler,
  deleteWishListHandler,
  fetchWishList,
} from '../../redux/wishlistSlice';
import StarRating from '../star-rating/star';
import cart from '../../assets/img/icons/Cart.png';
import cartSelected from '../../assets/img/icons/Cart_selected.png';
import { setProductId, setProductSkuId } from '../../redux/productSlice';

const ProductCard = ({ item, isBestDeals, onCartOpen, fromListing }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [isHeartHovered, setIsHeartHovered] = useState(false);
  const [isCartHovered, setIsCartHovered] = useState(false);
  const { wishlist } = useSelector((state) => state.wishlist);
  const [isInWishlist, setIsInWishlist] = useState(item.is_wishlist === 1);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const productImage =
    (item?.images && item?.images?.length > 0 && item?.images[0]?.image) ||
    item.image;

  const handleWishlistToggle = (e) => {
    e.stopPropagation();
    const itemId = item?.id ? String(item?.id) : null;
    const userId = userData?.id
      ? String(userData?.id)
      : guestInfo?.id
      ? String(guestInfo?.id)
      : null;

    if (isInWishlist) {
      dispatch(
        deleteWishListHandler({
          id: itemId,
          user_id: userId,
        })
      ).then((res) => {
        if (res.status === 200) {
          setIsInWishlist(false);
          toast({
            title: 'Product removed from wishlist',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          dispatch(fetchWishList(userData?.id || guestInfo.id));
        }
      });
    } else {
      dispatch(addToWishlistHandler(item)).then((res) => {
        if (res.status === 200) {
          setIsInWishlist(true);
          toast({
            title: 'Product added to wishlist',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }
  };

  return (
    <Box
      _hover={{
        border: '1px solid var(--w, #F41106)',
        boxShadow:
          '0px 151px 42px 0px rgba(0, 0, 0, 0.00), 0px 97px 39px 0px rgba(0, 0, 0, 0.01), 0px 54px 33px 0px rgba(0, 0, 0, 0.03), 0px 24px 24px 0px rgba(0, 0, 0, 0.04), 0px 6px 13px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
      }}
      border={'1px solid #EDEDED'}
      borderRadius={'16px'}
      bg={'#fff'}
      className='product-card'
      cursor={'pointer'}
      onClick={() => {
        if (!isSmallerThan767) {
          window.open(
            `/product/details/${item.product_unique_id}?sku_id=${item.sku_id}`,
            '_blank'
          );
        } else {
          navigate(
            `/product/details/${item.product_unique_id}?sku_id=${item.sku_id}`
          );
        }
      }}
      
      minH={'290px'}
    >
      <Flex
        bg='#F5F5F5'
        justify={'center'}
        align={'center'}
        borderTopLeftRadius={'16px'}
        borderTopRightRadius={'16px'}
        position='relative'
      >
        <Flex
          flexDir={'column'}
          top={'0.8rem'}
          gap={'85px'}
          className='product-icons'
          left={'0.6rem'}
          position='absolute'
          display={isSmallerThan767 ? 'none' : 'flex'}
        >
          <Box
            className='icon wishlist'
            height={'40px'}
            width={'40px'}
            onMouseEnter={() => setIsHeartHovered(true)}
            onMouseLeave={() => setIsHeartHovered(false)}
            onClick={handleWishlistToggle}
          >
            {isInWishlist || isHeartHovered ? (
              <RiHeartFill size={'24px'} color='#F41106' />
            ) : (
              <RiHeartLine size={'24px'} color='#F41106' />
            )}
          </Box>
          <Box
            className='icon cart'
            height={'40px'}
            width={'40px'}
            opacity={item.quantity === 0 ? 0.5 : 1}
            pointerEvents={item.quantity === 0 ? 'none' : 'auto'}
            onMouseEnter={() => setIsCartHovered(true)}
            onMouseLeave={() => setIsCartHovered(false)}
            onClick={(e) => {
              e.stopPropagation();
              if (item.price === 0 || item.quantity === 0) {
                return;
              }
              dispatch(setProductId(item.product_unique_id));
              dispatch(setProductSkuId(item.sku_id));
              // dispatch(setProductDetails(item));
              onCartOpen(item);
            }}
          >
            {item.is_cart === 1 || isCartHovered ? (
              <Image src={cartSelected} size={'24px'} color='#F41106' />
            ) : (
              <Image src={cart} size={'24px'} color='#F41106' />
            )}
          </Box>
        </Flex>

        {item.mrp !== item.price && (
          <Flex
            height={isSmallerThan767 ? '43px' : '53px'}
            width={isSmallerThan767 ? '41px' : '51px'}
            bg='#F41106'
            color={'#fff'}
            pos={'absolute'}
            right={0}
            top={0}
            borderRadius={'0px 16px'}
            align={'center'}
            justify={'center'}
            flexDir={'column'}
            fontSize={isSmallerThan767 ? '11px' : '14px'}
            fontWeight={500}
            lineHeight={'20px'}
          >
            {item?.discount}%<Text>OFF</Text>
          </Flex>
        )}

        <Image
          w={'100%'}
          height={'188px'}
          objectFit={'cover'}
          borderTopLeftRadius={'16px'}
          borderTopRightRadius={'16px'}
          src={productImage || require('../../assets/img/product/no-image.png')}
        />
      </Flex>
      <Box padding={'10px'}>
        <Tooltip label={item.name}>
          <Text
            fontSize={isSmallerThan767 ? '11px' : '14px'}
            fontWeight={400}
            lineHeight={'17px'}
            color={'#000'}
            mb='4px'
            className='product-name'
          >
            {item.name}
          </Text>
        </Tooltip>

        <Flex
          gap={'5px'}
          alignItems={'center'}
          fontSize={'.87rem'}
          flexDir={'row'}
          mt='10px'
        >
          <StarRating rating={item?.rating || 0} />

          <Text fontSize={isSmallerThan767 ? '9px' : '13px'} fontWeight={400}>
            {item?.sold} sold
          </Text>
        </Flex>

        <Flex gap={'5px'} flexDir={'row'} mt='10px' align={'center'}>
          <Text
            fontSize={isSmallerThan767 ? '9px' : '13px'}
            fontWeight={600}
            color={'#000'}
          >
            {item.currency}{' '}
            {item.price
              ? Math.round(
                  parseFloat(item.price.replace(/[^0-9.]/g, ''))
                ).toLocaleString('en-US')
              : 'N/A'}
          </Text>
          {item?.mrp !== item.price && (
            <Text fontSize={isSmallerThan767 ? '8.7px' : '13px'} opacity={'.8'}>
              <del>
                {item.currency}{' '}
                {item.mrp
                  ? Math.round(
                      parseFloat(item.mrp.replace(/[^0-9.]/g, ''))
                    ).toLocaleString('en-US')
                  : 'N/A'}
              </del>
            </Text>
          )}
        </Flex>

        {item.shipping === 'Free Shipping' ? (
          <>
            <Box mt='10px' mb='10px'>
              <hr />
            </Box>
            <Text
              color='green'
              fontWeight='600'
              fontSize={isSmallerThan767 ? '11px' : '13px'}
            >
              Free Shipping
            </Text>
          </>
        ) : (
          <>
            {/* {item?.mrp > 0 &&
              item.price > 0 &&
              calculateDiscountPrice(
                item?.mrp?.replace(',', ''),
                item.price?.replace(',', '')
              ) > 0 && (
                <>
                  <Box mt='10px' mb='10px'>
                    <hr />
                  </Box>
                  <Flex
                    align={'center'}
                    fontSize={'13px'}
                    fontWeight={600}
                    color={'green'}
                    gap={'5px'}
                  >
                    Save -{' '}
                    {calculateDiscountPrice(
                      item?.mrp?.replace(',', ''),
                      item.price?.replace(',', '')
                    )}
                  </Flex>
                </>
              )} */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProductCard;
