import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const Input = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    field: {
      bg: '#ffffff !important',
      _readOnly: {
        bg: '#F8F8F8',
      },
      border: '1px solid #e9edf6 !important',
    },
  }),
  variants: {
    readOnly: definePartsStyle({
      field: {
        _readOnly: true,
        bg: '#F8F8F8 !important',
      },
    }),
    pageNumberInput: definePartsStyle({
      field: {
        border: '1px solid #D9D9D9 !important',
        borderRadius: '0px !important',
        height: '35px',
      },
    }),
  },
});
