import axios from 'axios';

export const addToCart = async (paylaod) => {
  try {
    return await axios.post(`add-to-cart`, paylaod);
  } catch (error) {
    return error;
  }
};
export const getCartList = async (userId, country, currency) => {
  try {
    return await axios.get(
      `cart-list?user_id=${userId}&country=${country}&currency=${currency}`
    );
  } catch (error) {
    return error;
  }
};
export const updateCartQuantity = async (paylaod) => {
  try {
    return await axios.post(`update-quantity`, paylaod);
  } catch (error) {
    return error;
  }
};
export const updateCart = async (paylaod) => {
  try {
    return await axios.post(`update-cart`, paylaod);
  } catch (error) {
    return error;
  }
};
export const deleteCart = async (paylaod) => {
  try {
    return await axios.post(`delete-cart`, paylaod);
  } catch (error) {
    return error;
  }
};

export const applyCoupon = async (payload) => {
  try {
    return await axios.post('/apply-voucher', payload);
  } catch (error) {
    console.error('Error applying coupon:', error);
    throw error;
  }
};

