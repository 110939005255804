import React, { useRef, useState } from 'react';
import Modal from '../modal';
import {
  Box,
  Button,
  Flex,
  Image,
  Select,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { RiCamera2Fill, RiCloseCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { submitNewSuggestionHandler } from '../../redux/homeSlice';
import { reportProductHandler } from '../../redux/productSlice';
import { useLocation } from 'react-router-dom';

const AddNewSuggestion = ({ isOpen, onClose }) => {
  const toast = useToast();
  const location = useLocation();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [type, setType] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [screenshot, setScreenshot] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState('');
  const { userData } = useSelector((state) => state.user);
  const { reportCategores } = useSelector((state) => state.product);
  const { productDetails } = useSelector((state) => state.product || '');
  // if (!productDetails || !productDetails?.products) {
  //   return null;
  // }
  const productId = String(productDetails?.products?.id || '');
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const isProductDetailsPage = location.pathname.includes('product/details');

  const resetForm = () => {
    setType('');
    setSuggestion('');
    setScreenshot([]);
    setReason('');
  };
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const filesFormats = [
      '.doc',
      '.docx',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/png',
      'image/gif',
      'image/jpeg',
      'video/mp4',
    ];
  
    const isFileValid = filesFormats.includes(file.type);
    if (!isFileValid) {
      return toast({
        title: 'Invalid file format',
        status: 'error',
      });
    }

    if (file.size > 16 * 1024 * 1024) {
      toast({
        title: `File ${file.name} is larger than 16MB!`,
        status: 'error',
      });
      return;
    }
    setScreenshot([...screenshot, file]);
  };

  const handleReportSubmit = () => {
    if (!userData?.id) {
      toast({
        title: 'User ID is missing.',
        status: 'error',
      });
      return;
    }
    if (!productId) {
      toast({
        title: 'Product ID is missing.',
        status: 'error',
      });
      return;
    }
    const formData = new FormData();
    formData.append('user_id', userData?.id);
    formData.append('product_id', productId);
    formData.append('category', suggestion);
    formData.append('reason', reason);
    formData.append('other_data', '');
  
    screenshot.forEach((file, index) => formData.append(`images[${index}]`, file));
  
    dispatch(reportProductHandler(formData)).then((res) => {
      if (res.status === 200) {
        toast({ title: 'Product reported successfully', status: 'success' });
        resetForm(); // Reset state after successful report
        onClose();
      } else {
        toast({ title: 'Report failed', status: 'error' });
      }
      setIsLoading(false);
    });
  }; 

  const onSubmit = () => {
    setIsLoading(true);
    if (type === 'Report a product') {
      handleReportSubmit();
    } else {
      const formData = new FormData();
      screenshot.forEach((file, index) => formData.append(`images[${index}]`, file));
      formData.append('suggestion', suggestion);
      formData.append('type', type);

      dispatch(submitNewSuggestionHandler(formData)).then((res) => {
        toast({
          title: res.status === 200 ? 'Submitted successfully' : 'Submission failed',
          status: res.status === 200 ? 'success' : 'error',
        });
        setIsLoading(false);
        if (res.status === 200) {
          resetForm(); 
          onClose();
        }
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Add New Suggestion'>
      <Flex flexDir={'column'} gap={'1.25rem'}>
        <Box>
          <Text>Please first let us know what you'd like to do:</Text>
          <Select
            onChange={(e) => {
              if (e.target.value === '-1') {
                setType('');
                return;
              }
              setType(e.target.value);
            }}
          >
            <option value='-1'>Select</option>
            <option value='Report a bug'>Report a bug</option>
            <option value='Request a feature'>Request a feature</option>
            {isProductDetailsPage && <option value='Report a product'>Report a product</option>}
          </Select>
        </Box>
        {type === 'Report a product' ? (
          <>
            <Text>Please tell us about the issue</Text>
            <Select
              onChange={(e) => {
                if (e.target.value === '-1') {
                  setReason('');
                  return;
                }
                setReason(e.target.value);
              }}
            >
              <option value='-1'>Select</option>
              {reportCategores?.map((category) => (
                <option key={category.id} value={category?.name}>
                  {category?.name}
                </option>
              ))}
            </Select>
          </>
        ) : (
          <Textarea
            onChange={(e) => setSuggestion(e.target.value)}
            rows={6}
            placeholder='We take your feedback seriously and even though we may not be able to respond personally, consideration is given to any submission. If you do wish to give us a suggestion or report a problem, please be sure to include any relevant details including order numbers and product links.'
          />
        )}

        <Box>
          <Flex gap={'10px'}>
            {screenshot?.map((image, index) => (
              <>
                <Box
                  pos={'relative'}
                  height={'54px'}
                  width={'54px'}
                  key={index}
                >
                  <Box
                    pos={'absolute'}
                    right={'-5px'}
                    top={'-5px'}
                    cursor={'pointer'}
                    onClick={() => {
                      const temp = screenshot.filter((item, i) => i !== index);
                      setScreenshot(temp);
                    }}
                  >
                    <RiCloseCircleFill />
                  </Box>
                  <Image
                    height={'54px'}
                    width={'54px'}
                    src={URL.createObjectURL(image)}
                  />
                </Box>
              </>
            ))}
            <Flex
              height={'54px'}
              width={'54px'}
              align={'center'}
              justify={'center'}
              cursor={'pointer'}
              onClick={handleClick}
              border={'1px dashed #ddd'}
            >
              <RiCamera2Fill />
            </Flex>
          </Flex>

          <input
            type='file'
            onChange={handleFileChange}
            ref={hiddenFileInput}
            // accept={accept}
            style={{ display: 'none' }} // Make the file input element invisible
          />
          <Text fontSize={'12px'} mt='10px'>
            You can upload a maximum 5 files, individual image size should not
            exceed 1MB and supported file formats are PNG and JPG.{' '}
          </Text>
        </Box>
        <Flex justify={'flex-end'}>
          <Button
            isDisabled={!type || screenshot.length === 0}
            variant={'primary'}
            onClick={onSubmit}
            isLoading={isLoading}
            loadingText='Submitting'
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AddNewSuggestion;
