import React, { useEffect, useRef } from 'react';
import ArrowUpCard from '../../../assets/img/icons/Chevron.png';
import boxIcon from '../../../assets/img/icons/boxIcon.svg';

import {
  Box,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
} from '@chakra-ui/react';
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseLargeFill,
} from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SummaryMobile = ({
  data,
  isOpenSummary,
  setIsOpenSummary,
  priceDetails,
  handleSelectAll,
  selectedItems,
  cartItems,
}) => {
  const navigate = useNavigate();
  const { updatedCartDetails, coupon } = useSelector((state) => state.cart);
  const firstField = React.useRef();

  return (
    <>
      <div className='llt_cart_checkout_bx'>
        <div className='llt_cart_check_all'>
          <Checkbox
            isChecked={selectedItems.length === cartItems.length}
            onChange={handleSelectAll}
            fontSize='20px'
            fontWeight={700}
            color='#000'
          >
            All
          </Checkbox>
        </div>
        <div className='llt_cart_total_price'>
          <h6 className='' onClick={(e) => setIsOpenSummary(!isOpenSummary)}>
            {updatedCartDetails?.total_amount
              ? updatedCartDetails?.total_amount
              : priceDetails?.total_amount
              ? priceDetails?.total_amount
              : '0.00'}
            <Box>
              {isOpenSummary ? (
                <RiArrowDownSLine color='#f41106' size={'30px'} />
              ) : (
                <RiArrowUpSLine color='#f41106' size={'30px'} />
              )}
            </Box>
          </h6>
          <button
            onClick={() => {
              navigate('/create/order');
            }}
          >
            Checkout ({selectedItems?.length})
          </button>
        </div>
      </div>
      {isOpenSummary && (
        <Drawer
          placement={'bottom'}
          onClose={(e) => setIsOpenSummary(false)}
          isOpen={(e) => setIsOpenSummary(true)}
          initialFocusRef={firstField}
        >
          <DrawerContent className='teetete'>
            <DrawerHeader
              className='llt_card_price_priveiw'
              alignItems='center'
            >
              Summary
              <span onClick={(e) => setIsOpenSummary(false)}>
                {' '}
                <RiCloseLargeFill />
              </span>
            </DrawerHeader>
            <DrawerBody>
              <div className='llt_cart_summary_bx'>
                <div className='llt_cart_bx_sum' ref={firstField}>
                  <span>Sub Total</span>
                  <span>
                    {updatedCartDetails?.sub_total_amount
                      ? updatedCartDetails?.sub_total_amount
                      : priceDetails?.sub_total_amount
                      ? priceDetails?.sub_total_amount
                      : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span>Discount</span>
                  <span>
                    {updatedCartDetails?.discount
                      ? updatedCartDetails?.discount
                      : priceDetails?.discount
                      ? priceDetails?.discount
                      : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span>Delivery Charges</span>
                  <span>
                    {updatedCartDetails?.shipment_amount
                      ? updatedCartDetails?.shipment_amount
                      : priceDetails?.shipment_amount
                      ? priceDetails?.shipment_amount
                      : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span> Total</span>
                  <span>
                    {updatedCartDetails?.total_amount
                      ? updatedCartDetails?.total_amount
                      : priceDetails?.total_amount
                      ? priceDetails?.total_amount
                      : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_fast_delivery'>
                  <img src={boxIcon} alt='Shipment Icon' />
                  <h6>Fast Delivery</h6>
                </div>
                <div>
                  <div className='llt_cart_fast_delivery'>
                    <img src={boxIcon} alt='Shipment Icon' />
                    <h6>Security & Privacy</h6>
                  </div>
                  <div className='llt_cart_privacy_rules'>
                    <p>
                      Safe payments: We do not share your personal details with
                      any third parties without your consent.
                    </p>
                    <p>
                      Secure personal details: We protect your privacy and keep
                      your personal details safe and secure.
                    </p>
                  </div>
                </div>
                <div className='llt_cart_fast_delivery'>
                  <img src={boxIcon} alt='Shipment Icon' />
                  <h6>Buyer Protection</h6>
                </div>
                <div className='llt_cart_input_coupan'>
                  <input
                    autofocus={false}
                    type='text'
                    placeholder='Coupan Code'
                  />
                  <button>Apply</button>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default SummaryMobile;
