import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ArrowRight from '../../icons/arrow-right';
import styles from './top-category.module.scss';
import Categories from '../../categories';

const TopCategory = () => {
  return (
    <Box mt={'40px'} mb='40px' className={styles.top_cat_main}>
      <Flex
        mb='40px'
        justify={'space-between'}
        align={'center'}
        borderBottom={'1px solid #EDEDED'}
        paddingBottom={'16px'}
      >
        <Flex
          fontWeight={700}
          color={'#666'}
          fontSize={'24px'}
          align={'center'}
          gap={'5px'}
          className={styles.title}
        >
          Shop From <Text color={'#F41106'}>Top Categories</Text>
        </Flex>
      </Flex>

      <Categories />
    </Box>
  );
};

export default React.memo(TopCategory);
