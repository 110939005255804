import React from "react";
import { Box, Flex, Text, Avatar, Icon, Circle } from "@chakra-ui/react";
import { FaStar, FaQuoteRight } from "react-icons/fa";
import Profile from "../../assets/img/product/profileImage.jpg";

const ReviewCard = ({ name, title, message, rating, time }) => {
  return (
    <Box
      width="90%"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      m={8}
      p={4}
      bg="white"
      position="relative"
      mx="auto"
    >
      <Circle
        size="40px"
        bg="red.500"
        color="white"
        position="absolute"
        top="-20px"
        right="10px"
        boxShadow="0 0 8px rgba(255, 0, 0, 0.5)"
      >
        <Icon as={FaQuoteRight} boxSize={4} />
      </Circle>
      <Flex justify="space-between" align="center" mb={4} pt={4}>
        <Flex align="center">
          <Avatar src={Profile} size="md" />
          <Box ml={4}>
            <Text fontWeight="bold" color="#f41106" fontSize="14px">
              {name}
            </Text>
            <Text color="gray.500" fontSize="12px">
              {title}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Text mb={4} color="#696969" fontSize="14px">
        - {message}
        <Text
          as="span"
          fontWeight="bold"
          fontSize="14px"
          color="#f41106"
          cursor="pointer"
        >
         {" "}Read More
        </Text>
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text color="#696969">Rating :</Text>
          {[...Array(rating)].map((_, i) => (
            <Icon as={FaStar} key={i} color="yellow.400" />
          ))}
          {[...Array(5 - rating)].map((_, i) => (
            <Icon as={FaStar} key={i} color="gray.300" />
          ))}
        </Flex>
        <Flex flexDir="column" alignItems="center" justifyContent="center">
          <Text fontWeight="bold" color="gray.500" fontSize="14px">
            {time}
          </Text>
          <Text fontSize="14px" color="#51CCA9" fontStyle="italic">
            {name}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReviewCard;
