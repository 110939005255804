import { Flex, Input, Text, Textarea } from '@chakra-ui/react';
import { useField, Field } from 'formik';

const FormInputWithLabel = ({ label, labelProps, as, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Flex flexDir={'column'} gap={'10px'} pos={'relative'}>
      <Flex
        gap={'10px'}
        align={'center'}
        position='absolute'
        ml={3}
        mt={1}
        zIndex='1'
        bg='white'
        px={1}
      >
        <Text
          as={'label'}
          htmlFor={props.id || props.name}
          textStyle={'body-rg'}
          fontWeight={600}
          lineHeight={'18px'}
          color='gray'
          fontSize='14px'
          {...labelProps}
        >
          {label}
        </Text>
      </Flex>
      <Field
        as={as === 'Textarea' ? Textarea : Input}
        isInvalid={meta.touched && meta.error}
        // focusBorderColor={'primary.100'}
        borderColor="gray.300"
        focusBorderColor="darkgray"    
        height={'60px'}
        paddingTop={'10px'}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <Text textStyle={'body-rg'} color={'red'} fontWeight={400}>
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormInputWithLabel;
