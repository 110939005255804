// src/components/PageNotFound.js
import React from 'react';
import { Box, Heading, Text, Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import pagenotfoundImage from '../../assets/img/pagenotfound.png'; // Adjust the path if needed

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="50vh"
      textAlign="center"
      p={4}
    >
      <Image src={pagenotfoundImage} alt="Page Not Found" boxSize="300px" mb={2} />
      <Text fontSize="xl" mb={2}>
        Oops! Page Not Found
      </Text>
      <Button colorScheme="teal" as={Link} to="/" mb={2}>
        Go to Homepage
      </Button>
    </Box>
  );
};

export default PageNotFound;
