import { Image } from '@chakra-ui/react';
import React from 'react';
import OrderIcon from "../../../assets/img/icons/orderIcon.svg"


const Icon = () => {
  return <Image src={OrderIcon} />;
};

export default Icon;

