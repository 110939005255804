import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import { Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { setPage } from '../../redux/homeSlice';
import { useDispatch } from 'react-redux';
const Banner = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  return (
    <Box className='home-banner'>
      <Swiper
        loop={true}
        allowSlidePrev={true}
        allowSlideNext={true}
        modules={[Navigation]}
        navigation
        slidesPerView={1}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Image
              src={isSmallerThan767 ? ((item.mobile_image != null && item.mobile_image != '') ? item.mobile_image : item.image) : item.image}
              height={isSmallerThan767 ? '130px' : '315px'}
              cursor={'pointer'}
              onClick={() => {
                dispatch(setPage(1));
                const catQuery = item.category_id
                  ? `&categoryId=${item.category_id}`
                  : '';
                navigate(
                  `product/listing?page_no=1&min_discount=${item.min_discount}&max_discount=${item.max_discount}${catQuery}&is_sold=${item.is_sold}&rating=${item.rating}&is_free_shipping=${item.is_free_shipping}&search_key=${item.search_key}&min_price=${item?.min_price}&max_price=${item?.max_price}&name=${encodeURIComponent(item.title.replace(/ /g, '-'))}&categoryId=${item?.sub_to_subcategory_id}`
                );
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Banner;
