import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  HStack,
  Image,
  SkeletonText,
  Skeleton,
} from '@chakra-ui/react';
import './productDescription.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProductBySku,
  fetchShipmentList,
  getProductImageByColor,
  setProductDetails,
} from '../../../redux/productSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import StarRating from '../../../components/star-rating/star';
import { useQuery } from '../../../hooks/useQuery';
import isEmpty from 'is-empty';

const ProductDescription = ({ fromHome }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  let [searchParams, setSearchParams] = useSearchParams();
  const { productDetails, isProductLoading, productId } = useSelector(
    (state) => state.product
  );
  const { country } = useSelector((state) => state.home);
  const product = productDetails?.products;
  const rating = product?.rating || 0;
  const reviewsRef = useRef(null);
  const [skuId, setSkuId] = useState(query.get('sku_id') || null)
  const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    JPY: '¥', // Japanese Yen
    GBP: '£', // British Pound
    AUD: 'A$', // Australian Dollar
    CAD: 'C$', // Canadian Dollar
    CHF: 'CHF', // Swiss Franc
    CNY: '¥', // Chinese Yuan
    SEK: 'kr', // Swedish Krona
    NZD: 'NZ$', // New Zealand Dollar
    INR: '₹', // Indian Rupee
    RUB: '₽', // Russian Ruble
    ZAR: 'R', // South African Rand
    BRL: 'R$', // Brazilian Real
    SGD: 'S$', // Singapore Dollar
    HKD: 'HK$', // Hong Kong Dollar
    KRW: '₩', // South Korean Won
    MXN: 'MX$', // Mexican Peso
    NOK: 'kr', // Norwegian Krone
    TRY: '₺', // Turkish Lira
  };

  const currencySymbol =
    currencySymbols[product?.currency] || product?.currency;

  const productPrice = product?.price;
  const [priceValue, decimalValue] = productPrice
    ? productPrice.toString().split('.')
    : ['0', '00'];
  const formattedPriceValue = Number(
    priceValue.replace(/,/g, '')
  ).toLocaleString();
  const formattedDecimalValue = decimalValue ? `.${decimalValue}` : '.00';

  const scrollToReviews = () => {
    reviewsRef.current?.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: 1100,
      behavior: 'smooth',
    });
  };

  const isDefaultSelected = (name, value) => {
    const _name = productDetails?.products?.default_sku_attribute.find(
      (j) => j.name === name
    )?.name;
    const _value = productDetails?.products?.default_sku_attribute.find(
      (j) => j.property_value_id === value
    )?.property_value_id;

    return name === _name && value === _value;
  };

  const getDefaultSelectedVarients = (name) => {
    return productDetails?.products?.default_sku_attribute.find(
      (j) => j.name === name
    )?.value;
  };
  const getDefaultSelectedVarientsProperties = (property_value_id) => {
    return productDetails?.products?.default_sku_attribute.find(
      (j) => j.property_value_id === property_value_id
    );
  };

  // useEffect(() => {
  //   if (!fromHome && productDetails?.products?.sku_id) {
  //     setSearchParams({
  //       sku_id: productDetails.products.sku_id,
  //     });
  //   }
  // }, [fromHome, productDetails]);

  const handleVariantChange = (property, selectedVariant) => {
    // Step 1: Update selected status for the chosen variant
    const updatedProperties = productDetails?.sku_property.map((prop) => {
      if (prop.name === property.name) {
        return {
          ...prop,
          variant_value: prop.variant_value.map((variant) => ({
            ...variant,
            selected: variant.property_value_id === selectedVariant.property_value_id,
          })),
        };
      }
      return prop;
    });
  
    // Step 2: Update the product details with the new selected variant
    dispatch(
      setProductDetails({
        ...productDetails,
        sku_property: updatedProperties,
        products: {
          ...productDetails.products,
          default_sku_attribute: productDetails.products.default_sku_attribute.map(
            (attr) =>
              attr.name === property.name
                ? {
                    name: property.name,
                    value: selectedVariant.value,
                    sku_id: selectedVariant.sku_id,
                    property_value_id: selectedVariant.property_value_id,
                  }
                : attr
          ),
        },
      })
    );
  
    // Step 3: Update the product image if a color variant is selected
    if (property.name === "Color" && selectedVariant.image) {
      dispatch(
        getProductImageByColor({
          image: selectedVariant.image,
          id: selectedVariant.id,
        })
      );
    }
  
    // Step 4: Fetch product details by the selected SKU
    dispatch(fetchProductBySku(productDetails.products.id, property.name, selectedVariant));
  
    // Step 5: Trigger shipment list fetch for updated SKU
    const payload = {
      id: productDetails.products.id,
      country,
      currency: productDetails.products.currency,
      sku_id: selectedVariant.sku_id,
      quantity: 1,
    };
    dispatch(fetchShipmentList(payload));
  
    // Step 6: Update URL with the selected SKU ID only if `sku_id` is already in the URL
    const currentUrl = new URL(window.location.href);
    const urlSkuId = currentUrl.searchParams.get("sku_id");
  
    if (urlSkuId) {
      // Update `sku_id` in the URL only if it already exists
      currentUrl.searchParams.set("sku_id", selectedVariant.sku_id);
      window.history.replaceState({}, "", currentUrl);
    }
  };
  
  return (
    <Box className='product-description'>
      {isProductLoading ? (
        <SkeletonText
          noOfLines={2}
          spacing='10px'
          mb='10px'
          skeletonHeight='18px'
        />
      ) : (
        <Text fontSize={'18px'} fontWeight={600}>
          {product?.name}
        </Text>
      )}

      <Flex align='center' mb='1rem'>
        {/* Display stars based on the rating */}
        <StarRating rating={rating} />
        <Text fontSize='18px' fontWeight={600} color='#8c9097' ml='0.5rem'>
          {rating}{' '}
          <span
            className='text-info'
            onClick={scrollToReviews}
            style={{ cursor: 'pointer' }}
          >
            ({productDetails?.productReviews?.length} Reviews)
          </span>
        </Text>
      </Flex>
      <Text fontSize='12px' color='#26bf94' fontWeight={600}>
        Special Offer
      </Text>
      <Flex align='flex-start' flexDir={'column'}>
        {isProductLoading ? (
          <Skeleton height={'30px'} width={'200px'} mb='5px' />
        ) : (
          <Flex>
            <Text fontSize='18px' fontWeight='600' mr={1} mt={1}>
              {currencySymbol}
            </Text>
            <Text fontSize='28px' fontWeight='600'>
              {formattedPriceValue}
            </Text>
            <Text fontSize='18px' fontWeight='600' mt={1}>
              {formattedDecimalValue}
            </Text>

            {isProductLoading ? (
              <Skeleton ml='5px' height={'15px'} width={'30px'} mt={2} />
            ) : (
              product?.mrp !== product?.price && (
                <Badge
                  ml={2}
                  height='15px'
                  width={'auto'}
                  mt={5}
                  color='rgb(230 83 60)'
                  bg='rgba(230, 83, 60,.1)'
                  fontSize='md'
                  style={{ fontSize: '9.75px', marginLeft: '5px' }}
                >
                  {product?.discount}% Off
                </Badge>
              )
            )}
          </Flex>
        )}
      </Flex>
      {product?.mrp !== product?.price && (
        <>
          {' '}
          {isProductLoading ? (
            <Skeleton mt='5px' height={'20px'} width={'100px'} />
          ) : (
            <Text fontSize='sm' color='gray.500' textDecoration='line-through'>
              M.R.P : {product?.currency} {product?.mrp}
            </Text>
          )}
        </>
      )}

      <Flex flexDir='column'>
        {productDetails?.sku_property?.map((property, index) => (
          <Box mt={4}>
            <Text fontSize='15px' fontWeight='600'>
              {property?.name}:{' '}
              {property ? getDefaultSelectedVarients(property.name) : 'N/A'}
            </Text>

            <HStack mt={2} flexWrap={'wrap'}>
              {property?.variant_value?.map((innerProperty, index) =>
                property.name === 'Color' ? (
                  <>
                    {innerProperty?.image ? (
                      <Image
                        _hover={{ opacity: 0.5 }}
                        height={'55px'}
                        w={'55px'}
                        borderRadius={'50%'}
                        src={innerProperty.image}
                        cursor={'pointer'}
                        className={`${getDefaultSelectedVarientsProperties(
                          innerProperty.property_value_id
                        )?.avail_quantity
                          ? getDefaultSelectedVarientsProperties(
                            innerProperty.property_value_id
                          )?.avail_quantity === 0
                            ? 'sold'
                            : ''
                          : innerProperty.avail_quantity === 0
                            ? 'sold'
                            : ''
                          } noSelect`}
                        border={
                          innerProperty.selected ||
                            isDefaultSelected(
                              property.name,
                              innerProperty.property_value_id
                            )
                            ? '3px solid #fb0817'
                            : '1px solid #f2f4f5'
                        }
                        // onClick={() => {
                        //   if (
                        //     !isEmpty(
                        //       getDefaultSelectedVarientsProperties(
                        //         innerProperty.property_value_id
                        //       )
                        //     )
                        //   ) {
                        //     return;
                        //   }
                        //   handleVariantChange(property, innerProperty)
                        //   const temp = [...productDetails?.sku_property];
                        //   { console.log(temp, "checkk") }
                        //   // map new array with selected true or false
                        //   const newProperty = temp.map((prop) => {
                        //     if (prop.name === 'Color') {
                        //       return {
                        //         ...prop,
                        //         variant_value: prop.variant_value.map(
                        //           (innerProp) => {
                        //             if (innerProp?.id === innerProperty?.id) {
                        //               return {
                        //                 ...innerProp,
                        //                 selected: true,
                        //               };
                        //             } else if (
                        //               prop?.name === innerProperty?.name
                        //             ) {
                        //               return {
                        //                 ...innerProp,
                        //                 selected: false,
                        //               };
                        //             } else {
                        //               return {
                        //                 ...innerProp,
                        //               };
                        //             }
                        //           }
                        //         ),
                        //       };
                        //     } else {
                        //       return prop;
                        //     }
                        //   });

                        //   dispatch(
                        //     setProductDetails({
                        //       ...productDetails,
                        //       sku_property: newProperty,
                        //       products: {
                        //         ...productDetails.products,
                        //         default_sku_attribute: [
                        //           ...productDetails.products.default_sku_attribute.map(
                        //             (j) => {
                        //               if (j.name === property.name) {
                        //                 return {
                        //                   name: property.name,
                        //                   value: innerProperty.value,
                        //                   sku_id: innerProperty.sku_id,
                        //                   property_value_id:
                        //                     innerProperty.property_value_id,
                        //                 };
                        //               } else {
                        //                 return j;
                        //               }
                        //             }
                        //           ),
                        //         ],
                        //       },
                        //     })
                        //   );
                        //   dispatch(
                        //     getProductImageByColor({
                        //       image: innerProperty.image,
                        //       id: innerProperty.id,
                        //     })
                        //   );
                        //   dispatch(
                        //     fetchProductBySku(
                        //       id || productId,
                        //       property.name,
                        //       innerProperty
                        //     )
                        //   );
                        //   const payload = {
                        //     id: productDetails?.products?.id,
                        //     country: country,
                        //     currency: productDetails?.products?.currency,
                        //     sku_id: query.get('sku_id'),
                        //     quantity: 1,
                        //   };
                        //   dispatch(fetchShipmentList(payload));
                        // }}
                        onClick={() => handleVariantChange(property, innerProperty)}
                      />
                    ) : (
                      <Box
                        cursor={'pointer'}
                        // className='color-swatch'
                        bg={innerProperty.value}
                        style={{
                          height: '35px',
                          borderRadius: '50%',
                        }}
                        _hover={{ opacity: 0.5 }}
                        minW={'35px'}
                        maxW={'35px'}
                        width={'10%'}
                        border={
                          innerProperty.selected ||
                            isDefaultSelected(
                              property.name,
                              innerProperty.property_value_id
                            )
                            ? '3px solid #53CCAA'
                            : '1px solid #f2f4f5'
                        }
                        className={`${getDefaultSelectedVarientsProperties(
                          innerProperty.property_value_id
                        )?.avail_quantity
                          ? getDefaultSelectedVarientsProperties(
                            innerProperty.property_value_id
                          )?.avail_quantity === 0
                            ? 'sold'
                            : ''
                          : innerProperty.avail_quantity === 0
                            ? 'sold'
                            : ''
                          } noSelect`}
                        onClick={() => {
                          if (
                            !isEmpty(
                              getDefaultSelectedVarientsProperties(
                                innerProperty.property_value_id
                              )
                            )
                          ) {
                            return;
                          }
                          const temp = [...productDetails?.sku_property];
                          // map new array with selected true or false
                          const newProperty = temp.map((prop) => {
                            if (prop.name === 'Color') {
                              return {
                                ...prop,
                                variant_value: prop.variant_value.map(
                                  (innerProp) => {
                                    if (innerProp.id === innerProperty.id) {
                                      return {
                                        ...innerProp,
                                        selected: true,
                                      };
                                    } else if (
                                      prop.name === innerProperty.name
                                    ) {
                                      return {
                                        ...innerProp,
                                        selected: false,
                                      };
                                    } else {
                                      return {
                                        ...innerProp,
                                      };
                                    }
                                  }
                                ),
                              };
                            } else {
                              return prop;
                            }
                          });

                          dispatch(
                            setProductDetails({
                              ...productDetails,
                              sku_property: newProperty,
                              products: {
                                ...productDetails.products,
                                default_sku_attribute: [
                                  ...productDetails.products.default_sku_attribute.map(
                                    (j) => {
                                      if (j.name === property.name) {
                                        return {
                                          name: property.name,
                                          value: innerProperty.value,
                                          sku_id: innerProperty.sku_id,
                                          property_value_id:
                                            innerProperty.property_value_id,
                                        };
                                      } else {
                                        return j;
                                      }
                                    }
                                  ),
                                ],
                              },
                            })
                          );
                          dispatch(
                            getProductImageByColor({
                              image: innerProperty.image,
                              id: innerProperty.id,
                            })
                          );
                          dispatch(
                            fetchProductBySku(
                              id || productId,
                              property.name,
                              innerProperty
                            )
                          );
                          const payload = {
                            id: productDetails?.products?.id,
                            country: country,
                            currency: productDetails?.products?.currency,
                            sku_id: query.get('sku_id'),
                            quantity: 1,
                          };
                          dispatch(fetchShipmentList(payload));
                        }}
                      ></Box>
                    )}
                  </>
                ) : (
                  <Box
                    bg={
                      innerProperty.selected ||
                        isDefaultSelected(
                          property.name,
                          innerProperty.property_value_id
                        )
                        ? 'rgba(230, 83, 60, .1)'
                        : '#bcb4b31a'
                    }
                    color={
                      innerProperty.selected ||
                        isDefaultSelected(
                          property.name,
                          innerProperty.property_value_id
                        )
                        ? 'rgba(230, 83, 60, 1)'
                        : '#777679'
                    }
                    _hover={{
                      opacity: 0.8,
                      background: '#f2f4f5',
                    }}
                    style={{
                      fontSize: '1rem',
                      height: '40px',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    border={
                      innerProperty.selected ||
                        isDefaultSelected(
                          property.name,
                          innerProperty.property_value_id
                        )
                        ? '2px solid #f41106'
                        : '1px gray solid'
                    }
                    minW={'35px'}
                    padding={'0 5px'}
                    cursor={'pointer'}
                    className={`${getDefaultSelectedVarientsProperties(
                      innerProperty.property_value_id
                    )?.avail_quantity
                      ? getDefaultSelectedVarientsProperties(
                        innerProperty.property_value_id
                      )?.avail_quantity === 0
                        ? 'sold'
                        : ''
                      : innerProperty.avail_quantity === 0
                        ? 'sold'
                        : ''
                      } noSelect`}
                    onClick={() => {
                      if (
                        !isEmpty(
                          getDefaultSelectedVarientsProperties(
                            innerProperty.property_value_id
                          )
                        )
                      ) {
                        return;
                      }
                      const temp = [...productDetails?.sku_property];
                      // map new array with selected true or false
                      const newProperty = temp.map((prop) => {
                        return {
                          ...prop,
                          variant_value: prop.variant_value.map((innerProp) => {
                            if (innerProp.id === innerProperty.id) {
                              return {
                                ...innerProp,
                                selected: true,
                              };
                            } else if (prop.name === innerProperty.name) {
                              return {
                                ...innerProp,
                                selected: false,
                              };
                            } else {
                              return {
                                ...innerProp,
                              };
                            }
                          }),
                        };
                      });

                      dispatch(
                        setProductDetails({
                          ...productDetails,
                          sku_property: newProperty,
                          products: {
                            ...productDetails.products,
                            default_sku_attribute: [
                              ...productDetails.products.default_sku_attribute.map(
                                (j) => {
                                  if (j.name === property.name) {
                                    return {
                                      name: property.name,
                                      value: innerProperty.value,
                                      sku_id: innerProperty.sku_id,
                                      property_value_id:
                                        innerProperty.property_value_id,
                                    };
                                  } else {
                                    return j;
                                  }
                                }
                              ),
                            ],
                          },
                        })
                      );

                      dispatch(
                        fetchProductBySku(
                          id || productId,
                          property.name,
                          innerProperty
                        )
                      );
                      const payload = {
                        id: productDetails?.products?.id,
                        country: country,
                        currency: productDetails?.products?.currency,
                        sku_id: query.get('sku_id'),
                        quantity: 1,
                      };
                      dispatch(fetchShipmentList(payload));
                    }}
                  >
                    {innerProperty.value}
                  </Box>
                )
              )}
            </HStack>
          </Box>
        ))}
      </Flex>
      {/* {!fromHome && (
        <>
          <Box className='product-detail' mt='15px'>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Product Details :
            </Text>
            <Box
              overflowX={['auto', 'visible']}
              style={{ marginTop: '8px', width: '100%' }}
            >
              <Table variant='simple' minWidth='100%'>
                <Thead>
                  <Tr>
                    <Th border='1px solid' borderColor='#f2f4f5'>
                      Feature
                    </Th>
                    <Th border='1px solid' borderColor='#f2f4f5'>
                      Details
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {productDetails?.products?.product_details?.map(
                    (detail, index) => (
                      <Tr>
                        <Td
                          fontSize={'13px'}
                          border='1px solid'
                          borderColor='#f2f4f5'
                          fontWeight={600}
                        >
                          {detail.attrName}
                        </Td>
                        <Td
                          fontSize={'13px'}
                          border='1px solid'
                          borderColor='#f2f4f5'
                        >
                          {detail.attrValue}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </Box>
          </Box>
        </>
      )} */}
      <Box ref={reviewsRef}>
        {/* added this box just to scroll to comments when reviews is clicked */}
      </Box>
    </Box>
  );
};

export default ProductDescription;
