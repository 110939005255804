import React, { useState } from 'react';
import { Button, Flex, Select, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import Modal from '../../../components/modal';
import { reportProductHandler } from '../../../redux/productSlice';

const ReportProductModal = ({ isOpen, onClose, productId }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { reportCategores } = useSelector((state) => state.product);
  const [reason, setReason] = useState('');

  const handleReportSubmit = () => {
    if (!userData?.id) {
      toast({
        title: 'User ID is missing.',
        status: 'error',
      });
      return; 
    }
  
    if (!productId) {
      toast({
        title: 'Product ID is missing.',
        status: 'error',
      });
      return; 
    }
    const payload = {
      user_id: userData?.id,
      product_id: productId,
      category: reason,
      other_data: '',
    };

    dispatch(reportProductHandler(payload)).then((res) => {
      if (res.status === 200) {
        toast({
          title: 'Product reported successfully',
          status: 'success',
        });
        onClose();
      } else {
        toast({
          title: 'Product report failed',
          status: 'error',
        });
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Report Product'>
      <Text>Please tell us about the issue</Text>
      <Select
        onChange={(e) => {
          if (e.target.value === '-1') {
            setReason('');
            return;
          }
          setReason(e.target.value);
        }}
      >
        <option value='-1'>Select</option>
        {reportCategores?.map((category) => (
          <option key={category.id} value={category?.name}>
            {category?.name}
          </option>
        ))}
      </Select>
      <Flex justify={'flex-end'} align={'center'} gap={'20px'} mt='20px'>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleReportSubmit}>
          Submit
        </Button>
      </Flex>
    </Modal>
  );
};

export default ReportProductModal;
