import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Image,
  Select,
  Textarea,
  Input,
  useToast,
  Badge,
  Heading,
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, refundCategoryHandler, refundOrderHandler } from '../../redux/orderSlice';
import NumberGroupInput from '../../components/number-group-input';
import { FaImage, FaVideo } from 'react-icons/fa';
import InstructionsModal from './instructionsModal';
import { ChevronRightIcon } from '@chakra-ui/icons';
// import MultilevelDropdown, { MultilevelDropdownItem } from 'react-multilevel-dropdown';

const ReturnOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const { orders } = useSelector((state) => state.order);
  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [problemDescription, setProblemDescription] = useState('');

  const [order, setOrder] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [refundCategory, setRefundCategory] = useState(null);

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [refundMethod, setRefundMethod] = React.useState('Original payment');
  const [email, setEmail] = React.useState('');
  // const MultilevelDropdownItem = MultilevelDropdown.Item;
  const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    if (orders.length > 0) {
      const foundOrder = orders.find((order) => order.id === Number(id));
      setOrder(foundOrder);
      setOrderId(foundOrder.id);
    }
  }, [orders, id]);

  useEffect(() => {
    refundCategoryOptions();
  }, []);

  const refundCategoryOptions = () => {
    dispatch(refundCategoryHandler()).then((res) => {
      // if (res.status === 200) {
      setRefundCategory(res.data.data);
      //   toast({
      //     title: 'Data Fetched Successfully',
      //     status: 'success',
      //     duration: 4000,
      //     isClosable: true,
      //   });
      // } else {
      //   toast({
      //     title: res?.response?.data?.message ?? 'Something went wrong',
      //     status: 'error',
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // }
    });
  };

  useEffect(() => {
    dispatch(fetchOrders()).then((res) => {
      if (res.payload && res.payload.length > 0) {
        setSelectedOrder(res.payload[0]);
      }
    });
  }, [dispatch]);

  const moveToFirstStep = () => {
    setCurrentStep(1);
  };

  const moveToSecondStep = () => {
    // if (orderId && images && reason) {
    setCurrentStep(2);
    // } else {
    //   toast({
    //     title: 'Please fill all required fields',
    //     status: 'warning',
    //     duration: 4000,
    //     isClosable: true,
    //   });
    // }
  };

  const handleImageUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    const validImages = uploadedFiles.filter((file) => file.type.startsWith('image/'));
    if (validImages.length > 0) {
      setImages([...images, ...validImages]);
      toast({
        title: 'Image uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Only image files are allowed.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleVideoUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    const validVideos = uploadedFiles.filter((file) => file.type.startsWith('video/') && file.size <= 2 * 1024 * 1024);
    if (validVideos.length > 0) {
      setVideos([...videos, ...validVideos]);
      toast({
        title: 'Video uploaded successfully!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    }

    const oversizedFiles = uploadedFiles.filter((file) => file.size > 2 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      toast({
        title: 'Video size exceeds the 2MB limit.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const submitRefundOrder = async () => {
    const formData = new FormData();

    formData.append('order_id', orderId);
    formData.append('reason', reason);
    formData.append('return_method', refundMethod);
    formData.append('email', email);
    images.forEach((image, index) => {
      formData.append(`return_image[${index}]`, image);
    });
    videos.forEach((video, index) => {
      formData.append(`return_video[${index}]`, video);
    });

    try {
      const res = await dispatch(refundOrderHandler(formData));
      if (res.status === 200) {
        toast({
          title: 'Refund request submitted successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        navigate('/my-orders');
      } else {
        toast({
          title: res?.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message ?? 'An error occurred',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSelect = (value) => {
    setReason(value);
  };

  return (
    <Box mb="30px" p={{ base: "10px", md: "20px" }}>
      <Flex direction={{ base: "column", md: "row" }} align="center" mb={{ base: '0', md: '20px' }}>
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight={600} mb={{ base: "10px", md: "0" }}>
          Request a Return/Refund
        </Text>
        <Spacer display={{ base: "none", md: "block" }} />
        <InstructionsModal />
      </Flex>

      {/* <Flex bg='#fffb2e6e' h='50px' p='15px' justifyContent='flex-start' alignItems='center'>
        <Text>
          <Badge bg='#d69e206e' h='20px' m='5px' border='brown 1px solid'>choice</Badge>
          This order qualifies for free return
        </Text>
      </Flex> */}
      <br />
      <Flex align="center" justify="space-between" w="100%" mb={{ base: '0', md: '20px' }} px={{ base: '10px', md: '200px' }}>
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 1 ? 'red.500' : 'gray.300'}
            color="white"
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            1
          </Box>
          <Text mt="10px">Select article</Text>
        </Flex>

        <Box
          flex="1"
          height="2px"
          mb='35px'
          bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
        />
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
            color={currentStep >= 2 ? 'white' : 'black'}
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            2
          </Box>
          <Text mt="10px">Select method</Text>
        </Flex>
      </Flex>
      <br />
      {currentStep === 1 && (
        <Box width={{ base: "100%", md: "50%" }}>
          <Flex align="center" mb="20px" gap="20px">
            <Box>
              <Image
                src={order?.product_image}
                alt="Product Image"
                boxSize={{ base: "80px", md: "100px" }}
                width="130px"
                height="90px"
                minW="100px"
                minH="70px"
                border="gray 1px solid"
                borderRadius="10px"
              />
            </Box>
            <Box>
              <Text fontWeight="600">{order?.product_name}</Text>
              <Text fontSize="sm">{order?.productDescription}</Text>
            </Box>
          </Flex>

          <Select
            placeholder="Please select"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            mb="20px"
          >
            {refundCategory && refundCategory.length > 0 ? (
              refundCategory.map((category) => (
                <optgroup key={category.id} label={category.name}>
                  <option value={category.name}>{category.name}</option>
                  {category.subcategory &&
                    category.subcategory.length > 0 &&
                    category.subcategory.map((sub) => (
                      <option key={sub.id} value={sub.name}>
                        &nbsp;&nbsp;&nbsp;{sub.name}
                      </option>
                    ))}
                </optgroup>
              ))
            ) : (
              <option value="">No refund categories available</option>
            )}
          </Select>

           {/* <MultilevelDropdown>
            {refundCategory && refundCategory.length > 0 ? (
              refundCategory.map((category) => (
                <MultilevelDropdownItem
                  key={category.id}
                  title={category.name}
                  onClick={() => handleSelect(category.name)}
                >
                  {category.subcategory &&
                    category.subcategory.length > 0 &&
                    category.subcategory.map((sub) => (
                      <MultilevelDropdownItem
                        key={sub.id}
                        title={sub.name}
                        onClick={() => handleSelect(sub.name)}
                      />
                    ))}
                </MultilevelDropdownItem>
              ))
            ) : (
              <MultilevelDropdownItem title="No refund categories available" disabled />
            )}
          </MultilevelDropdown>  */}

          <Text mb="10px" fontWeight="600">Upload a picture/video</Text>
          <Box position="relative" mb="10px">
            <Textarea
              placeholder="Please describe your problem."
              value={problemDescription}
              onChange={(e) => setProblemDescription(e.target.value)}
              mb="10px"
              minHeight="100px"
            />
            <Flex position="absolute" bottom="15px" left="10px" gap="10px">
              <IconButton
                icon={<FaImage />}
                size="md"
                variant="secondary"
                aria-label="Upload Image"
                onClick={() => document.getElementById('image-upload').click()}
              />
              <IconButton
                icon={<FaVideo />}
                size="md"
                variant="secondary"
                aria-label="Upload Video"
                onClick={() => document.getElementById('video-upload').click()}
              />
            </Flex>
          </Box>

          <Input type="file" id="image-upload" accept="image/*" display="none" onChange={handleImageUpload} />
          <Input type="file" id="video-upload" accept="video/*" display="none" onChange={handleVideoUpload} />
        </Box>
      )}

      {currentStep === 2 && (
        <>
          <Box p={6} borderRadius="md" boxShadow="md">
            <Heading as="h4" size="lg" mb={4}>
              Select a refund method
            </Heading>

            <RadioGroup onChange={setRefundMethod} value={refundMethod}>
              <Stack spacing={4}>
                <HStack align="start">
                  <Radio value="Original payment" mt="6px" />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">Original payment method</Text>
                    <Text fontSize="sm" fontWeight="bold">{order?.payment_method == "ONLINE" ? "Ezipay / Paypal" : order?.payment_method}</Text>
                    <Text fontSize="sm" color="gray">
                      Refunds are issued within 3-14 business days (some payment methods may take up to 20 business days, depending on the bank or financial institution).
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </RadioGroup>
          </Box>

          <Box p={6} borderRadius="md" boxShadow="md" mt="20px">
            <Text fontSize="lg" fontWeight="bold">Estimated refund amount: {order?.currency} {order?.price}</Text>
          </Box>

          <Box p={6} borderRadius="md" boxShadow="md" mt="20px">
            <FormControl isRequired>
              <FormLabel>Confirm your email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <Text mt="5px" fontSize="sm" color="gray">
                Confirm your email to receive return labels, return updates, and refund notifications.
              </Text>
            </FormControl>
          </Box>
        </>
      )}

      <br />
      <br />
      {/* Next Step Button */}
      <Flex justifyContent="center">
        {currentStep === 2 &&
          <Button
            mr='10px'
            variant='secondary'
            width="100%" maxW='300px' borderRadius='20px'
            onClick={moveToFirstStep}
          >
            Back
          </Button>}
        <Button
          width="100%" maxW='300px' borderRadius='20px'
          onClick={currentStep === 1 ? moveToSecondStep : submitRefundOrder}
          disabled={
            (currentStep === 1 && (!reason || images.length === 0))
            ||
            (currentStep === 2 && !isValidEmail)
          }
        >
          {currentStep === 1 ? 'Next Step' : 'Submit'}
        </Button>
      </Flex>
    </Box>
  );
};

export default ReturnOrder;
