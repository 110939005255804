import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import textStyles from './textStyles';
import Button from './components/button';
import { Input } from './components/input';
import { TextArea } from './components/textarea';
import Alert from './components/alert';
import { Checkbox } from './components/checkbox';
import { Select } from './components/select';
import { Radio } from './components/radio';

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        fontFamily: '"Inter",  sans-serif"',
      },
    }),
  },
  colors,
  textStyles,
  components: {
    Button,
    Input,
    TextArea,
    Alert,
    Checkbox,
    Select,
    Radio,
  },
});

export default theme;
