import {
  Center,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useField, Field } from 'formik';
import { useState } from 'react';
import { RiEyeFill, RiEyeOffLine } from 'react-icons/ri';

const FormPasswordInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [show, setShow] = useState(false);

  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <Text
        as={'label'}
        htmlFor={props.id || props.name}
        textStyle={'body-rg'}
        fontWeight={400}
        lineHeight={'18px'}
      >
        {label}
      </Text>
      <InputGroup borderColor={'text.secondary.100'}>
        <Field
          as={Input}
          isInvalid={meta.touched && meta.error}
          type={show ? 'text' : 'password'}
          focusBorderColor={'primary.100'}
          size={'md'}
          {...field}
          {...props}
        />
        <InputRightElement
          h={'100%'}
          cursor={'pointer'}
          onClick={() => setShow(!show)}
        >
          <Tooltip label={show ? 'Hide Password' : 'Show Password'}>
            <Center>{show ? <RiEyeOffLine /> : <RiEyeFill />}</Center>
          </Tooltip>
        </InputRightElement>
      </InputGroup>

      {meta.touched && meta.error ? (
        <Text textStyle={'body-rg'} color={'red'} fontWeight={400}>
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormPasswordInput;
