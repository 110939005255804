import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

export const Checkbox = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    control: {
      border: '1.5px solid #7A7A7A !important',
      _checked: {
        border: '0px solid #f41106 !important',
        bgColor: '#f41106',
      },
    },
    icon: {
      bgColor: '#f41106',
    },
  }),
});
