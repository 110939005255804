import React, { useState } from 'react';

const UserIcon = ({color}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      style={{ minWidth: '24px' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <circle cx='12' cy='8' r='4.75' stroke={isHovered ? 'red' : color} stroke-width='1.5' />
      <path
        d='M6 21C6 21 6 19.75 6 18.5C6 17.25 8.24914 16 12 16C15.7509 16 18 17.25 18 18.5C18 20.375 18 21 18 21'
        stroke={isHovered ? 'red' : color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default UserIcon;
